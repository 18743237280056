/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { shape, string } from 'prop-types';
import { useQuery } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import MuiChip from '@material-ui/core/Chip';
import { Box, Spinner, Label, IconClose, IconSearch } from '@freska/freska-ui';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { GET_SUPPORT_MATERIAL } from '../../gql/queries';
import ViewGrid from '../../components/Common/ViewGrid';
import withUser from '../../utils/withUser';
import SupportCard from '../../components/Contentful/SupportCard';
import ContentfulEntry from '../../components/Contentful/ContentfulEntry';
import ClosableContentfulEntry from '../../components/Contentful/ClosableContentfulEntry';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import { trackEvent } from '../../utils/tracking';

const propTypes = {
  match: shape({ params: shape({ slug: string }) }),
};

function Support({ match: { params } }) {
  const [filters, setFilters] = useState({
    categories: [],
    category: null,
    search: '',
  });
  const { loading, data, error } = useQuery(GET_SUPPORT_MATERIAL);
  const debouncedSearch = useDebounce(filters.search, 350);
  const [activeSlug, setActiveSlug] = useState(null);

  useEffect(() => {
    if (debouncedSearch.length > 0) {
      trackEvent(`Search used: "${debouncedSearch}"`, {
        category: 'Support/Pro',
      });
    }
  }, [debouncedSearch]);

  if (loading) {
    return (
      <ViewGrid>
        <Box p={3}>
          <Spinner />
        </Box>
      </ViewGrid>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (activeSlug) {
    const item = data.getSupportEntries.find(item => activeSlug === item.slug);
    // TODO: REPLACE WITH ANOTHER COMPONENT TO SET NULL
    return (
      <ClosableContentfulEntry
        item={item}
        slug="news"
        onClose={() => setActiveSlug(null)}
      />
    );
  }

  if (params.slug) {
    const item = data.getSupportEntries.find(item => params.slug === item.slug);
    return <ContentfulEntry item={item} slug="support" />;
  }

  const supportItems = data.getSupportEntries.map(item => {
    if (!item.category) {
      item.category = 'General';
    }
    return item;
  });

  const onDeleteChip = () => {
    setFilters({ ...filters, category: null });
  };

  const onSelectChip = category => {
    setFilters({ ...filters, category });
    trackEvent(`Category chip selected: "${category}"`, {
      category: 'Support/Pro',
    });
  };

  const categories = supportItems.map(item => item.category);
  const uniqCategories = [...new Set(categories)].sort();

  const filteredsupportItems = supportItems.filter(item => {
    const hasCategoryFilterOn = !!filters.category;
    const includedinSearch =
      item.topic.includes(debouncedSearch) ||
      item.description.includes(debouncedSearch);
    const isInSearch = hasCategoryFilterOn
      ? debouncedSearch.length && includedinSearch
      : includedinSearch;

    return isInSearch || filters.category === item.category;
  });

  return (
    <ViewGrid>
      <Box mx={2}>
        <Input
          placeholder="Search by title or description"
          fullWidth
          variant="outlined"
          onChange={e => setFilters({ ...filters, search: e.target.value })}
          value={filters.search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconSearch color="#90909A" />
              </InputAdornment>
            ),
            endAdornment: filters.search.length ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setFilters({ ...filters, search: '' })}
                >
                  <IconClose color="primary" />
                </IconButton>
              </InputAdornment>
            ) : (
              undefined
            ),
          }}
        />

        <Box mx="-6px">
          {uniqCategories.map(category => (
            <ChipComponent
              label={category}
              key={category}
              isSelected={filters.category === category}
              onClick={() =>
                filters.category === category
                  ? onDeleteChip()
                  : onSelectChip(category)
              }
            />
          ))}
        </Box>

        <Label mt={4} mb={3}>
          {filters.category || filters.search.length
            ? `Articles (${filteredsupportItems.length})`
            : 'All articles'}
        </Label>

        {filteredsupportItems.map(item => (
          <div onClick={() => setActiveSlug(item.slug)} key={item.slug}>
            {/* // <StyledLink
          //   to={`/support/${item.slug}`}
          //   key={`support-${item.slug}-${index}`}
          // > */}
            <SupportCard title={item.topic} description={item.description} />
            {/* // </StyledLink> */}
          </div>
        ))}
      </Box>
    </ViewGrid>
  );
}

export const useDebounce = (value, waitTime) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, waitTime);

    return () => {
      clearTimeout(handler);
    };
  }, [value, waitTime]);

  return debouncedValue;
};

const ChipComponent = ({ isSelected, label, onClick }) => {
  if (isSelected) {
    return (
      <Chip
        clickable={true}
        variant="outlined"
        label={label}
        onClick={onClick}
      />
    );
  }
  return (
    <Chip clickable={true} variant="default" label={label} onClick={onClick} />
  );
};

const Chip = withStyles({
  root: {
    backgroundColor: '#ffffff',
    border: '1px solid #E3E1EC',
    borderRadius: '4px',
    marginLeft: '6px',
    marginright: '6px',
    marginBottom: '6px',
    fontFamily: 'Nunito Sans',
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: '#1A237E',
    boxShadow:
      '0px 3px 5px rgba(0, 5, 82, 0.032), 0px 5px 8px rgba(0, 5, 82, 0.03), 0px 0px 14px rgba(0, 5, 82, 0.05)',
    outline: 'none !important',
  },
  outlined: {
    backgroundColor: '#E9F5F9',
    border: '2px solid #1A237E',
    borderRadius: '8px',
    margin: '4px',
    fontFamily: 'Nunito Sans',
    fontSize: '17px',
    fontWeight: 800,
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: '#1A237E',
  },
})(MuiChip);

const Input = withStyles({
  root: {
    // border: '1px solid #E3E1EC',
    borderRadius: '8px',
    background: '#ffffff',
    fontFamily: 'Nunito Sans',
    fontSize: '17px',
    fontWeight: 400,
    paddingTop: '8px',
    paddingBottom: '24px',
    minHeight: '56px',
    '& .MuiInputBase-input': {
      fontFamily: 'Nunito Sans',
      fontSize: '17px',
      fontWeight: 400,
    },
    '& label.Mui-focused': {
      color: '#E3E1EC',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#E3E1EC',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E3E1EC',
      },
      '&:hover fieldset': {
        borderColor: '#E3E1EC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E3E1EC',
      },
    },
  },
})(TextField);

Support.propTypes = propTypes;

export default withUser(Support);
