import React, { Component, Fragment } from 'react';
import { oneOf, bool, shape, func } from 'prop-types';
import styled, { css } from 'styled-components';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { IconFeedback, theme, typography, Text, Box } from '@freska/freska-ui';
import FiberNewIcon from '@material-ui/icons/FiberNew';

import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  injectIntl,
} from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Rating } from '@material-ui/lab';

import FlexContainer from '../Common/FlexContainer';
import { getLocaleObject } from '../../i18n/getLocaleObject';
import { space, sizes } from '../../theme/theme';
import { trackEvent } from '../../utils/tracking';
import { getServiceTypeIcon } from '../../utils/bookingsHelper';
import { AttentionWiggle } from '../../theme/animations';
import ServicesIconList from './ServicesIconList';
import { formatRating, formatDuration } from '../../utils/formatNumber';
import {
  BOOKING_DETAILS_PATHNAME,
  LANGUAGES_TYPE,
  RATING_QUALITY,
} from '../../constants';
import withLanguage from '../../utils/withLanguage';
import { bookingType } from '../../types';

const propTypes = {
  data: bookingType.isRequired,
  language: oneOf(LANGUAGES_TYPE).isRequired,
  unconfirmed: bool,
  rated: bool,
  history: shape({ push: func }).isRequired,
  intl: shape({}).isRequired,
  isBookingFromAvailability: bool,
  isOutsideAvailabilityPeriod: bool,
  type: oneOf(['dashboard', 'schedule']),
};

const defaultProps = {
  unconfirmed: false,
  rated: false,
  isBookingFromAvailability: false,
  isOutsideAvailabilityPeriod: false,
  type: 'schedule',
};

class BookingCard extends Component {
  state = {
    showDetails: false,
  };
  card = React.createRef();

  componentDidMount() {
    this.card.current.addEventListener('keypress', this.checkKeyCode);
  }

  componentWillUnmount() {
    this.card.current.removeEventListener('keypress', this.checkKeyCode);
  }

  checkKeyCode = e => {
    const code = e.keyCode;
    if (code === 13) {
      this.goToDetails();
    }
  };

  goToDetails = () => {
    const { rated, unconfirmed, data, history, type } = this.props;
    if (rated) {
      trackEvent('Booking details opened from rated bookings list', {
        category: 'Ratings',
      });
    } else if (unconfirmed) {
      trackEvent('Booking details opened from unconfirmed bookings list', {
        category: 'Bookings',
      });
    } else {
      trackEvent('Booking details opened from bookings list', {
        category: 'Bookings',
      });
    }

    history.push({
      pathname: BOOKING_DETAILS_PATHNAME,
      search: `?id=${data.uuid}`,
      state: { origin: type === 'schedule' },
    });
  };

  getServiceType = (serviceId, unconfirmed, isOutsideAvailabilityPeriod) => {
    if (unconfirmed) {
      return <FormattedMessage id="bookings.status.unconfirmed" />;
    }
    if (isOutsideAvailabilityPeriod) {
      return (
        <FormattedMessage id="bookings.status.outside_availability_period" />
      );
    }
    return <FormattedMessage id={`bookings.card.service_type.${serviceId}`} />;
  };

  renderInvoiceableDuration = (data, intl) => (
    <Text as="p" align="center" size="small" mt="-4px" color="secondary" bold>
      {data.invoiceable_duration >= data.duration && '+'}
      {formatDuration(data.invoiceable_duration - data.duration, intl)}
    </Text>
  );

  render() {
    const { showDetails } = this.state;
    const {
      data,
      unconfirmed,
      rated,
      language: locale,
      intl,
      isBookingFromAvailability,
      isOutsideAvailabilityPeriod,
      type,
    } = this.props;

    return (
      <CardContainer
        id={data.id}
        as="article"
        className="card-section"
        unconfirmed={unconfirmed}
        rated={rated}
        ref={this.card}
        onClick={this.goToDetails}
      >
        <FlexContainer column alignH="flex-start" alignV="center">
          <IconContainer
            unconfirmed={unconfirmed}
            isOutsideAvailabilityPeriod={isOutsideAvailabilityPeriod}
          >
            {getServiceTypeIcon(
              20,
              data.service.id,
              unconfirmed,
              isOutsideAvailabilityPeriod
            )}
          </IconContainer>

          {!rated && (
            <Fragment>
              <Text
                as="p"
                align="center"
                size="small"
                mt={space.xs}
                color="secondary"
              >
                {formatDuration(data.duration, intl)}
              </Text>
              {data.invoiceable_duration &&
                data.invoiceable_duration !== data.duration &&
                this.renderInvoiceableDuration(data, intl)}
            </Fragment>
          )}
        </FlexContainer>
        <CardContentContainer type={type}>
          {(!isBookingFromAvailability || isOutsideAvailabilityPeriod) &&
            type === 'schedule' && (
              <ServiceTypeFlexContainer
                unconfirmed={unconfirmed || isOutsideAvailabilityPeriod}
              >
                {this.getServiceType(
                  data.service.id,
                  unconfirmed,
                  isOutsideAvailabilityPeriod
                )}
              </ServiceTypeFlexContainer>
            )}

          <TimeFlexContainer valign="center">
            <DetailsWrapper>
              {type === 'dashboard' && (
                <DetailsWrapper>
                  <Text size="small" color="secondary">
                    {formatDistanceToNow(parseISO(data.start_time), {
                      addSuffix: true,
                      locale: getLocaleObject(intl.locale),
                    })}{' '}
                  </Text>
                </DetailsWrapper>
              )}
              <DetailsWrapper>
                {(unconfirmed || rated) && (
                  <Fragment>
                    <FormattedDate
                      value={data.start_time}
                      weekday="short"
                      day="2-digit"
                      month="short"
                    />
                    &#44;&nbsp;
                  </Fragment>
                )}
                <time>
                  <FormattedTime
                    value={data.start_time}
                    hour="2-digit"
                    minute="2-digit"
                    hour12={false}
                  />
                  &ndash;
                  <FormattedTime
                    value={data.end_time}
                    hour="2-digit"
                    minute="2-digit"
                    hour12={false}
                  />
                </time>
              </DetailsWrapper>
              {data.frequency && !rated && (
                <DetailsWrapper>
                  <FormattedMessage
                    id={`bookings.card.frequency.${data.isSubstituteCleaner ? 'substitute' : data.frequency
                      }`}
                  />
                </DetailsWrapper>
              )}
              {!unconfirmed && type === 'schedule' && (
                <DetailsWrapper>
                  <FormattedMessage
                    id={`bookings.status.${data.status?.toLowerCase()}`}
                  />
                </DetailsWrapper>
              )}
            </DetailsWrapper>
          </TimeFlexContainer>
          <Box display="flex">
            <CustomerName as="p" color="black" bold mb={0}>
              {data.company
                ? data.company.name
                : `${data.person.firstname} ${data.person.lastname}`}
            </CustomerName>

            {!data.rating && data.detailSummary.extendedDuration && data.detailSummary.extendedDuration > 0 && (
              <FiberNewIcon
                style={{ fill: theme.colors.attention, marginLeft: '4px' }}
              />
            )}
          </Box>
          <Address as="p" mb={1}>
            <span>
              {data.address.street_address}
              &#44;
            </span>
            <span>
              {data.address.postcode}
              &nbsp;
              {data.address.city}
            </span>
          </Address>

          {!showDetails && !unconfirmed && !data.rating && (
            <ServicesIconList services={data.detailSummary} />
          )}

          {!showDetails && !unconfirmed && data.rating && data.rating.average && (
            <RatingFlexContainer alignV="center">
              {data.rating && data.rating.comments && (
                <CustomerFeedback>
                  <IconFeedback size={16} />
                </CustomerFeedback>
              )}
              <Rating
                value={data.rating.average}
                precision={0.1}
                size="small"
                readOnly
                style={{
                  fontSize: '16px',
                  color:
                    theme.colors[
                    data.rating.average >= RATING_QUALITY.THRESHOLD
                      ? 'rating'
                      : 'attention'
                    ],
                }}
              />
              <StyledText ml={2} as="p" size="small">
                {formatRating(data.rating.average, locale)}
              </StyledText>
            </RatingFlexContainer>
          )}
        </CardContentContainer>
      </CardContainer>
    );
  }
}

const CardContainer = styled(Box).attrs(props => ({
  tabIndex: 0,
  id: props.id,
}))`
  ${typography.fontRegular()};
  display: grid;
  grid-gap: ${space.default};
  grid-template-columns: ${sizes.width.customerTypeIcon} auto;
  padding-top: ${space.md};
  max-width: ${sizes.width.bookingCardMaxWidth};
  width: calc(100% - calc(${space.sm} * 2));
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1;
  justify-content: center;
  height: ${sizes.width.customerTypeIcon};
  width: ${sizes.width.customerTypeIcon};

  background: ${theme.colors.greyLight};
  border-radius: 50%;

  ${props =>
    props.unconfirmed &&
    css`
      animation: ${AttentionWiggle} 8s 4s infinite;
      background: ${theme.colors.attention};
      transform-origin: 50%;
      will-change: transform;
    `};

  ${props =>
    props.isOutsideAvailabilityPeriod &&
    css`
      background: ${theme.colors.attention};
    `};
`;

const CardContentContainer = styled.section`
  grid-column: 2;
  border-bottom: ${props =>
    props.type === 'schedule' && `1px solid ${theme.colors.greyMed}`};
  padding-bottom: ${props => props.type === 'schedule' && space.sm};
`;

const ServiceTypeFlexContainer = styled(FlexContainer)`
  ${typography.fontLabel()};
  color: ${props =>
    props.unconfirmed ? theme.colors.attention : theme.colors.secondary};
`;

const TimeFlexContainer = styled(FlexContainer)`
  ${typography.fontSmall()};
  color: ${theme.colors.secondary};
  margin-bottom: ${space.xs};
`;

const Address = styled(Text)`
  span {
    display: inline-block;
    padding-right: 4px;
  }
`;

const DetailsWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  padding-right: ${space.sm};
  flex-wrap: wrap;

  + div {
    &:before {
      content: '•';
      padding-right: ${space.sm};
    }
  }

  &:last-child {
    padding-right: 0;
  }
`;

const CustomerName = styled(Text)`
  text-transform: capitalize;
`;

const RatingFlexContainer = styled(FlexContainer)`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${space.sm};
`;

const StyledText = styled(Text)`
  line-height: 1;
  margin: 0;
  margin-left: ${space.sm};
  padding-top: 2px;
`;

const CustomerFeedback = styled.div`
  height: 16px;
  margin-right: ${space.md};
`;

BookingCard.propTypes = propTypes;
BookingCard.defaultProps = defaultProps;

export default withRouter(injectIntl(withLanguage(BookingCard)));
