import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
// import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { format, parseISO } from 'date-fns';
import { Box, Spinner, theme } from '@freska/freska-ui';
import { GET_NEWS_ITEMS } from '../../gql/queries';
import ViewGrid from '../../components/Common/ViewGrid';
import withUser from '../../utils/withUser';
import { space } from '../../theme/theme';
import ClosableContentfulEntry from '../../components/Contentful/ClosableContentfulEntry';
import ContentfulEntry from '../../components/Contentful/ContentfulEntry';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import SupportCard from '../../components/Contentful/SupportCard';

const propTypes = {
  match: shape({ params: shape({ slug: string }) }),
};

function News({ match: { params } }) {
  const { loading, data, error } = useQuery(GET_NEWS_ITEMS);
  const [activeSlug, setActiveSlug] = useState(null);

  if (loading) {
    return (
      <ViewGrid>
        <Box p={3}>
          <Spinner />
        </Box>
      </ViewGrid>
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (params.slug) {
    const item = data.getNewsItems.find(item => params.slug === item.slug);
    return <ContentfulEntry item={item} slug="news" />;
  }

  if (activeSlug) {
    const item = data.getNewsItems.find(item => activeSlug === item.slug);
    // TODO: REPLACE WITH ANOTHER COMPONENT TO SET NULL
    return (
      <ClosableContentfulEntry
        item={item}
        slug="news"
        onClose={() => setActiveSlug(null)}
      />
    );
  }

  return (
    <ViewGrid>
      <GridContainer>
        {data.getNewsItems.map(item => (
          <div onClick={() => setActiveSlug(item.slug)} key={item.slug}>
            <SupportCard
              title={item.title}
              description={
                <FormattedMessage
                  id="news.publishedAt"
                  values={{
                    date: format(parseISO(item.publishOn), 'MMM d, yyyy'),
                    time: format(parseISO(item.publishOn), 'HH:mm'),
                  }}
                />
              }
            />
          </div>
        ))}
      </GridContainer>
    </ViewGrid>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-gap: ${space.sm};
  margin-bottom: ${space.lg};

  a,
  a:active,
  a:visited,
  a:link {
    color: ${theme.colors.primary};
  }

  &:last-child {
    margin-bottom: ${space.sm};
  }
`;

News.propTypes = propTypes;

export default withUser(News);
