import React, { Fragment } from 'react';
import {
  arrayOf,
  shape,
  string,
  func,
  instanceOf,
  bool,
  number,
} from 'prop-types';
import styled from 'styled-components';
import { theme, Label, Text, IconLock, Box } from '@freska/freska-ui';
import { rgba } from 'polished';
import {
  isToday,
  format,
  isWithinInterval,
  parseISO,
  isSameDay,
  isEqual,
} from 'date-fns';
import { useIntl, FormattedDate, FormattedTime } from 'react-intl';
import { formatDuration } from '../../utils/formatNumber';
import FlexContainer from '../Common/FlexContainer';
import EventCard from './EventCard';
import {
  availabilityType,
  bookingType,
  workingScheduleType,
} from '../../types';
import { BOOKING_CALENDAR_ENUM } from '../../constants';
import AbsenceTypeIcon from '../Common/AbsenceTypeIcon';

const propTypes = {
  date: instanceOf(Date).isRequired,
  dayBookings: bookingType,
  dayAvailabilities: availabilityType,
  dayWorkingSchedule: workingScheduleType,
  dayAbsences: arrayOf(shape({})),
  lineBlocks: arrayOf(
    shape({ gridStart: string, gridEnd: string, displayTime: string })
  ).isRequired,
  buildGridRows: func.isRequired,
  startDate: instanceOf(Date).isRequired,
  endDate: instanceOf(Date).isRequired,
  isUserFinnishEmployed: bool.isRequired,
  gridStartTime: shape({ hours: number, minutes: number }).isRequired,
};

const defaultProps = {
  dayAvailabilities: { availabilities: [] },
  dayBookings: { date: null, bookings: [] },
  dayWorkingSchedule: {},
  dayAbsences: [],
};

function svgIconWrapper(iconName, size = 24, color = theme.colors.secondary) {
  const paths = {
    lunch:
      'M8.1 13.34l2.83-2.83-6.19-6.18c-.48-.48-1.31-.35-1.61.27-.71 1.49-.45 3.32.78 4.56l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38 1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27L4.4 19.17a.996.996 0 101.41 1.41L12 14.41l6.18 6.18a.996.996 0 101.41-1.41L13.41 13l1.47-1.47z',
    duration:
      'M14 1h-4c-.55 0-1 .45-1 1s.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1zm-2 13c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm7.03-6.61l.75-.75c.38-.38.39-1.01 0-1.4l-.01-.01c-.39-.39-1.01-.38-1.4 0l-.75.75C16.07 4.74 14.12 4 12 4c-4.8 0-8.88 3.96-9 8.76C2.87 17.84 6.94 22 12 22c4.98 0 9-4.03 9-9 0-2.12-.74-4.07-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z',
    unlocked:
      'M12 13c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6-5h-1V6c0-2.76-2.24-5-5-5-2.28 0-4.27 1.54-4.84 3.75-.14.54.18 1.08.72 1.22.53.14 1.08-.18 1.22-.72C9.44 3.93 10.63 3 12 3c1.65 0 3 1.35 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 11c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1v-8c0-.55.45-1 1-1h10c.55 0 1 .45 1 1v8z',
  };

  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path fill={color} d={paths[iconName]} />
    </svg>
  );
}

function formatTimeForGrid(time) {
  const datetime = new Date(time);
  const coeff = 1000 * 60 * BOOKING_CALENDAR_ENUM.GRID_INTERVAL;
  const roundedToNearestInterval = new Date(
    Math.round(datetime.getTime() / coeff) * coeff
  );
  return format(roundedToNearestInterval, 'HHmm');
}

function formatMinutesToTime(minutes, intl) {
  const durationFloat = minutes / 60;
  return formatDuration(durationFloat, intl, 'short', true);
}

function DayColumn({
  dayBookings: { bookings },
  date,
  dayAvailabilities: { availabilities },
  lineBlocks,
  buildGridRows,
  dayWorkingSchedule,
  dayAbsences,
  startDate,
  endDate,
  isUserFinnishEmployed,
  gridStartTime,
}) {
  const intl = useIntl();
  const isTodaysDate = isToday(date);
  const dateClone = new Date(date);
  const durationSum = availabilities.reduce(
    (acc, val) => acc + val.duration,
    0
  );

  function isOutsideAvailability(booking) {
    return !availabilities.some(
      availability =>
        isWithinInterval(parseISO(booking.start_time), {
          start: parseISO(availability.start_time),
          end: parseISO(availability.end_time),
        }) &&
        isWithinInterval(parseISO(booking.end_time), {
          start: parseISO(availability.start_time),
          end: parseISO(availability.end_time),
        })
    );
  }

  function calculateGridStartForRender(absenceStartTimeString) {
    const absenceStartTime = parseISO(absenceStartTimeString);

    if (isSameDay(date, absenceStartTime) && !isEqual(date, absenceStartTime))
      return formatTimeForGrid(absenceStartTime);

    return formatTimeForGrid(dateClone.setHours(gridStartTime.hours, 0));
  }

  return (
    <DayColumnContainer column key={date}>
      <HeaderBackground>
        <Header row center py="4px">
          <SmallLabel textAlign="right" color="secondary" mr={1} mb={0}>
            <FormattedDate value={date} weekday="short" />
          </SmallLabel>
          <TodayCircle isToday={isTodaysDate}>
            <Text
              as="span"
              bold
              mb={0}
              color={isTodaysDate ? theme.colors.white : theme.colors.black}
            >
              <FormattedDate value={date} day="2-digit" />
            </Text>
          </TodayCircle>
          <SmallLabel textAlign="left" color="secondary" ml={1} mb={0}>
            <FormattedDate value={date} month="short" />
          </SmallLabel>
        </Header>
      </HeaderBackground>
      {isUserFinnishEmployed && (
        <WorkSchedule column pt="4px">
          {dayWorkingSchedule.date && (
            <Fragment>
              <Box display="inline-flex" alignItems="center" height="12px">
                {dayWorkingSchedule.locked ? (
                  <IconLock size={12} mr="4px" color={theme.colors.primary} />
                ) : (
                  <Box display="inline-block" mr="4px">
                    {svgIconWrapper('unlocked', 12)}
                  </Box>
                )}
                <SmallLabel color="black" mb={0}>
                  <FormattedTime
                    value={dayWorkingSchedule.day_start}
                    hour="2-digit"
                    minute="2-digit"
                    hour12={false}
                  />
                  &ndash;
                  <FormattedTime
                    value={dayWorkingSchedule.day_end}
                    hour="2-digit"
                    minute="2-digit"
                    hour12={false}
                  />
                </SmallLabel>
              </Box>
              <Box>
                <SmallLabel color="black" mb={0}>
                  {durationSum > 0 && dayWorkingSchedule.locked && (
                    <Box display="inline-flex" mr={1} alignItems="center">
                      <Box display="inline-block" mr="4px">
                        {svgIconWrapper('duration', 12)}
                      </Box>
                      {formatMinutesToTime(durationSum, intl)}
                    </Box>
                  )}
                  {dayWorkingSchedule.lunchDuration > 0 && (
                    <Box display="inline-flex" alignItems="center">
                      <Box display="inline-block" mr="4px">
                        {svgIconWrapper('lunch', 12)}
                      </Box>
                      {formatMinutesToTime(
                        dayWorkingSchedule.lunchDuration,
                        intl
                      )}
                    </Box>
                  )}
                </SmallLabel>
              </Box>
            </Fragment>
          )}
        </WorkSchedule>
      )}
      <Timeline
        date={date}
        buildGridRows={buildGridRows}
        gridStartTime={gridStartTime}
      >
        {availabilities &&
          availabilities.map(availability => (
            <AvailabilityBlock
              key={availability.start_time}
              gridStart={formatTimeForGrid(availability.start_time)}
              gridEnd={formatTimeForGrid(availability.end_time)}
            />
          ))}
        {dayAbsences &&
          dayAbsences.map(absence => (
            <AbsenceBlock
              key={absence.id}
              gridStart={calculateGridStartForRender(absence.start_time)}
              gridEnd={formatTimeForGrid(
                isSameDay(date, parseISO(absence.end_time))
                  ? absence.end_time
                  : dateClone.setHours(0, 30)
              )}
            >
              <Box p="4px">
                <AbsenceTypeIcon type={absence.reason.key} color="white" />
              </Box>
            </AbsenceBlock>
          ))}
        {lineBlocks &&
          lineBlocks.map(block => (
            <Lines
              key={`${date}-${block.gridStart}${block.gridEnd}`}
              gridStart={block.gridStart}
              gridEnd={block.gridEnd}
              className="line"
            />
          ))}
        {isUserFinnishEmployed && dayWorkingSchedule.date && (
          <WorkScheduleBlock
            gridStart={formatTimeForGrid(dayWorkingSchedule.day_start)}
            gridEnd={formatTimeForGrid(dayWorkingSchedule.day_end)}
            locked={dayWorkingSchedule.locked}
          />
        )}
        {bookings &&
          bookings.map(booking => (
            <EventCard
              key={booking.id}
              booking={booking}
              formatTimeForGrid={formatTimeForGrid}
              startDate={startDate}
              endDate={endDate}
              isOutsideAvailability={
                availabilities ? isOutsideAvailability(booking) : false
              }
            />
          ))}
      </Timeline>
    </DayColumnContainer>
  );
}

const DayColumnContainer = styled(FlexContainer)`
  background: ${theme.colors.greyLight};
  border-radius: ${theme.borderRadius}px;
  min-width: 80px;
`;

const HeaderBackground = styled(FlexContainer)`
  background-color: ${theme.colors.greyLight};
  align-content: flex-start;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  min-width: 80px;
  width: 100%;
  flex-direction: column;
  z-index: 4;
`;

const Header = styled(FlexContainer)`
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius}px ${theme.borderRadius}px 0 0;
  width: 100%;
`;

const TodayCircle = styled(FlexContainer)`
  background-color: ${props =>
    props.isToday ? theme.colors.primary : theme.colors.white};
  display: inline-flex;
  align-content: flex-start;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
`;

const SmallLabel = styled(Label)`
  font-size: 10px;
`;

const WorkSchedule = styled(FlexContainer)`
  background-color: ${theme.colors.white};
  align-content: flex-start;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
`;

const WorkScheduleBlock = styled.div`
  border-top: 2px solid
    ${props => (props.locked ? theme.colors.primary : theme.colors.secondary)};
  border-bottom: 2px solid
    ${props => (props.locked ? theme.colors.primary : theme.colors.secondary)};
  grid-row: ${props => `time-${props.gridStart} / time-${props.gridEnd}`};
  grid-column: 1;
`;

const Timeline = styled.div`
  background-color: ${theme.colors.white};
  display: grid;
  grid-gap: 0;
  grid-template-rows: ${props =>
    props.buildGridRows(props.date, props.gridStartTime)};
  grid-template-columns: minmax(80px, 1fr);
  border-bottom: 1px solid ${theme.colors.greyLight};
  padding-top: ${theme.space[1]}px;
`;

const AvailabilityBlock = styled.div`
  background-color: ${rgba(theme.colors.success, 0.25)};
  grid-row: ${props => `time-${props.gridStart} / time-${props.gridEnd}`};
  grid-column: 1;
`;

const AbsenceBlock = styled.div`
  background-color: ${theme.colors.completed};
  grid-row: ${props => `time-${props.gridStart} / time-${props.gridEnd}`};
  grid-column: 1;
`;

const Lines = styled.div`
  background: transparent;
  grid-row: ${props => `time-${props.gridStart} / time-${props.gridEnd}`};
  grid-column: 1;
  border-bottom: 1px solid ${theme.colors.greyLight};
  border-top: 1px solid ${theme.colors.greyLight};
  &.line ~ .line {
    border-top: none;
  }
`;

DayColumn.propTypes = propTypes;
DayColumn.defaultProps = defaultProps;

export default DayColumn;
