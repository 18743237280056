export default {
  "app": {
    "cancel": "Anuluj",
    "close": "Zamknij",
    "menu": "Menu",
    "open": "Otwórz",
    "save": "Zapisz",
    "delete": "Usuń"
  },
  "snackbar": {
    "offline": "Jesteś teraz offline! Ograniczona funkcjonalność dostępna.",
    "online": "Jesteś z powrotem online! Strona odświeży się automatycznie za 3 sekundy…",
    "new_version": "Nowa wersja aplikacji jest dostępna!",
    "action": {
      "update_now": "Uaktualnij teraz",
      "refresh": "Odswież stronę"
    }
  },
  "areas": {
    "title": "Obszary pracy",
    "next_booking": "Następna rezerwacja",
    "home_address_marker_desc": "Twoj dom",
    "toggle_visibility_working_area_caption": "{isShown, select, true {Hide} other {Show}} my working areas",
    "toggle_visibility_customer_markers_caption": "{isShown, select, true {Hide} other {Show}} my customers",
    "booking_details_link": "Otwórz szczegóły rezerwacji",
    "error_loading_map": "Podczas wczytywania mapy coś poszło nie tak. Spróbuj ponownie później lub skontaktuj się z zespołem serwisowym Freska pod adresem service.team@freska.fi"
  },
  "availability": {
    "add_period": "Dodaj okres dostępności",
    "availability_details": "Szczegóły dostępności",
    "availability_this_day": "Dostępność w tym dniu",
    "back_link": "Wróć do kalendarza",
    "bookings_this_day": "{num, plural, =0 {Bookings} one {{num} booking} other {{num} bookings}} this day",
    "calendar": "Kalendarz",
    "continuous": "Ciągły",
    "create_new_availability": "Utwórz nową tygodniową dostępność",
    "create_upcoming_availability": "Utwórz regułę dostępności",
    "edit_rule": "Edytuj regułę",
    "edit_weekly_availability": "Edytuj tygodniową dostępność",
    "end_on": "Zakończ",
    "ends": "Koniec",
    "off": "Off",
    "only_one_offs": "Tylko rezerwacje jednorazowe",
    "start_from": "Zacznij od",
    "starting_on": "Dostępne od",
    "ending_on": "Dostępne do",
    "please_note": "Proszę zanotować",
    "stable_availability": " Stabilna dostępność co najmniej 12 tygodni zwiększa Twoje szanse na zdobycie stałych klientów",
    "rules": "Zasady dostępności",
    "starts": "Rozpoczyna się",
    "title": "Dostępność",
    "modal_title": "Dostępność w",
    "modal_description": "{date}",
    "no_bookings_this_day": "Brak rezerwacji na ten dzień",
    "no_availability_this_day": "Nie dodano jeszcze dostępności",
    "one_off_modal": {
      "title": "Tylko jednorazowe",
      "message": "Decydujesz się być dostępny/a tylko dla jednorazowych rezerwacji. Pamiętaj, że po wybraniu tej opcji nie zostaniesz przypisany/a do żadnych stałych klientów, którzy mają powtarzające się rezerwacje. Włączenie tej opcji nie spowoduje usunięcia już istniejących stałych klientów.",
      "confirm": "Tak, daj mi tylko jednorazowych klientów"
    },
    "delete_modal": {
      "title": "Usuń dostępność",
      "message": "Jeśli usuniesz tę dostępność, Twoje rezerwacje nie zostaną automatycznie anulowane. Nadal musisz skontaktować się z biurem Freska przez WhatsApp."
    },
    "errors": {
      "intervals_only": "Zauważ, że wprowadzony czas został zaokrąglony do najbliższego przedziału 15 minut."
    },
    "remove_availability": "Usuń",
    "save_availability": "Zapisz dostępność",
    "disabled_explanation": "Nadchodzącą dostępność można ustawić tylko wtedy, gdy pierwsza z nich ma datę końcową"
  },
  "absence": {
    "absence_types": {
      "sick_leave": "Na chorobowym",
      "vacation": "Na urlopie",
      "other_absence": "Na urlopie bezpłatnym"
    },
    "last_day_of_absence": "Ostatni dzień nieobecności: ",
    "report_fit_link_caption": "Zgłoś gotowość do pracy",
    "still_sick": "Jestem nadal chory/a",
    "modal_close": "Zamknij",
    "action_fit_for_work": "Jestem zdolny/a do pracy",
    "modal_heading": "Zgłoś zdolność do pracy",
    "modal_text_intro_before_threshold": "Jesteś obecnie na zwolnieniu chorobowym. Zgłoś <b>przed {time} dzis</b> jeśli jesteś gotowy/a do pracy od jutra.",
    "modal_text_intro_after_threshold": "Jesteś obecnie na zwolnieniu chorobowym. <b>Minął dzisiejszy termin {time} ostateczny</b> na zgłoszenie gotowości do pracy jutro.",
    "modal_text_details_before_threshold": "W przeciwnym razie Twoje rezerwacje na jutro zostaną automatycznie przełożone lub przeniesione.",
    "modal_text_details_after_threshold": "Rezerwacje na jutro zostały anulowane. Jeśli zgłosisz zdolność do pracy teraz, oczekuje się, że wrócisz do pracy <b>pojutrze</b> w celu wykonania następnej zaplanowanej rezerwacji. Pytania? Proszę skontaktuj się z nami.",
    "modal_text_details_still_sick": "Wciąż chory/a? Życzymy szybkiego powrotu do zdrowia, a kiedy lepiej się poczujesz, pamiętaj, aby zgłosić się na czas.",
    "error_updating_absence": "Ups! Coś poszło nie tak. Nie mogliśmy pomyślnie zgłosić, że jesteś zdolny/a do pracy jutro. Skontaktuj się z biurem Freska, aby zgłosić, że jesteś sprawny/a.",
    "error_fetching_ongoing_absences": "Wystąpił błąd podczas wyszukiwania aktualnych nieobecności. Spróbuj ponownie później, a w razie pilnej potrzeby skontaktuj się z biurem Freska"
  },
  "access": {
    "status": {
      "no_key": "Klient powinien otworzyć drzwi",
      "door_code_only": "Tylko kod do drzwi",
      "stored_by_customer": "Przechowywany przez klienta",
      "key": "Klucz"
    },
    "labels": {
      "door_code": "Kod do drzwi",
      "access_notes": "Notatki do wejścia"
    }
  },
  "bookings": {
    "calendar": "Kalendarz",
    "completed": "Gotowe",
    "hide_past_bookings": "Ukryj poprzednie rezerwacje",
    "last_week": "Ubiegły tydzień",
    "next_week": "Przyszły tydzień",
    "no_bookings": "Brak rezerwacji w tym tygodniu",
    "show_past_bookings": "Pokaż poprzednie rezerwacje",
    "title": "Rezerwacje",
    "this_week": "Ten tydzień",
    "today": "Dzisiaj",
    "total_time": "{totalBookings} {totalBookings, plural, one {Booking} other {Bookings}} this week (total {totalHours} {totalHours, plural, one {hour} other {hours}})",
    "unconfirmed_bookings": "{num} Unconfirmed {num, plural, one {Booking} other {Bookings}}",
    "upcoming": "Nadchodząca",
    "week_num": "Tydzień {num}",
    "company_contact_person": "Osoba kontaktowa: ",
    "new_customer": "Nowy klient!",
    "extended_duration": "To pierwsze sprzątanie nowego klienta. Do tej rezerwacji dodaliśmy {duration} minut, abyś miał/a czas na wykonanie 5-gwiazdkowego sprzątania. Możesz wykorzystać dodatkowy czas, aby dodatkowo dokładnie sprawdzić najważniejsze obszary i wpisać pozdrowienia na powitalnej karcie domowej.💙",
    "card": {
      "access": "Dostęp do szczegółów",
      "additional_services": "Dodatkowe usługi",
      "address_notes": "Notatki adresowe",
      "cleaning_supplies": "Środki czystości",
      "customer_notes": "Notatki klienta",
      "window_cleaning_notes": "Szczegóły mycia okien",
      "directions": "Sprawdź dojazd",
      "rating": "Ocena",
      "average_rating": "Średnia ocena",
      "no_rating_provided": "Brak oceny",
      "service_type": {
        "1": "Sprzątanie domowe",
        "2": "Sprzątanie biurowe",
        "3": "Mycie okien",
        "4": "Sprzątanie przeprowadzkowe",
        "6": "Sprzątanie szpitala",
        "7": "Gruntowne sprzątanie",
        "8": "Sprzątanie przed pokazem na sprzedaż",
        "9": "Spotkanie w biurze",
        "10": "Odbiór klucza",
        "11": "Przerwa",
        "12": "Dojazd",
        "13": "Późna anulacja",
        "14": "Szkolenie",
        "15": "Urlop",
        "16": "Urlop rodzicielski",
        "17": "Unpaid leave",
        "18": "Zwolnienie chorobowe",
        "19": "Zwolnienie chorobowe dziecka",
        "20": "Sprzątanie domowe Flex",
        "21": "Usługa wakacyjna",
        "22": "Sprzątanie klatki schodowej",
        "23": "Sprzątanie niestandartowe",
        "36": "Sprzątanie przeprowadzkowe",
        "38": "Sprzątanie szpitala"
      },
      "frequency": {
        "0": "Jednorazowe",
        "1": "Co tygodniowe",
        "2": "Co dwutygodniowe",
        "4": "Co czterotygodniowe",
        "substitute": "Zastępstwo"
      },
      "pets": {
        "cat": "Kot",
        "dog": "Pies",
        "other": "Inne",
        "pets": "Zwierzę"
      }
    },
    "details": {
      "back_link": "Wróć do przeglądu",
      "booking_details": "Szczegóły rezerwacji",
      "cleaning_supplies": {
        "by_freska": "Firmowe środki czystości z Freska.",
        "by_customer": "Klient zapewnia środki czystości."
      },
      "sw_comment_to_freska": "Twój komentarz do Freska",
      "service_type": "Rodzaj usługi",
      "service_frequency": "Częstotliwość",
      "window_cleaning": {
        "window_groups": "Grupy okien",
        "single_windows": "Pojedyńcze okna",
        "balcony": "Balkon",
        "balcony_value": "Tak, {num} szyb",
        "no_balcony": "Nie",
        "number_of_layers": "Szyb/okno",
        "ventilation_windows": "Onka wentylacyjne",
        "included": "Tak",
        "not_included": "Nie",
        "blinds": "Żaluzje"
      },
      "feedback_history": {
        "no_history": "Brak opinii klienta dotyczącej tego abonamentu.",
        "title": "Historia opinii"
      },
      "tab": {
        "details": "Szczegóły",
        "instructions": "Instrukcje",
        "feedback": "Opinie"
      },
      "instructions": {
        "title": "Instructions",
        "customer_instructions_title": "Instrukcje sprzątania klienta",
        "worker_notes_title": "Notatki pracownika",
        "worker_notes_description": "Notatki dla pracowników są przeznaczone do wszystkiego, co Twoje zastępstwo musi wiedzieć o wykonywaniu wysokiej jakości {usługi} u tego klienta na wypadek choroby lub urlopu. Każdy pracownik wykonujący {usługę} u tego klienta będzie mógł zobaczyć te notatki.",
        "worker_notes_info_tag": "Pomoc",
        "no_cleaning_instructions": "Klient nie dostarczył instrukcji sprzątania.",
        "error_deleting_worker_note": "Usunięcie notatki nie powiodło się. Odśwież stronę i spróbuj ponownie. Jeśli problem nie ustąpi, skontaktuj się z biurem Freska.",
        "error_fetching_worker_notes": "Pobieranie notatek nie powiodło się. Odśwież stronę, aby spróbować ponownie. Jeśli problem będzie się powtarzał, skontaktuj się z biurem Freska.",
        "error_saving_worker_note": "Zapisywanie zmian w notatce nie powiodło się. Odśwież stronę i spróbuj ponownie. Jeśli problem będzie się powtarzał, skontaktuj się z biurem Freska.",
        "save_changes": "Zapisz zmiany",
        "add_note": "Dodaj notatkę",
        "cancel_add_note": "Odrzuć",
        "cancel_edit_note": "Odrzuć",
        "last_updated": "Ostatnio zaktualizowane ",
        "delete_confirmation_modal": {
          "title": "Usuń notatkę",
          "message": "Ta czynność usunie tę notatkę dla wszystkich (przyszłych) pracowników u tego klienta i usługi. Tej czynności nie można cofnąć.",
          "button_caption": "Usuń notatkę"
        },
        "no_worker_notes": "Nie dodano jeszcze żadnych notatek pracownika dla tego klienta i usługi.",
        "error_saving_worker_notes": "Błąd podczas zapisywania notatek pracownika. Spróbuj ponownie później"
      }
    },
    "status": {
      "approved": "Zatwierdzone",
      "booked": "Zarezerwowane",
      "completed": "Ukończone",
      "invoiced": "Zafakturowane",
      "paid": "Zapłacone",
      "prebooked": "Wstępnie zarezerwowane",
      "unconfirmed": "Niepotwierdzone",
      "uninvoiced": "Niezafakturowane",
      "outside_availability_period": "Dostępność zewnętrzna",
      "canceled": "Anulowane"
    },
    "summary": "Streszczenie",
    "unconfirmed": {
      "comments_to_freska": "Komentarze do Freski (opcjonalnie)",
      "confirm_as_completed": "Ukończyłem/am tę rezerwację",
      "ended": "Zakończone",
      "show_booking_details": "Pokaż szczegóły rezerwacji",
      "started": "Rozpoczęte",
      "tips_and_tricks": "Porady i wskazówki dla osób sprzątających",
      "comments_to_freska_placeholder": "Napisz tutaj tylko wtedy, gdy faktyczny czas był inny niż w rezerwacji lub jeśli jest coś, o czym powinniśmy wiedzieć. Ten tekst jest opcjonalny i niewidoczny dla klienta!"
    }
  },
  "calendar": {
    "modal": {
      "title": "Szczegóły dostępności"
    }
  },
  "keys": {
    "title": "Klucze",
    "back_link": "Powrót do przeglądu",
    "key_details": "Szczegóły o kluczu",
    "notes": "Notatki",
    "next_booking": "Następna rezerwacja",
    "no_keys": "Nie znaleziono kluczy",
    "pick_up_before_date": "Odbiór przed: {date}",
    "return_latest_by_date": "Zwróć najpóźniej do: {date}",
    "section_headers": {
      "return": "Klucze do zdania w biurze",
      "pick_up": "Klucze do odbioru z biura",
      "not_urgent": "Klucze które powinieneś/powinnnaś mieć"
    },
    "status": {
      "at_office": "Biuro Freska",
      "at_you": "Ty",
      "at_another_cleaner": "Inna osoba sprzątająca",
      "at_cleaner": "Ty"
    },
    "tags": {
      "return": "Zwróć klucz do biura",
      "pick_up": "Wymaga odbioru"
    },
    "labels": {
      "return_date": "Ostatnia data zwrotu",
      "label": "Etykieta klucza",
      "safekeeper": "Status klucza",
      "pick_up_location": "Miejsce odbioru",
      "key_notes": "Notatki o kluczu"
    }
  },
  "dates": {
    "mon": "Pon",
    "monday": "Poniedziałek",
    "tue": "Wto",
    "tuesday": "Wtorek",
    "wed": "Śro",
    "wednesday": "Środa",
    "thu": "Czw",
    "thursday": "Czwartek",
    "fri": "Pią",
    "friday": "Piatek",
    "sat": "Sob",
    "saturday": "Sobota",
    "sun": "Nie",
    "sunday": "Niedziela",
    "jan": "Sty",
    "january": "Styczeń",
    "feb": "Lut",
    "february": "Luty",
    "mar": "Mar",
    "march": "Marzec",
    "apr": "Kwi",
    "april": "Kwiecień",
    "may": "Maj",
    "may_full": "Maj",
    "jun": "Cze",
    "june": "Czerwiec",
    "jul": "Lip",
    "july": "Lipiec",
    "aug": "Sie",
    "august": "Sierpień",
    "sep": "Wrz",
    "september": "Wrzesień",
    "oct": "Paz",
    "october": "Październik",
    "nov": "Lis",
    "november": "Listopad",
    "dec": "Gru",
    "december": "Grudzień"
  },
  "pluralDates": {
    "mon": "Poniedziałki",
    "tue": "Wtorki",
    "wed": "Środy",
    "thu": "Czwartki",
    "fri": "Piątki",
    "sat": "Soboty",
    "sun": "Niedziele"
  },
  "shortDates": {
    "mon": "Pon",
    "tue": "Wto",
    "wed": "Śro",
    "thu": "Czw",
    "fri": "Pia",
    "sat": "Sob",
    "sun": "Nie"
  },
  "time": {
    "duration_hours": {
      "short": "{hours}godz",
      "long": "{hours} {hours, plural, one {hour} other {hours}}"
    },
    "duration_minutes": {
      "short": "{minutes}min",
      "long": "{minutes} {minutes, plural, one {minute} other {minutes}}"
    }
  },
  "error": {
    "contact_support": "Jeśli problem nie ustąpi, skontaktuj się z biurem Freska.",
    "default": "Wystąpił błąd. Proszę spróbuj ponownie.",
    "incorrect_credentials": "Twój adres e-mail i/lub hasło są nieprawidłowe",
    "something_went_wrong": "Wygląda na to, że coś poszło nie tak. Nie martw się, my to uprzątniemy.",
    "start_over": "Zacznij od nowa",
    "whoops": "Ups!",
    "wrong_input": "Hasła się nie zgadzają",
    "offline_header": "Niedostępne w trybie offline",
    "offline_description": "Gdy jesteś offline, aplikacja Superbohaterowie zapewnia ograniczoną funkcjonalność w oparciu o poprzednie odwiedzane strony. Pełna funkcjonalność zostanie przywrócona po nawiązaniu połączenia z Internetem."
  },
  "login": {
    "email": "Email",
    "password": "Hasło",
    "login": "Zaloguj się",
    "return_to_login": "Wróć do logowania",
    "forgot_password": "Zapomniałeś/aś hasła?",
    "reset_password": "Wyślij instrukcje resetowania",
    "reset_password_email_sent": "Wiadomość e-mail z instrukcjami została wysłana na {email}.",
    "translate_msg": "Po fińsku",
    "reset_password_success": "Twoje hasło zostało zmienione"
  },
  "nav": {
    "logout": "Wyloguj się"
  },
  "dashboard_news": {
    "title": "Menu"
  },
  "dashboard": {
    "title": "Panel",
    "latest_news": "Najnowsze wiadomości",
    "all_news": "Qszystkie wiadomości",
    "next_booking": "Teraz/Następne",
    "view_schedule": "Zobacz schemat",
    "published": "Opublikowano dnia {date} o godz. {time}",
    "news_fetch_failed": "Przepraszamy, coś się stało podczas pobierania wiadomości",
    "action_required": "Twoje działanie jest wymagane",
    "booking_status": "Niepotwierdzona rezerwacja",
    "up_to_date": "Jesteś na bieżąco! Świetnie! 💪",
    "customer_instructions_available": "Dostępne instrukcje do klienta!",
    "no_upcoming_cleanings": "Spokojnie! Brak rezerwacji w ciągu najbliższych trzech dni.",
    "fetching_next_failed": "Coś się stało podczas pobierania następnej rezerwacji. Zobacz schemat, aby zobaczyć nadchodzące sprzątania.",
    "view": "Podgląd",
    "key_status": {
      "at_cleaner": "Zwróć klucz do biura",
      "at_office": "Odbiór klucza z biura"
    }
  },
  "not_found": {
    "return": "Zwrot",
    "title": "PNie znaleziono strony",
    "whoops": "Ups… nie można znaleźć tej strony."
  },
  "support": {
    "title": "Pomoc",
    "back_link": "Wróc do pomocy"
  },
  "news": {
    "title": "Nowości",
    "back_link": "Powrót do przeglądu",
    "publishedAt": "Opublikowano dnia {date} o godz. {time}"
  },
  "profile": {
    "title": "Profil",
    "back_link": "Powrót do profilu",
    "authenticated_user": "Uwierzytelniony użytkownik",
    "password": {
      "change_password": "Zmień hasło",
      "confirm_new_password": "Potwierdź nowe hasło",
      "current_password": "Aktualne hasło",
      "new_password": "Nowe hasło",
      "update": "Uaktualnij hasło"
    },
    "language": {
      "options": {
        "english": "Angielski",
        "finnish": "Fiński"
      },
      "set_language": "Ustaw język"
    },
    "success": "Twoje hasło zostało zmienione"
  },
  "ratings": {
    "average_rating": "Średnia ocena przez ostatnie {num} miesięcy",
    "behavior": "Interakcja",
    "comments": "Opinie klientów",
    "latest_ratings": "Najnowsze oceny",
    "on_time": "Na czas",
    "overall": "Ogólna",
    "title": "Ocena",
    "total_cleanings": "Ilość sprzątań",
    "total_hours": "Ilość godzin",
    "quality": "Jakość",
    "no_customer_feedback_provided": "Klient nie wystawił opinii na temat tej rezerwacji."
  },
  "services": {
    "fridge": "Lodówka",
    "ironing": "Prasowanie ubrań",
    "oven_cleaning": "Czyszczenie piekarnika",
    "sauna_cleaning": "Sprzątanie sauny",
    "trash": "Odpady"
  },
  "face_mask": {
    "title": "Zawsze noś maseczke na twarz",
    "description": "Aby zapobiec rozprzestrzenianiu się koronawirusa, noszenie maskeczki na twarzy jest mocno zalecane."
  },
  "article_was_not_found": "Niestety nie znaleziono artykułu. Wróć i spróbuj ponownie."
}