import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { shape, func } from 'prop-types';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { Heading, Logo, Card } from '@freska/freska-ui';
import { CHANGE_PASSWORD, LOGIN } from '../../gql/mutations';
import getClient from '../../api/client';
import validateData from '../../utils/validate';
import schema from '../../components/Forms/schemas/changePasswordSchema';

import NotificationBlock from '../../components/Common/NotificationBlock';
import ChangePasswordForm from '../../components/Forms/ChangePasswordForm';
import FlexContainer from '../../components/Common/FlexContainer';

const propTypes = {
  history: shape({ replace: func }).isRequired,
};

class ResetPasswordContainer extends PureComponent {
  state = {
    loading: false,
    errors: {},
    password: '',
    passwordConfirmation: '',
  };

  handleChange = (e, field) => {
    this.setState({ [field]: e.target.value });
  };

  handleSubmit = e => {
    const { password, passwordConfirmation } = this.state;
    e.preventDefault();
    const variables = {
      password,
      passwordConfirmation,
    };
    validateData(variables, schema).then(res =>
      this.setState(
        { errors: res },
        () => Object.keys(res).length === 0 && this.submit(variables)
      )
    );
  };

  submit = variables => {
    const params = new URLSearchParams(window.location.search);
    const headers = {
      'access-token': params.get('token'),
      uid: params.get('uid'),
      client: params.get('client_id'),
      expiry: params.get('expiry'),
    };
    this.setState({ loading: true });
    const client = getClient(headers);
    client
      .mutate({
        mutation: CHANGE_PASSWORD,
        variables,
      })
      .then(() => {
        const { history } = this.props;
        this.setState({ loading: false, error: null });

        history.replace({
          pathname: '/login',
          state: { isFromResetPasswordComponent: true },
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: error.graphQLErrors.some(
            ({ message }) => message === 'Wrong user input'
          )
            ? 'wrong_input'
            : 'default',
        });
      });
  };

  render() {
    const {
      loading,
      error,
      errors,
      password,
      passwordConfirmation,
    } = this.state;
    return (
      <FlexContainer center fullScreen column>
        <FlexContainer center>
          <Logo height={40} blue mb={4} />
        </FlexContainer>
        <FormWrapper>
          <Heading level={2} mt={1} mb={3}>
            <FormattedMessage id="profile.password.change_password" />
          </Heading>

          {error && (
            <NotificationBlock hasError>
              <FormattedMessage id={`error.${error}`} />
            </NotificationBlock>
          )}
          <ChangePasswordForm
            centerButton
            loading={loading}
            onSubmit={this.handleSubmit}
            password={password}
            passwordConfirmation={passwordConfirmation}
            onChange={this.handleChange}
            errors={errors}
          />
        </FormWrapper>
      </FlexContainer>
    );
  }
}

const FormWrapper = styled(Card)`
  margin: 0 auto;
  max-width: 400px;
  width: 90%;
`;

ResetPasswordContainer.propTypes = propTypes;

export default graphql(LOGIN, {
  name: 'login',
})(withRouter(ResetPasswordContainer));
