import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme, typography, Text, Box } from '@freska/freska-ui';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import withLanguage from '../../utils/withLanguage';
import { getServiceTypeIcon } from '../../utils/bookingsHelper';
import {
  formatUnitPrice,
  formatPrice,
  formatNumber,
} from '../../utils/formatNumber';
import { LANGUAGES_TYPE } from '../../constants';
import { bookingType } from '../../types';
import FlexContainer from '../Common/FlexContainer';
import { space, sizes } from '../../theme/theme';

const propTypes = {
  language: PropTypes.oneOf(LANGUAGES_TYPE).isRequired,
  currency: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  booking: bookingType.isRequired,
  unitPrice: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  totalPrice: PropTypes.PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

function areDurationsDifferent(language, invoicedDuration, duration) {
  return (
    invoicedDuration &&
    formatNumber(invoicedDuration, language) !==
    formatNumber(duration, language)
  );
}

const InvoicedBookingCard = ({
  booking,
  serviceType,
  unitPrice,
  totalPrice,
  language,
  currency,
}) => (
  <CardContainer id={booking.id} as="article" className="card-section">
    <IconContainer mr={3}>{getServiceTypeIcon(20, serviceType)}</IconContainer>
    <CardContentContainer>
      {areDurationsDifferent(
        language,
        booking.invoiceable_duration,
        booking.duration
      ) && (
          <ServiceTypeFlexContainer>
            <FormattedMessage id="invoices.extra_hours" />
          </ServiceTypeFlexContainer>
        )}
      <TimeFlexContainer alignV="center">
        <FormattedDate
          value={booking.start_time}
          month="2-digit"
          day="2-digit"
          weekday="short"
        />
        &nbsp;&#8226;&nbsp;
        <FormattedTime value={booking.start_time} hour12={false} />
        &nbsp;&ndash;&nbsp;
        <FormattedTime value={booking.end_time} hour12={false} />
      </TimeFlexContainer>
      <MainContentContainer>
        <Text as="span">
          <StrikethrouthText
            as="span"
            withStrike={!!booking.invoiceable_duration}
          >
            <FormattedMessage
              id="time.duration_hours.short"
              values={{ hours: formatNumber(booking.duration, language) }}
            />
          </StrikethrouthText>
          {!!booking.invoiceable_duration && (
            <FormattedMessage
              id="time.duration_hours.short"
              values={{ hours: Number(booking.invoiceable_duration) }}
            />
          )}
          {` × `}
          {formatUnitPrice(unitPrice, language, currency)}
        </Text>
        <Text as="span" align="right" bold>
          {formatPrice(Number(totalPrice), language, currency)}
        </Text>
      </MainContentContainer>
    </CardContentContainer>
  </CardContainer>
);

const CardContainer = styled(Box).attrs(props => ({
  id: props.id,
}))`
  ${typography.fontRegular()};
  display: grid;
  grid-gap: ${space.md};
  grid-template-columns: ${sizes.width.customerTypeIcon} 1fr;
  margin-bottom: ${space.md};
  max-width: ${sizes.width.bookingCardMaxWidth};
`;

const ServiceTypeFlexContainer = styled(FlexContainer)`
  ${typography.fontLabel()};
  color: ${theme.colors.attention};
`;

const StrikethrouthText = styled(Text)`
  ${props =>
    props.withStrike &&
    `
    text-decoration: line-through;
    color: ${theme.colors.attention}
  `}
`;

const IconContainer = styled.div`
  align-items: center;
  background: ${theme.colors.greyLight};
  border-radius: 50%;
  display: flex;
  grid-column: 1;
  justify-content: center;
  height: ${sizes.width.customerTypeIcon};
  width: ${sizes.width.customerTypeIcon};
`;

const CardContentContainer = styled.section`
  grid-column: 2;
  border-bottom: 1px solid ${theme.colors.greyMed};
  padding-bottom: ${space.sm};
`;

const TimeFlexContainer = styled(FlexContainer)`
  ${typography.fontSmall()};
  color: ${theme.colors.secondary};
  margin-bottom: ${space.xs};
  justify-content: flex-start;
  display: inline-flex;
  flex-wrap: wrap;
`;

const MainContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${space.sm};
`;

InvoicedBookingCard.propTypes = propTypes;

export default withLanguage(InvoicedBookingCard);
