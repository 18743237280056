export default {
  app: {
    cancel: 'Peruuta',
    close: 'Sulje',
    menu: 'Valikko',
    open: 'Avaa',
    save: 'Tallenna',
    delete: 'Poista',
  },
  snackbar: {
    offline: 'Et ole verkossa! Toiminnallisuus on rajallinen.',
    online:
      'Olet taas verkossa! Sivu päivittyy automaattisesti 3 sekunnin kuluttua...',
    new_version: 'Uusi versio applikaatiosta on nyt saatavilla!',
    action: {
      update_now: 'Päivitä nyt',
      refresh: 'Päivitä sivu',
    },
  },
  areas: {
    title: 'Työskentelualueet',
    next_booking: 'Seuraava tilaus',
    home_address_marker_desc: 'Kotisi',
    toggle_visibility_working_area_caption:
      'Piilota / Näytä työskentelyalueeni',
    toggle_visibility_customer_markers_caption: 'Piilota / Näytä asiakkaani',
    booking_details_link: 'Avaa tilauksen tiedot',
    error_loading_map:
      'Jokin meni vikaan karttaa ladattaessa. Yritä myöhemmin uudelleen tai ota yhteyttä Freskan IT-osastolle osoitteeseen service.team@freska.fi',
  },
  availability: {
    add_period: 'Lisää käytettävyyttä',
    availability_details: 'Käytettävyystiedot',
    availability_this_day: 'Käytettävyys tälle päivälle',
    back_link: 'Takaisin kalenteriin',
    bookings_this_day:
      '{num, plural, =0 {Tilauksia} one {{num} tilaus} other {{num} tilausta}} tälle päivälle',
    calendar: 'Kalenteri',
    continuous: 'Jatkuva',
    create_new_availability: 'Luo uusi viikottainen käytettävyys',
    create_upcoming_availability: 'Luo sääntö käytettävyydelle',
    edit_rule: 'Muokkaa sääntöä',
    edit_weekly_availability: 'Muokkaa viikottaista käytettävyyttä',
    end_on: 'Päättyy',
    ends: 'Päättyy',
    off: 'Pois',
    only_one_offs: 'Vain kertatilauksia',
    start_from: 'Alkaen',
    starting_on: 'Käytettävyys alkaa',
    ending_on: 'Käytettävyys loppuu',
    please_note: 'Huom',
    stable_availability:
      'Vähintään 12 viikon säännöllinen käytettävyys parantaa mahdollisuuksia säännöllisten asiakkaiden saamiseen',
    starts: 'Alkaa',
    title: 'Käytettävyys',
    modal_title: 'Käytettävyys',
    modal_description: '{date}',
    no_bookings_this_day: 'Ei tilauksia tänä päivänä',
    no_availability_this_day: 'Käytettävyyttä ei ole lisätty vielä',
    one_off_modal: {
      title: 'Vain kertatilauksia',
      message:
        'Olet aktivoimassa itsellesi vain kertatilaukset. Huomaathan, että aktivoidessasi tämän et voi saada säännöllisiä asiakkaita, joilla on säännöllinen siivoustilaus. Lisäksi, tämän aktivoiminen ei poista kalenterissasi jo olevia säännöllisiä asiakkaita.',
      confirm: 'Kyllä, haluan vain kertatilauksia',
    },
    delete_modal: {
      title: 'Poista käytettävyys',
      message:
        'Jos poistat tämän käytettävyyden, tilauksesi eivät poistu automaattisesti kalenteristasi. Sinun täytyy vielä ottaa yhteyttä Freskaan Whatsapp-ryhmässäsi',
      confirm: 'Poista kuitenkin',
    },
    errors: {
      intervals_only:
        'Huomaathan, että asettamasi aika on pyöristetty lähimpään 15min jaksoon.',
    },
    remove_availability: 'Poista',
    save_availability: 'Tallenna käytettävyys',
    disabled_explanation:
      'Uusi käytettävyyssääntö voidaan asettaa vain, jos ensimmäisellä käytettävyyssäännöllä on päättymispäivä.',
  },
  absence: {
    absence_types: {
      sick_leave: 'Sairaslomalla',
      vacation: 'Lomalla',
      other_absence: 'Muu poissaolo',
    },
    last_day_of_absence: 'Viimeinen poissaolopäivä: ',
    report_fit_link_caption: 'Ilmoita työkykyiseksi',
    still_sick: 'Olen vielä kipeä',
    modal_close: 'Sulje',
    action_fit_for_work: 'Pystyn työskentelemään jälleen',
    modal_heading: 'Ilmoita työkykyiseksi',
    modal_text_intro_before_threshold:
      'Olet tällä hetkellä sairaslomalla. Ilmoita <b>ennen {time} tänään</b> jos pystyt työskentelemään huomenna.',
    modal_text_intro_after_threshold:
      'Olet tällä hetkellä sairaslomalla.<b>Et ilmoittanut tämän päivän {time} määräaikaan mennessä </b> jatkaaksesi töitä huomenna.',
    modal_text_details_before_threshold:
      'Muutoin huomisen tilauksesi tullaan poistamaan.',
    modal_text_details_after_threshold:
      'Huomisen tilauksesi on nyt poistettu kalenteristasi. Jos ilmoitat itsesi työkykyiseksi nyt, sinun odotetaan palaavan töihin <b>huomisen jälkeen</b> seuraavaan tilaukseesi. Kysymyksiä? Otathan meihin yhteyttä.',
    modal_text_details_still_sick:
      'Vielä kipeä? Pikaista paranemista! Kun voit paremmin, muistathan ilmoittaa itsesi työkykyiseksi ajoissa.',
    error_updating_absence:
      'Hups! Jotain meni vikaan. Emme onnistuneet ilmoittamaan sinua työkykyiseksi huomenna. Otathan yhteyttä Freskan toimistoon ilmoittaaksesi itsesi työkykyiseksi.',
    error_fetching_ongoing_absences:
      'Tapahtui virhe hakiessa nykyisiä poissaolojasi. Yritäthän myöhemmin uudelleen tai kiireellisissä tapauksissa otathan Freskan toimistoon yhteyttä.',
  },
  access: {
    status: {
      no_key: 'Asiakas avaa oven',
      door_code_only: 'Vain ovikoodi',
      stored_by_customer: 'Asiakkaan hallussa',
      key: 'Avain',
    },
    labels: {
      door_code: 'Ovikoodi',
      access_notes: 'Lisätiedot',
    },
  },
  bookings: {
    calendar: 'Kalenteri',
    completed: 'Tehty',
    hide_past_bookings: 'Piilota menneet tilaukset',
    last_week: 'Viime viikko',
    next_week: 'Ensi viikko',
    no_bookings: 'Ei tilauksia tällä viikolla',
    show_past_bookings: 'Näytä menneet tilaukset',
    title: 'Tilaukset',
    this_week: 'Tämä viikko',
    today: 'Tänään',
    total_time:
      '{totalBookings} {totalBookings, plural, one {Tilaus} other {Tilausta}} tällä viikolla ({totalHours} {totalHours, plural, one {tunti} other {tuntia}})',
    unconfirmed_bookings:
      '{num} Vahvistamaton tilaus/vahvistamattomat tilaukset',
    upcoming: 'Tuleva',
    week_num: 'Viikko {num}',
    company_contact_person: 'Yhteyshenkilö: ',
    new_customer: 'Uusi asiakas!',
    extended_duration:
      'Tämä on uuden säännöllisen tilauksen ensimmäinen siivous. Olemme lisänneet {duration} minuuttia ylimääräistä, jotta sinulla on aikaa suorittaa 5 tähden siivous. Voit käyttää ylimääräisen ajan varmistaaksesi tärkeimmät alueet ja kirjoittaaksesi tervehdyksen asiakkaalle Tervetuloa kotiin -korttiin.💙',
    card: {
      access: 'Sisäänpääsytiedot',
      additional_services: 'Lisäpalvelut',
      address_notes: 'Osoitetiedot',
      cleaning_supplies: 'Siivousvälineet',
      customer_notes: 'Asiakastiedot',
      window_cleaning_notes: 'Ikkunanpesutiedot',
      directions: 'Suuntaohjeet',
      rating: 'Palaute',
      average_rating: 'Keskiarvo',
      no_rating_provided: 'Ei annettua palautetta',
      service_type: {
        1: 'Kotisiivous',
        2: 'Toimistosiivous',
        3: 'Ikkunanpesu',
        4: 'Muuttosiivous',
        6: 'Sairaalasiivous',
        7: 'Syväpuhdistus',
        8: 'Katsastussiivous',
        9: 'Toimistotapaaminen',
        10: 'Avaimen nouto/palauttaminen',
        11: 'Tauko',
        12: 'Matkustus',
        13: 'Myöhäinen peruutus',
        14: 'Koulutus',
        15: 'Loma',
        16: 'Vanhempainvapaa',
        17: 'Palkaton vapaa',
        18: 'Sairasloma',
        19: 'Lapsen sairasloma',
        20: 'Flex-kotisiivous',
        21: 'Lomapalvelu',
        22: 'Porrassiivous',
        23: 'Mukautettu siivous',
        36: 'Muuttosiivous',
        38: 'Sairaalasiivous',
      },
      frequency: {
        0: 'Kertatilaus',
        1: 'Viikottainen',
        2: 'Vuoroviikottainen',
        4: 'Joka neljäs viikko',
        substitute: 'Tuuraus',
      },
      pets: {
        cat: 'Kissa',
        dog: 'Koira',
        other: 'Muu',
        pets: 'Lemmikit',
      },
    },
    details: {
      back_link: 'Takaisin yleisnäkymään',
      booking_details: 'Tilaustiedot',
      cleaning_supplies: {
        by_freska: 'Freskan siivousvälineet',
        by_customer: 'Asiakkaan siivousvälineet',
      },
      sw_comment_to_freska: 'Kommenttisi Freskalle',
      service_type: 'Palvelutyyppi',
      service_frequency: 'Frekvenssi',
      window_cleaning: {
        window_groups: 'Ikkunaryhmät',
        single_windows: 'Yksittäiset ikkunat',
        balcony: 'Parveke',
        balcony_value: 'Kyllä, {num} paneelia',
        no_balcony: 'Ei',
        number_of_layers: 'Puolet/ikkuna',
        ventilation_windows: 'Tuuletusikkunat',
        included: 'Kyllä',
        not_included: 'Ei',
        blinds: 'Sälekaihtimet',
      },
      feedback_history: {
        no_history:
          'Ei sinulle annettua asiakaspalautetta tälle säännölliselle tilaukselle.',
        title: 'Palautehistoria',
      },
      tab: {
        details: 'Tiedot',
        instructions: 'Ohjeet',
        feedback: 'Palaute',
      },
      instructions: {
        title: 'Ohjeet',
        customer_instructions_title: 'Asiakkaan siivousohjeet',
        worker_notes_title: 'Sisäiset muistiinpanot',
        worker_notes_description:
          'Sisäiset muistiinpanot voivat sisältää mitä tahansa tietoa, joka auttaa toista kollegaa suorittamaan korkealaatuisen palvelun tämän asiakkaan luona, mikäli tulet kipeäksi tai olet lomalla. Kuka tahansa tämän asiakkaan luokse menevä näkee nämä muistiinpanot.',
        worker_notes_info_tag: 'Info',
        no_cleaning_instructions: 'Asiakas ei ole jättänyt siivousohjeita.',
        error_deleting_worker_note:
          'Tämän muistiinpanon poistaminen ei onnistunut. Päivitäthän sivun ja yrität uudellen. Jos ongelma jatkuu, otathan yhteyttä Freskan IT-osastoon.',
        error_fetching_worker_notes:
          'Muistiinpanojen noutaminen ei onnistunut. Päivitäthän sivun ja yritä uudelleen. Jos ongelma jatkuu, otathan yhteyttä Freskan IT-osastoon.',
        error_saving_worker_note:
          'Muutosten tallentaminen muistiinpanoihin ei onnistunut. Päivitäthän sivun ja yrität uudellen. Jos ongelma jatkuu, otathan yhteyttä Freskan IT-osastoon.',
        save_changes: 'Tallenna muutokset',
        add_note: 'Lisää muistiinpano',
        cancel_add_note: 'Hylkää',
        cancel_edit_note: 'Hylkää',
        last_updated: 'Viimeksi päivitetty',
        delete_confirmation_modal: {
          title: 'Poista muistiinpano',
          message:
            'Tämä toiminta poistaa tämän muistiinpanon kokonaan näkyvistä lopullisesti. Tätä toimintaa ei voi perua.',
          button_caption: 'Poista muistiinpano',
        },
        no_worker_notes:
          'Ei vielä lisättyjä sisäisiä muistiinpanoja tälle asiakkaalle tai palvelulle.',
        error_saving_worker_notes:
          'Virhe tallentaessa sisäistä muistiinpanoa. Yritäthän myöhemmin uudelleen.',
      },
    },
    status: {
      approved: 'Hyväksytty',
      booked: 'Tilattu',
      completed: 'Tehty',
      invoiced: 'Laskutettu',
      paid: 'Maksettu',
      prebooked: 'Alustava tilaus',
      unconfirmed: 'Vahvistamaton',
      uninvoiced: 'Laskuttamaton',
      outside_availability_period: 'Käytettävyyden ulkopuolella',
      canceled: 'Peruttu',
    },
    summary: 'Yhteenveto',
    unconfirmed: {
      comments_to_freska: 'Kommentit Freskalle (valinnainen)',
      confirm_as_completed: 'Olen tehnyt tämän tilauksen',
      ended: 'Päättynyt',
      show_booking_details: 'Näytä tilauksen tiedot',
      started: 'Alkanut',
      tips_and_tricks: 'Vinkit ja neuvot siivoojille',
      comments_to_freska_placeholder:
        'Kirjoita tähän vain, jos suoritettu aika on eronnut tilatusta ajasta tai jos Freskan olisi hyvä tietää jotain. Tämä teksti on valinnainen ja ei näy asiakkaalle!',
    },
    actions: {
      show_list_view: 'Näytä listanäkymä',
      show_calendar_view: 'Näytä kalenterinäkymä',
    },
  },
  calendar: {
    modal: {
      title: 'Käytettävyystiedot',
    },
  },
  keys: {
    title: 'Avaimet',
    back_link: 'Takaisin yleisnäkymään',
    key_details: 'Avaintiedot',
    notes: 'Muistiinpanot',
    next_booking: 'Seuraava tilaus',
    no_keys: 'Ei avaimia',
    pick_up_before_date: 'Hae ennen {date}',
    return_latest_by_date: 'Palauta viimeistään: {date}',
    section_headers: {
      return: 'Toimistolle palautettavia avaimia',
      pick_up: 'Toimistolta haettavia avaimia',
      not_urgent: 'Hallussasi olevat avaimet',
    },
    status: {
      at_office: 'Freskan toimisto',
      at_you: 'Sinä',
      at_another_cleaner: 'Kollega',
      at_cleaner: 'Sinä',
      at_freska_helsinki: 'Freska Helsinki toimisto',
      at_freska_stockholm: 'Freska Stockholm toimisto',
      at_freska_oslo: 'Freska Oslo toimisto',
      at_freska_bergen: 'Freska Bergen toimisto',
      at_herohub_billingstad: 'HeroHub Billingstad',
      at_herohub_drammen: 'HeroHub Drammen',
      at_herohub_majorstuen: 'HeroHub Majorstuen',
      at_herohub_langhus: 'HeroHub Langhus',
      at_herohub_lorenskog: 'HeroHub Lørenskog',
    },
    tags: {
      return: 'Palauta avain toimistolle',
      pick_up: 'Haettava',
    },
    labels: {
      return_date: 'Viimeinen palautuspäivä',
      label: 'Avainnumero',
      safekeeper: 'Hallussapitäjä',
      pick_up_location: 'Hakupaikka',
      key_notes: 'Avaintiedot',
    },
  },
  dates: {
    mon: 'Ma',
    monday: 'Maanantai',
    tue: 'Ti',
    tuesday: 'Tiistai',
    wed: 'Ke',
    wednesday: 'Keskiviikko',
    thu: 'To',
    thursday: 'Torstai',
    fri: 'Pe',
    friday: 'Perjantai',
    sat: 'La',
    saturday: 'Lauantai',
    sun: 'Su',
    sunday: 'Sunnuntai',
    jan: 'Tam',
    january: 'Tammikuu',
    feb: 'Hel',
    february: 'Helmikuu',
    mar: 'Maa',
    march: 'Maaliskuu',
    apr: 'Huh',
    april: 'Huhtikuu',
    may: 'Tou',
    may_full: 'Toukokuu',
    jun: 'Kes',
    june: 'Kesäkuu',
    jul: 'Hei',
    july: 'Heinäkuu',
    aug: 'Elo',
    august: 'Elokuu',
    sep: 'Syy',
    september: 'Syyskuu',
    oct: 'Lok',
    october: 'Lokakuu',
    nov: 'Mar',
    november: 'Marraskuu',
    dec: 'Jou',
    december: 'Joulukuu',
  },
  pluralDates: {
    mon: 'Maanantait',
    tue: 'Tiistait',
    wed: 'Keskiviikot',
    thu: 'Torstait',
    fri: 'Perjantait',
    sat: 'Lauantait',
    sun: 'Sunnuntait',
  },
  shortDates: {
    mon: 'Ma',
    tue: 'Ti',
    wed: 'Ke',
    thu: 'To',
    fri: 'Pe',
    sat: 'La',
    sun: 'Su',
  },
  time: {
    duration_hours: {
      short: '{hours}h',
      long: '{hours} tunti(a)',
    },
    duration_minutes: {
      short: '{minutes}m',
      long: '{minutes} minuutti(a)',
    },
  },
  error: {
    contact_support: 'Otathan yhteyttä Freskan toimistoon, jos ongelma jatkuu.',
    default: 'Tapahtui virhe. Yritäthän uudelleen.',
    incorrect_credentials: 'Sähköpostisi ja/tai salasanasi on väärin.',
    something_went_wrong:
      'Näyttää siltä, että jokin meni vikaan. Ei hätää, siivoamme asian.',
    start_over: 'Aloita uudelleen',
    whoops: 'Hups!',
    wrong_input: 'Salasanat eivät täsmää',
    offline_header: 'Ei käytettävissä ilman verkkoa.',
    offline_description:
      'Kun et ole verkossa, Superheroes-applikaatio toimii rajallisesti perustuen sivuhistoriaasi. Kun olet taas liittynyt verkkoon, palautuu täysi toiminnallisuus.',
  },
  login: {
    email: 'Sähköposti',
    password: 'Salasana',
    login: 'Kirjaudu',
    return_to_login: 'Palaa kirjautumiseen',
    forgot_password: 'Unohtuiko salasana?',
    reset_password: 'Lähetä salasanan päivitysohjeet',
    reset_password_email_sent:
      'Ohjeet on lähetetty sähköpostitse tähän osoitteeseen.',
    translate_msg: 'Suomeksi',
    reset_password_success: 'Salasanasi on päivitetty',
  },
  nav: {
    availability: 'Käytettävyys',
    bookings: 'Tilaukset',
    keys: 'Avaimet',
    logout: 'Kirjaudu ulos',
    support: 'Tuki',
    profile: 'Profiili',
    rating: 'Palaute',
    working_area: 'Työskentelyalueet',
    you_are_here: 'Olet tässä',
    invoices: 'Laskutustiedot',
    news: 'Uutiset',
    dashboard: 'Etusivu',
    hse_form: 'Työsuojeluraportointi',
  },
  dashboard_news: {
    title: 'Valikko',
  },
  dashboard: {
    title: 'Etusivu',
    latest_news: 'Tuoreimmat uutiset',
    all_news: 'Kaikki uutiset',
    next_booking: 'Nyt/Seuraavaksi',
    view_schedule: 'Katso aikataulua',
    published: 'Julkaistu {date} klo {time}',
    news_fetch_failed: 'Pahoittelut, jotain tapahtui hakiessa uutisia.',
    action_required: 'Toimintaasi kaivataan',
    booking_status: 'Vahvistamaton tilaus',
    up_to_date: 'Olet tilanteen tasalla! Hienoa! 💪',
    customer_instructions_available: 'Asiakasohjeet saatavilla!',
    no_upcoming_cleanings:
      'Ota rennosti! Ei tilauksia seuraavaan kolmeen päivään.',
    fetching_next_failed:
      'Jotain tapahtui hakiessa seuraavaa tilausta. Katso aikataulua nähdäksesi seuraavat siivouksesi.',
    view: 'Katso',
    key_status: {
      at_cleaner: 'Palauta avain toimistolle',
      at_office: 'Hae avain toimistolta',
      at_freska_helsinki: 'Palauta avain Freska Helsinki toimistolta',
      at_freska_stockholm: 'Palauta avain Freska Stockholm toimistolta',
      at_freska_oslo: 'Palauta avain Freska Oslo toimistolta',
      at_freska_bergen: 'Palauta avain Freska Bergen toimistolta',
      at_herohub_billingstad: 'Palauta avain HeroHub Billingstadista',
      at_herohub_drammen: 'Palauta avain HeroHub Drammenilta',
      at_herohub_majorstuen: 'Palauta avain HeroHub Majorstuenilta',
      at_herohub_langhus: 'Palauta avain HeroHub Langhusista',
      at_herohub_lorenskog: 'Palauta avain HeroHub Lørenskogista',
    },
  },
  not_found: {
    return: 'Palaa',
    title: 'Sivua ei löytynyt',
    whoops: 'Huups... sivua ei löytynyt.',
  },
  support: {
    title: 'Tuki',
    back_link: 'Takaisin tukeen',
  },
  news: {
    title: 'Uutiset',
    back_link: 'Takaisin yleisnäkymään',
    publishedAt: 'Julkaistu {date} klo {time}',
  },
  profile: {
    title: 'Profiili',
    back_link: 'Takaisin profiiliin',
    authenticated_user: 'Sisäänkirjautunut käyttäjä',
    password: {
      change_password: 'Vaihda salasana',
      confirm_new_password: 'Vahvista uusi salasana',
      current_password: 'Nykyinen salasana',
      new_password: 'Uusi salasana',
      update: 'Päivitä salasana',
    },
    language: {
      options: {
        english: 'Englanti',
        finnish: 'Suomi',
      },
      set_language: 'Aseta kieli',
    },
    success: 'Salasanasi on vaihdettu',
  },
  ratings: {
    average_rating: 'Keskiarvo viimeiseltä {num} kuukaudelta',
    behavior: 'Ystävällisyys',
    comments: 'Asiakaspalaute',
    latest_ratings: 'Viimeisimmät palautteet',
    on_time: 'Täsmällisyys',
    overall: 'Yleisilme',
    title: 'Palaute',
    total_cleanings: 'Siivoukset yhteensä',
    total_hours: 'Tunnit yhteensä',
    quality: 'Laatu',
    no_customer_feedback_provided:
      'Asiakas ei jättänyt palautetta tästä tilauksesta.',
  },
  services: {
    fridge: 'Jääkaappi',
    ironing: 'Vaatteiden silitys',
    oven_cleaning: 'Uuninpesu',
    sauna_cleaning: 'Saunanpesu',
    trash: 'Roskat',
  },
  invoices: {
    title: 'Laskutustiedot',
    back_link: 'Takaisin laskutustietoihin',
    invoice_details: 'Laskutustiedot',
    invoice_period: 'Laskutusjakso',
    start_date: 'Aloituspäivämäärä',
    end_date: 'Lopetuspäivämäärä',
    date_range: 'Jakso',
    create_invoice_period_continue: 'Luo laskutustiedot...',
    create_invoice_periods_continue: 'Luo laskutustiedot...',
    create_invoice_period: 'Luo laskutustiedot',
    create_invoice_periods: 'Luo laskutustiedot',
    all_invoiceable_bookings: 'Kaikki laskuttamattomat tilaukset',
    specific_period: 'Määrätty jakso',
    invoice_period_disclaimer:
      'Tarkista ja vahvista alla oleva tieto ennen laskutustietojen luomista.',
    invoice_period_note: 'Tärkeää',
    grand_total: {
      pending_invoices: 'Odottamassa yhteensä:',
      invoiced_last_18months: 'Laskutettu yhteensä:',
    },
    awaiting_invoice_header: 'Tämä ei ole lasku.',
    awaiting_invoice_desc:
      'Sinun täytyy vielä lähettää laskusi Freskalle oman laskutuspalvelusi tai yhtiösi kautta.',
    extra_hours: 'Lisättyjä tunteja',
    agreement:
      'Olen tarkastanut huolellisesti kaikki sisältyvät tilaukset ja vahvistan ne oikeiksi.',
    section_headers: {
      pending_invoices: 'Odotamme laskuasi',
      invoiced_last_18months: 'Laskutettu',
      uninvoiced_bookings: 'Laskuttamattomat tilaukset',
      invoiceable_bookings: 'Laskutettavat tilaukset',
    },
    status: {
      invoiced: 'Laskutettu',
      paid: 'Maksettu',
      uninvoiced: 'Odotamme laskuasi',
      processed: 'Käsitelty',
    },
    errors: {
      no_invoiceable_bookings: 'Ei tilauksia valitulla jaksolla.',
      no_price_level:
        'Laskutusjakson luominen epäonnistui. Otathan yhteyttä osoitteeseen hr@freska.fi',
      invoice_create_fail: 'Jotain meni vikaan laskutusjaksoasi luodessa.',
    },
    ukko: {
      quantity: 'Määrä',
      unit_price: 'Tuntihinta',
      payment_terms: 'Maksuaika',
      total_amount: 'Yhteensä',
      invoice_number: 'Laskunumero',
      paid_on: 'Eräpäivä',
      total_amount_with_vat: 'Yhteensä ilman ALV',
      vat_amount: 'ALV',
    },
    hours: '{hours} tunnit',
    hours_short: '{hours}h',
    included_bookings: 'Sisältyvät tilaukset',
    totals_per_service_type: 'Yhteensä per palvelutyyppi',
    no_invoices: 'Ei laskutustietoja saatavilla',
    no_pending_invoices: 'Ei odottavia laskuja saatavilla.',
    no_invoiced_last_18months: 'Ei laskutettuja laskuja saatavilla.',
  },
  face_mask: {
    title: 'Käytä aina kasvomaskia',
    description:
      'Koronaviruksen leviämisen ehkäisemiseksi kasvomaskin käyttäminen on pakollista.',
  },
  article_was_not_found:
    'Valitettavasti artikkelia ei löytynyt. Mene takaisin ja yritä uudelleen.',
  multiple_invoices_will_be_created: `Freskan Y-tunnus on vaihtunut 01.01.2022 alkaen. Huomaathan, että tästä syystä tilaukset jaetaan kahdelle laskutusjaksolle.`,
  invoice_periods_created: 'Laskutusjakso(t) on nyt luotu',
  multiple_invoices_created:
    'Huomaathan, että sinun täytyy vielä lähettää laskusi Freskalle oman laskutuspalvelusi tai yhtiösi kautta.',
};
