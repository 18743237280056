import React from 'react';
import styled from 'styled-components';
import { oneOf } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { IconStarFull, typography, Text, theme } from '@freska/freska-ui';
import { v4 as uuidv4 } from 'uuid';
import { Rating } from '@material-ui/lab';

import { space } from '../../theme/theme';
import FlexContainer from '../Common/FlexContainer';
import { LANGUAGES_TYPE, RATING_QUALITY } from '../../constants';
import withLanguage from '../../utils/withLanguage';
import { formatRating } from '../../utils/formatNumber';
import DataBlock from '../Common/DataBlock';
import { ratingType } from '../../types';

const propTypes = {
  language: oneOf(LANGUAGES_TYPE).isRequired,
  rating: ratingType.isRequired,
};

function showRatingValue(labelId, classString, rating, locale) {
  return (
    <div>
      <Text size="small" bold as="p" mb={space.xs}>
        <FormattedMessage id={labelId} />
      </Text>
      <Text as="span" size="small" className={classString}>
        {formatRating(rating, locale)}
      </Text>
    </div>
  );
}

const BookingDetailsRating = ({ rating, language: locale }) => (
  <DataBlock
    Icon={IconStarFull}
    title="bookings.card.rating"
    hasContent={!!rating}
    noContentValue={[
      <Text as="p" m={0} color="secondary" key={uuidv4()}>
        <FormattedMessage id="bookings.card.no_rating_provided" />
      </Text>,
    ]}
  >
    <Text m={0} as="p">
      <FormattedMessage id="bookings.card.average_rating" />
    </Text>

    <RatingFlexContainer alignV="center" alignH="flex-start">
      <Rating
        value={rating.average}
        precision={0.1}
        size="small"
        readOnly
        style={{
          fontSize: '24px',
          color:
            theme.colors[
              rating.average >= RATING_QUALITY.THRESHOLD
                ? 'rating'
                : 'attention'
            ],
        }}
      />
      <Text
        as="p"
        ml={1}
        my={0}
        mr={0}
        pt="2px"
        size="small"
        className="ratingAverage"
      >
        {formatRating(rating.average, locale)}
      </Text>
    </RatingFlexContainer>
    <RatingGrid>
      {showRatingValue(
        'ratings.overall',
        'ratingOverall',
        rating.overall,
        locale
      )}
      {showRatingValue(
        'ratings.behavior',
        'ratingBehavior',
        rating.behavior,
        locale
      )}
      {showRatingValue(
        'ratings.on_time',
        'ratingPunctionality',
        rating.punctuality,
        locale
      )}
      {showRatingValue(
        'ratings.quality',
        'ratingQuality',
        rating.workmanship,
        locale
      )}
    </RatingGrid>
  </DataBlock>
);

const RatingGrid = styled.section`
  display: grid;
  ${typography.fontSmall()};
  grid-template-columns: repeat(
    auto-fill,
    minmax(min-content, calc(50% - ${space.md}))
  );
  grid-gap: ${space.md};

  span:nth-child(odd) {
    display: block;
  }
`;

const RatingFlexContainer = styled(FlexContainer)`
  margin: ${space.sm} 0 ${space.md} 0;
`;

BookingDetailsRating.propTypes = propTypes;

export default withLanguage(BookingDetailsRating);
