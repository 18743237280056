import React from 'react';
import { shape, oneOf } from 'prop-types';
import styled from 'styled-components';
// import { useQuery } from 'react-apollo';
import { Box, Text, Label, theme, Heading } from '@freska/freska-ui';
import { FormattedMessage } from 'react-intl';
import { Rating } from '@material-ui/lab';

import { space, sizes } from '../../theme/theme';
import ViewGrid from '../../components/Common/ViewGrid';
import { formatNumber, formatRating } from '../../utils/formatNumber';
import FlexContainer from '../../components/Common/FlexContainer';
import { LANGUAGES_TYPE, RATING_QUALITY } from '../../constants';
// import { GET_RATED_BOOKINGS } from '../../gql/queries';
import withLanguage from '../../utils/withLanguage';
import withUser from '../../utils/withUser';
// import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
// import BookingListWithInfiniteLoading from '../../components/Ratings/BookingListWithInfiniteLoading';

const propTypes = {
  language: oneOf(LANGUAGES_TYPE).isRequired,
  user: shape({}).isRequired,
};

function renderRatingType(type, rating, locale) {
  return (
    <FlexContainer column>
      <Label mb={1}>
        <FormattedMessage id={`ratings.${type}`} />
      </Label>
      <FlexContainer>
        <Rating
          value={rating}
          precision={0.1}
          size="small"
          readOnly
          style={{
            fontSize: '16px',
            color:
              theme.colors[
              rating >= RATING_QUALITY.THRESHOLD ? 'rating' : 'attention'
              ],
          }}
        />
        <Text as="span" bold ml={1}>
          {formatRating(rating, locale)}
        </Text>
      </FlexContainer>
    </FlexContainer>
  );
}

// AGREED TO HIDE RATED BOOKINGS FOR NOW
const RatingsContainer = ({ language, user }) => {
  const locale = language;
  const {
    average_rating_last_three_months: averageRating,
    past_bookings_count: pastBookingsCount,
    past_bookings_hours: pastBookingsHours,
  } = user;
  let { average_rating_details: ratingDetails } = user;
  ratingDetails = ratingDetails || {
    overall: 0,
    behavior: 0,
    workmanship: 0,
    punctuality: 0,
  };

  // const { loading, error } = useQuery(GET_RATED_BOOKINGS);

  // if (loading) {
  //   return (
  //     <Box p={3}>
  //       <Spinner />
  //     </Box>
  //   );
  // }

  // if (error) {
  //   return <ErrorHandler error={error} />;
  // }

  // const bookings = data && data.getRatedBookings;

  return (
    <ViewGrid>
      <StyledBox pt={2}>
        <StyledFlexContainer center column>
          <Label mb={1} align="center">
            <FormattedMessage id="ratings.average_rating" values={{ num: 3 }} />
          </Label>
          <StyledHeading align="center" level={1}>
            {formatRating(averageRating, locale)}
          </StyledHeading>
          <Rating
            value={averageRating}
            precision={0.1}
            size="small"
            readOnly
            style={{
              fontSize: '46px',
              color:
                theme.colors[
                averageRating >= RATING_QUALITY.THRESHOLD
                  ? 'rating'
                  : 'attention'
                ],
            }}
          />
        </StyledFlexContainer>
        <GridContainer>
          {renderRatingType('overall', ratingDetails.overall, locale)}
          {renderRatingType('quality', ratingDetails.workmanship, locale)}
          {renderRatingType('on_time', ratingDetails.punctuality, locale)}
          {renderRatingType('behavior', ratingDetails.behavior, locale)}
        </GridContainer>
        <GridContainer>
          <FlexContainer column>
            <Label mb={1}>
              <FormattedMessage id="ratings.total_cleanings" />
            </Label>
            <Text as="span" bold>
              {Number(pastBookingsCount)}
            </Text>
          </FlexContainer>
          <FlexContainer column>
            <Label mb={1}>
              <FormattedMessage id="ratings.total_hours" />
            </Label>
            <Text as="span" bold>
              {formatNumber(pastBookingsHours, locale)}
            </Text>
          </FlexContainer>
        </GridContainer>
      </StyledBox>
      {/* AGREED TO HIDE RATED BOOKINGS FOR NOW
        {bookings && !!bookings.length && (
          <BookingListWithInfiniteLoading bookings={bookings} />
        )} */}
    </ViewGrid>
  );
};

const StyledFlexContainer = styled(FlexContainer)`
  padding: ${space.md} 0 ${space.lg} 0;
`;

const StyledBox = styled(Box)`
  margin: 0 auto;
  max-width: ${sizes.width.bookingCardMaxWidth};
`;

const StyledHeading = styled(Heading)`
  margin: 0;
  line-height: 61px;
`;

const GridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(min-content, calc(50% - ${space.default}))
  );
  column-gap: ${space.md};
  row-gap: ${space.default};
  justify-content: center;
  padding: ${space.default} 0;
  margin: 0;

  &:last-child {
    border-top: 1px solid ${theme.colors.greyMed};
  }

  @media screen and (min-width: 850px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(min-content, calc(25% - ${space.default}))
    );
    min-width: ${sizes.width.bookingCardMaxWidth};
  }
`;

RatingsContainer.propTypes = propTypes;

export default withLanguage(withUser(RatingsContainer));
