import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { IconFeedback, Text } from '@freska/freska-ui';
import { v4 as uuidv4 } from 'uuid';

import DataBlock from '../Common/DataBlock';

const propTypes = {
  comment: PropTypes.string,
};

const defaultProps = {
  comment: '',
};

function BookingDetailsCustomerFeedback({ comment }) {
  return (
    <DataBlock
      Icon={IconFeedback}
      title="ratings.comments"
      hasContent={!!comment}
      noContentValue={[
        <Text as="p" m={0} color="secondary" key={uuidv4()}>
          <FormattedMessage id="ratings.no_customer_feedback_provided" />
        </Text>,
      ]}
    >
      <Text m={0} as="p" className="customerFeedback">
        {comment}
      </Text>
    </DataBlock>
  );
}

BookingDetailsCustomerFeedback.propTypes = propTypes;
BookingDetailsCustomerFeedback.defaultProps = defaultProps;

export default BookingDetailsCustomerFeedback;
