import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Box,
  IconAssignment,
  Spinner,
  Text,
  IconRecurring,
  IconOneOff,
} from '@freska/freska-ui';

import { GET_BOOKING } from '../../gql/queries';
import { space } from '../../theme/theme';
import { NETWORK_STATUS_ENUM } from '../../constants';
import { useServiceWorker } from '../../useServiceWorker';

import FullscreenContentContainer from '../Common/FullscreenContentContainer';

import ErrorHandler from '../ErrorHandler/ErrorHandler';
import BookingDetailsWorkerNotes from './BookingDetailsWorkerNotes';
import BookingDetailsPastFeedback from './BookingDetailsTabPastFeedback';
import BookingDetailsTabDetails from './BookingDetailsTabDetails';

import { useSearchParams } from '../../hooks/useSearchParams';

import { ContactDetails } from './ContactDetails';
import DataBlock from '../Common/DataBlock';

function getFrequencyIcon(serviceId, isSubstituteCleaner) {
  if (serviceId === '0' || isSubstituteCleaner) return IconOneOff;
  return IconRecurring;
}

function BookingDetails() {
  const { tab } = useParams();
  const { id } = useSearchParams();
  const onlineStatus = useServiceWorker();

  const { data, networkStatus, error } = useQuery(GET_BOOKING, {
    variables: {
      bookingId: Number(id),
    },
    pollInterval: onlineStatus ? 60000 : 0,
  });
  const booking = data?.getBooking;
  const loading = networkStatus === NETWORK_STATUS_ENUM.LOADING;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tab]);

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <Fragment>
      <FullscreenContentContainer>
        {loading && (
          <Box p={4}>
            <Spinner />
          </Box>
        )}
        {booking && (
          <Fragment>
            <Wrapper>
              <ContactDetails booking={booking} />

              <DataBlock
                Icon={getFrequencyIcon(
                  booking.frequency,
                  booking.isSubstituteCleaner
                )}
                hasContent={!!booking.start_time && !!booking.end_time}
              >
                <Text m={0} as="p">
                  <FormattedMessage
                    id={`bookings.card.frequency.${
                      booking.isSubstituteCleaner
                        ? 'substitute'
                        : booking.frequency
                    }`}
                  />
                </Text>
              </DataBlock>
              <DataBlock
                Icon={IconAssignment}
                title="bookings.details.instructions.customer_instructions_title"
              >
                {booking.detailSummary.customerNotes ? (
                  <Text as="p">{booking.detailSummary.customerNotes}</Text>
                ) : (
                  <Text as="p" color="secondary">
                    <FormattedMessage id="bookings.details.instructions.no_cleaning_instructions" />
                  </Text>
                )}
              </DataBlock>
              <BookingDetailsTabDetails booking={booking} />
              <BookingDetailsWorkerNotes
                bookingId={booking.id}
                serviceId={booking.service.id}
              />
              {!!booking.recurring_booking_id && (
                <BookingDetailsPastFeedback
                  recurringBookingId={booking.recurring_booking_id}
                />
              )}
            </Wrapper>
          </Fragment>
        )}
      </FullscreenContentContainer>
    </Fragment>
  );
}

const Wrapper = styled.section`
  padding: 0 ${space.default} ${space.default};
  height: 100%;
  z-index: 1;
`;

export default BookingDetails;
