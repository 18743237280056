import React from 'react';
import PropTypes, { string } from 'prop-types';
import { IntlProvider } from 'react-intl';

import languageContent from './index';
import withLanguage from '../utils/withLanguage';
import LocalStorage from '../utils/localStorage';

export const LANGUAGE = Object.freeze(['fi', 'en', 'sv']);

const propTypes = {
  children: PropTypes.node,
  language: string,
};

const defaultProps = {
  children: {},
};

const LanguageProvider = ({ children }) => {
  const lng = LocalStorage.get('lng') || 'en';
  const locale = LANGUAGE.find(lang => lng.startsWith(lang)) || 'en';
  return (
    <IntlProvider locale={locale} key={locale} messages={languageContent[locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = propTypes;
LanguageProvider.defaultProps = defaultProps;

export default withLanguage(LanguageProvider);
