import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { instanceOf } from 'prop-types';
import { useQuery } from 'react-apollo';
import {
  theme,
  Text,
  Button,
  IconChevronLeft,
  IconChevronRight,
  Box,
  Spinner,
} from '@freska/freska-ui';
import {
  eachDayOfInterval,
  addMinutes,
  addHours,
  startOfDay,
  addDays,
  differenceInMinutes,
  format,
  parseISO,
  isSameDay,
  subDays,
  getMinutes,
  getHours,
  subMinutes,
  isBefore,
  isAfter,
  isEqual,
  isWithinInterval,
} from 'date-fns';
import { useHistory } from 'react-router';

import {
  GET_BOOKINGS_VIEW_DATA,
  GET_WORKING_SCHEDULES,
  GET_ABSENCES,
} from '../../gql/queries';
import { BOOKING_CALENDAR_ENUM } from '../../constants';
import FlexContainer from '../Common/FlexContainer';
import DayColumn from './DayColumn';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import { trackEvent } from '../../utils/tracking';
import useNumberOfDaysToRender from './useNumberOfDaysToRender';
import LocalStorage from '../../utils/localStorage';
import { getStringTime } from '../../utils/time';
import { sizes } from '../../theme/theme';
import { useServiceWorker } from '../../useServiceWorker';
import { NETWORK_STATUS_ENUM } from '../../constants';

const propTypes = {
  weekStartDate: instanceOf(Date).isRequired,
};

const getTimeObject = time => ({
  hours: getHours(time),
  minutes: getMinutes(time),
});

function buildGridRows(date, gridStartTime) {
  const datetime = subMinutes(addHours(date, gridStartTime.hours), 15);
  const amountOfIntervals =
    differenceInMinutes(startOfDay(addDays(datetime, 1)), datetime) /
    BOOKING_CALENDAR_ENUM.GRID_INTERVAL;
  const timesArray = [];

  for (let i = 0; i <= amountOfIntervals; i++) {
    const formattedTime = format(
      addMinutes(datetime, BOOKING_CALENDAR_ENUM.GRID_INTERVAL * i),
      'HHmm'
    );
    timesArray.push(`[time-${formattedTime}] 6px `);
  }
  return timesArray.join('');
}

function buildGridLines(date, gridStartTime) {
  const datetime = addHours(date, gridStartTime.hours);
  const amountOfIntervalsIn30Minutes = 30 / BOOKING_CALENDAR_ENUM.GRID_INTERVAL;
  const amountOfIntervals =
    differenceInMinutes(startOfDay(addDays(datetime, 1)), datetime) /
      BOOKING_CALENDAR_ENUM.GRID_INTERVAL +
    amountOfIntervalsIn30Minutes;
  const lineBlocks = [];

  for (let i = 1; i <= amountOfIntervals; ++i) {
    if (i % amountOfIntervalsIn30Minutes === 0)
      lineBlocks.push({
        gridStart: format(
          addMinutes(
            datetime,
            BOOKING_CALENDAR_ENUM.GRID_INTERVAL *
              (i - amountOfIntervalsIn30Minutes)
          ),
          'HHmm'
        ),
        gridEnd: format(
          addMinutes(datetime, BOOKING_CALENDAR_ENUM.GRID_INTERVAL * i),
          'HHmm'
        ),
        displayTime: format(
          addMinutes(
            datetime,
            BOOKING_CALENDAR_ENUM.GRID_INTERVAL *
              (i - amountOfIntervalsIn30Minutes)
          ),
          'HH:mm'
        ),
      });
  }
  return lineBlocks;
}

function useDateRange(startDate, numberOfDaysToRender, history) {
  const [dateRange, setDateRange] = useState({
    startDate,
    endDate: addDays(startDate, numberOfDaysToRender),
  });

  useEffect(() => {
    setDateRange(currentState => ({
      startDate: startOfDay(currentState.startDate),
      endDate: addDays(
        startOfDay(currentState.startDate),
        numberOfDaysToRender
      ),
    }));
  }, [numberOfDaysToRender]);

  function nextDateRange() {
    trackEvent('Bookings calendar: Navigated to next date range', {
      category: 'Bookings',
    });
    const newStartDate = addDays(dateRange.endDate, 1);
    setDateRange({
      startDate: newStartDate,
      endDate: addDays(addDays(dateRange.endDate, 1), numberOfDaysToRender),
    });

    history.push(`${window.location.pathname}?date=${format(newStartDate, 'yyyy-MM-dd')}`);
  }

  function previousDateRange() {
    trackEvent('Bookings calendar: Navigated to previous date range', {
      category: 'Bookings',
    });
    const newStartDate = subDays(subDays(dateRange.startDate, 1), numberOfDaysToRender);
    setDateRange({
      startDate: newStartDate,
      endDate: subDays(dateRange.startDate, 1),
    });

    history.push(`${window.location.pathname}?date=${format(newStartDate, 'yyyy-MM-dd')}`);
  }

  return { nextDateRange, previousDateRange, dateRange };
}

function getDataForSingleDay(data, day) {
  return data.find(item => isSameDay(parseISO(item.date), day));
}

function filterAbsences(absences, day) {
  return absences.filter(absence => {
    if (absence.end_time) {
      return (
        isWithinInterval(day, {
          start: parseISO(absence.start_time),
          end: parseISO(absence.end_time),
        }) || isSameDay(day, parseISO(absence.start_time))
      );
    }
    return (
      isEqual(day, parseISO(absence.start_time)) ||
      isAfter(day, parseISO(absence.start_time))
    );
  });
}

function BookingCalendarView({ weekStartDate }) {
  const { onlineStatus } = useServiceWorker();
  const history = useHistory();
  const numberOfDaysToRender = useNumberOfDaysToRender();
  const {
    nextDateRange,
    previousDateRange,
    dateRange: { startDate, endDate },
  } = useDateRange(
    weekStartDate,
    numberOfDaysToRender,
    history
  );

  const [gridStartTime, setGridStartTime] = useState({
    hours: parseInt(BOOKING_CALENDAR_ENUM.START_HOUR, 10),
    minutes: parseInt(BOOKING_CALENDAR_ENUM.START_MINUTES, 10),
  });
  const isUserFinnishEmployed = LocalStorage.get('is-employed');

  const eachDayInView = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });

  const { error, data, networkStatus: bookingsViewNetworkStatus } = useQuery(
    GET_BOOKINGS_VIEW_DATA,
    {
      variables: {
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      },
      pollInterval: onlineStatus ? 60000 : 0,
    }
  );

  const {
    data: absencesData,
    networkStatus: absencesNetworkStatus,
    error: absencesError,
  } = useQuery(GET_ABSENCES, {
    pollInterval: onlineStatus ? 60000 : 0,
  });

  const {
    error: workingScheduleError,
    data: workingScheduleData,
    networkStatus: workingScheduleNetworkStatus,
  } = useQuery(GET_WORKING_SCHEDULES, {
    variables: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
    pollInterval: onlineStatus ? 60000 : 0,
  });
  const loading =
    workingScheduleNetworkStatus === NETWORK_STATUS_ENUM.LOADING ||
    workingScheduleNetworkStatus === NETWORK_STATUS_ENUM.SET_VARIABLES ||
    bookingsViewNetworkStatus === NETWORK_STATUS_ENUM.LOADING ||
    bookingsViewNetworkStatus === NETWORK_STATUS_ENUM.SET_VARIABLES ||
    absencesNetworkStatus === NETWORK_STATUS_ENUM.LOADING;

  const bookings = data ? data.getBookingsViewData.bookings : null;

  const availabilities = data
    ? data.getBookingsViewData.mergedAvailabilities
    : null;

  const workingSchedule = workingScheduleData
    ? workingScheduleData.getWorkingSchedule
    : null;

  const absences = absencesData ? absencesData.getAbsences : null;

  useEffect(() => {
    function getTimeOnSameDate(time) {
      return new Date(1970, 0, 1, time.substr(0, 2), time.substr(2, 2));
    }

    function getEarliestStartTime() {
      const earliestTime = bookings.reduce((currentDay, nextDay) => {
        const events = nextDay.bookings;
        const earliestTimeOfThisDay = events.reduce((current, next) => {
          const startTime = getStringTime(next.start_time, false, false);
          return parseInt(startTime, 10) < current ? startTime : current;
        }, 2400);
        return parseInt(earliestTimeOfThisDay, 10) < currentDay
          ? earliestTimeOfThisDay
          : currentDay;
      }, 2400);

      const defaultStartTime = getTimeOnSameDate(
        `${BOOKING_CALENDAR_ENUM.START_HOUR}${BOOKING_CALENDAR_ENUM.START_MINUTES}`
      );
      const earliestBookingStartTime = getTimeOnSameDate(earliestTime);
      const gridStartTime = isBefore(earliestBookingStartTime, defaultStartTime)
        ? getTimeObject(earliestBookingStartTime)
        : getTimeObject(defaultStartTime);

      return gridStartTime;
    }
    if (bookings && bookings.length > 0) {
      setGridStartTime(getEarliestStartTime(bookings));
    }
  }, [bookings]);

  const lineBlocks = gridStartTime
    ? buildGridLines(startDate, gridStartTime)
    : null;

  if (error || workingScheduleError || absencesError) {
    return (
      <ErrorHandler error={error || workingScheduleError || absencesError} />
    );
  }
  if (loading) {
    return (
      <Box p={3}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Fragment>
      <ViewGridContainer>
        <TimelineContainer isEmployed={isUserFinnishEmployed}>
          <StickyHeader />
          <TimelineGrid gridStartTime={gridStartTime}>
            <Timeline date={startDate} gridStartTime={gridStartTime}>
              {lineBlocks &&
                lineBlocks.map(block => (
                  <Lines
                    key={`${block.gridStart}${block.gridEnd}`}
                    gridStart={block.gridStart}
                    gridEnd={block.gridEnd}
                  >
                    <Text mb={0} mt={0} size="smaller" color="secondary">
                      {block.displayTime}
                    </Text>
                  </Lines>
                ))}
            </Timeline>
          </TimelineGrid>
        </TimelineContainer>
        {data &&
          eachDayInView.map(day => (
            <DayColumn
              key={day}
              date={day}
              dayBookings={getDataForSingleDay(bookings, day)}
              dayAvailabilities={getDataForSingleDay(availabilities, day)}
              dayWorkingSchedule={
                workingSchedule ? getDataForSingleDay(workingSchedule, day) : []
              }
              dayAbsences={absences ? filterAbsences(absences, day) : []}
              lineBlocks={lineBlocks}
              buildGridRows={buildGridRows}
              gridStartTime={gridStartTime}
              startDate={startDate}
              endDate={endDate}
              isUserFinnishEmployed={isUserFinnishEmployed}
            />
          ))}
      </ViewGridContainer>
      <TimelineNavigationButtonGroup row>
        <TimelineNavigationButton onClick={previousDateRange}>
          <IconChevronLeft color="white" size={32} />
        </TimelineNavigationButton>
        <TimelineNavigationButton onClick={nextDateRange}>
          <IconChevronRight color="white" size={32} />
        </TimelineNavigationButton>
      </TimelineNavigationButtonGroup>
    </Fragment>
  );
}

const ViewGridContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 4px;
  grid-column: 1;
  grid-template-columns: [timeline] 40px [days] repeat(
      auto-fit,
      minmax(80px, 1fr)
    );
  margin-bottom: ${theme.space[4]}px;
`;

const TimelineContainer = styled.div`
  grid-area: 'timeline';
  display: grid;
  grid-template-rows: [header] ${props => (props.isEmployed ? '72px' : '32px')} [timelineGrid] 1fr;
  grid-template-columns: 1fr;
`;

const TimelineGrid = styled.div`
  grid-area: 'timelineGrid';
  display: grid;
  grid-template-rows: ${props =>
    buildGridRows(props.date, props.gridStartTime)};
  grid-template-columns: 1fr;
  padding-top: ${theme.space[1]}px;
`;

const StickyHeader = styled.div`
  background-color: ${theme.colors.greyLight};
  grid-area: 'header';
  grid-column: 1;
  height: 40px;
  width: 40px;
  position: sticky;
  top: 0;
`;

const Timeline = styled.div`
  display: grid;
  grid-template-rows: ${props =>
    buildGridRows(props.date, props.gridStartTime)};
  grid-template-columns: 1fr;
`;

const Lines = styled.div`
  grid-row: ${props => `time-${props.gridStart} / time-${props.gridEnd}`};
  grid-column: 1;
`;

const TimelineNavigationButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  border-radius: 20px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding: 0;
  margin-right: ${theme.space[3]}px;
  :last-child {
    margin-right: ${theme.space[4]}px;
  }
`;

const TimelineNavigationButtonGroup = styled(FlexContainer)`
  flex-shrink: 0;
  z-index: 5;
  width: 100%;
  max-width: ${sizes.width.bookingCardMaxWidth};
  position: fixed;
  bottom: ${theme.space[4]}px;
  justify-content: flex-end;
`;

BookingCalendarView.propTypes = propTypes;

export default BookingCalendarView;

