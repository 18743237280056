import { IconWarning, theme } from '@freska/freska-ui';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { space } from '../../theme/theme';
import FlexContainer from './FlexContainer';

const propTypes = {
  children: PropTypes.node.isRequired,
  hasError: PropTypes.bool,
};

const defaultProps = {
  hasError: true,
};

const NotificationBlock = ({ children, hasError }) => (
  <NotificationContainer
    alignV="flex-start"
    alignH="flex-start"
    hasError={hasError}
  >
    {hasError && <StyledIconWarning color="alert" mr={2} />}
    {children}
  </NotificationContainer>
);

const NotificationContainer = styled(FlexContainer).attrs(() => ({
  ariaLive: 'polite',
}))`
  color: ${props => (props.hasError ? theme.colors.alert : theme.colors.black)};
  margin: ${space.sm} 0;
  padding: ${space.sm} 0;
`;

const StyledIconWarning = styled(IconWarning)`
  flex-shrink: 0;
`;
NotificationBlock.propTypes = propTypes;
NotificationBlock.defaultProps = defaultProps;

export default NotificationBlock;
