import { Box } from '@freska/freska-ui';
import { shape, arrayOf, func, node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { theme } from '@freska/freska-ui';

const propTypes = {
  children: node.isRequired,
  headerActions: arrayOf(
    shape({ handler: func, component: func, iconTooltip: string })
  ),
};

const defaultProps = {
  headerActions: [],
};

const ViewGrid = ({ children }) => (
  <Wrapper>
    <ContentWrapper>{children}</ContentWrapper>
  </Wrapper>
);

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  background: #ffffff;
  fontFamily: 'Nunito Sans',
`;

const ContentWrapper = styled.div`
  margin-top: ${theme.space[2]}px;
  padding: 0 ${theme.space[1]}px;
  @media (min-width: 600px) {
    padding: 0 ${theme.space[3]}px;
  }
`;

ViewGrid.propTypes = propTypes;
ViewGrid.defaultProps = defaultProps;

export default ViewGrid;
