import gql from 'graphql-tag';

export const GET_LANGUAGE = gql`
  {
    language @client
  }
`;

export const GET_BOOKINGS = gql`
  query getBookings(
    $bookingStatus: String!
    $startDate: String
    $endDate: String
  ) {
    getBookings(
      bookingStatus: $bookingStatus
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      bookings {
        id
        uuid
        start_time
        end_time
        duration
        invoiceable_duration
        frequency
        status
        customer_id
        address {
          street_address
          city
          postcode
        }
        service {
          id
          name
        }
        person {
          id
          firstname
          lastname
        }
        company {
          id
          name
          customer_id
        }
        detailSummary {
          extendedDuration
          additionalServices {
            id
            total_amount
          }
          accessStatus
          pets {
            hasDogs
            hasCats
            hasOtherPets
          }
          addressNotes
          needsCleaningSupplies
          customerNotes
        }
        rating {
          average
          comments
        }
        isSubstituteCleaner
        service_worker {
          id
        }
      }
    }
  }
`;

export const GET_RATED_BOOKINGS = gql`
  query getRatedBookings {
    getRatedBookings {
      id
      uuid
      start_time
      end_time
      duration
      frequency
      status
      address {
        street_address
        city
        postcode
      }
      service {
        id
        name
      }
      person {
        id
        firstname
        lastname
      }
      company {
        id
        name
        customer_id
      }
      rating {
        average
        comments
      }
      recurring_booking_id
      isSubstituteCleaner
      service_worker {
        id
      }
    }
  }
`;

export const GET_BOOKINGS_WITH_FEEDBACK = gql`
  query getBookingsWithCustomerFeedback($recurringBookingId: Int!) {
    getBookingsWithCustomerFeedback(recurringBookingId: $recurringBookingId) {
      id
      start_time
      recurring_booking_id
      rating {
        average
        comments
      }
    }
  }
`;

export const GET_WORKER = gql`
  query getServiceWorkerData {
    getServiceWorkerData {
      id
      email
      firstname
      lastname
      phonenumber
      locale
      employed
      average_rating
      average_rating_last_three_months
      average_rating_last_six_months
      past_bookings_count
      past_bookings_hours
      home_address {
        id
        street_address
        postcode
        city
        countrycode
        coordinates {
          lat
          lng
        }
      }
      region {
        id
        key
        name
        countrycode
        currency
        timezone
      }
      workingAreas {
        id
        created_at
        updated_at
        service_worker_id
        area {
          type
          coordinates {
            lat
            lng
          }
        }
      }
      average_rating_details {
        workmanship
        behavior
        punctuality
        overall
      }
    }
  }
`;

export const GET_AVAILABILITY_GROUPS = gql`
  query getAvailabilityGroups {
    getAvailabilityGroups {
      id
      service_worker_id
      valid_to
      valid_from
      only_one_offs
      schedule {
        start
        end
      }
    }
  }
`;

export const GET_AVAILABILITY_CALENDAR_DATA = gql`
  query getAvailabilityCalendarData {
    getAvailabilityCalendarData {
      bookings {
        date
        bookings {
          id
          start_time
          end_time
        }
      }
      availabilities {
        id
        availabilityId
        start_time
        end_time
      }
      mergedAvailabilities {
        date
        availabilities {
          start_time
          end_time
          duration
        }
      }
    }
  }
`;

export const GET_BOOKING = gql`
  query getBooking($bookingId: Int!) {
    getBooking(bookingId: $bookingId) {
      id
      uuid
      start_time
      end_time
      duration
      invoiceable_duration
      frequency
      status
      customer_uuid
      address {
        street_address
        city
        postcode
      }
      service {
        id
        name
      }
      person {
        id
        firstname
        lastname
      }
      company {
        id
        name
        customer_id
      }
      detailSummary {
        extendedDuration
        additionalServices {
          id
          name
          total_amount
        }
        accessStatus
        pets {
          hasDogs
          hasCats
          hasOtherPets
          petsInfo
        }
        addressNotes
        needsCleaningSupplies
        customerNotes
      }
      access {
        booking_id
        door_code
        key_id
        notes
        status
        key {
          id
          door_code
          label
          notes
          return_date
          service_worker_id
          status
        }
      }
      rating {
        average
        overall
        workmanship
        punctuality
        behavior
        comments
        approved
      }
      isSubstituteCleaner
      service_worker {
        id
      }
      service_worker_uuid
      isUnconfirmed
      recurring_booking_id
      window_cleaning_details {
        blinds
        balcony
        balcony_panes
        window_groups
        single_windows
        ventilation_windows
        number_of_layers
      }
      work_report {
        internal_comments
      }
    }
  }
`;

export const GET_BOOKINGS_VIEW_DATA = gql`
  query getBookingsViewData($startDate: String, $endDate: String) {
    getBookingsViewData(startDate: $startDate, endDate: $endDate) {
      bookings {
        date
        bookings {
          id
          access {
            booking_id
            door_code
            key_id
            notes
            status
            key {
              id
              door_code
              label
              notes
              return_date
              service_worker_id
              status
            }
          }
          company {
            id
            name
            customer_id
          }
          start_time
          end_time
          duration
          invoiceable_duration
          frequency
          status
          customer_id
          address {
            street_address
            city
            postcode
            coordinates {
              lat
              lng
            }
          }
          service {
            id
            name
          }
          person {
            id
            firstname
            lastname
          }
          detailSummary {
            extendedDuration
            additionalServices {
              id
              name
              total_amount
            }
            accessStatus
            pets {
              hasDogs
              hasCats
              hasOtherPets
              petsInfo
            }
            addressNotes
            needsCleaningSupplies
            customerNotes
          }
          rating {
            average
            overall
            workmanship
            punctuality
            behavior
            comments
            approved
          }
          isUnconfirmed
          recurring_booking_id
          isSubstituteCleaner
          service_worker {
            id
          }
        }
      }
      availabilities {
        date
        availabilities {
          id
          availabilityId
          start_time
          end_time
        }
      }
      mergedAvailabilities {
        date
        availabilities {
          start_time
          end_time
          duration
        }
      }
    }
  }
`;

export const GET_NEXT_BOOKING = gql`
  query getNextBooking($endDate: String) {
    getNextBooking(endDate: $endDate) {
      id
      uuid
      start_time
      end_time
      duration
      frequency
      status
      customer_uuid
      address {
        street_address
        city
        postcode
      }
      service {
        id
        name
      }
      person {
        id
        firstname
        lastname
      }
      company {
        id
        name
        customer_id
      }
      detailSummary {
        extendedDuration
        additionalServices {
          id
          name
          total_amount
        }
        accessStatus
        pets {
          hasDogs
          hasCats
          hasOtherPets
          petsInfo
        }
        addressNotes
        needsCleaningSupplies
        customerNotes
      }
      isSubstituteCleaner
      isUnconfirmed
      recurring_booking_id
    }
  }
`;

export const GET_INVOICES = gql`
  query getInvoices {
    getInvoices {
      invoices {
        id
        countrycode
        currency
        date
        invoiced
        our_reference
        paid
        payment_term
        region_id
        total_amount
        total_amount_vat_excluded
        total_vat_amount
        service_worker {
          id
          firstname
          lastname
        }
        data {
          bookings_to
          bookings_from
          service_worker_id
        }
        lines {
          booking {
            id
            start_time
            end_time
            duration
            invoiceable_duration
          }
          description
          id
          quantity
          total_amount
          total_amount_vat_excluded
          unit_price
        }
        purchase_invoices {
          invoice_number
          paid_on
        }
        status
      }
      uninvoicedBookings {
        id
        start_time
        end_time
        duration
        invoiceable_duration
        frequency
        status
        customer_id
        address {
          street_address
          city
          postcode
        }
        service {
          id
          name
        }
        person {
          id
          firstname
          lastname
        }
      }
    }
  }
`;

export const GET_INVOICE_BY_ID = gql`
  query getInvoiceById($invoiceId: Int!) {
    getInvoiceById(invoiceId: $invoiceId) {
      id
      status
      allowed_due_date
      countrycode
      currency
      date
      dates_editable
      invoiced
      may_be_sent_to_ukko
      ordered
      our_reference
      paid
      payment_term
      region_id
      total_amount
      total_amount_vat_excluded
      total_vat_amount
      service_worker {
        id
        firstname
        lastname
      }
      data {
        bookings_to
        bookings_from
        service_worker_id
      }
      lines {
        booking {
          id
          start_time
          end_time
          duration
          invoiceable_duration
        }
        data {
          booking_id
          service_worker_id
        }
        description
        id
        quantity
        total_amount
        total_amount_vat_excluded
        unit
        unit_price
        vat_amount
      }
      purchase_invoices {
        accepted
        accepted_at
        automatically_matched
        automatically_matched_at
        created_at
        currency
        date
        due_date
        id
        invoice_number
        manually_matched
        manually_matched_at
        matching_notes
        our_reference
        paid
        paid_on
        purchase_order_ids
        region_id
        total_amount
        total_amount_vat_excluded
        total_vat_amount
        updated_at
        vendor {
          active
          business_id
          created_at
          customer_id
          e_invoice_address
          e_invoice_edi_id
          e_invoice_operator
          id
          invoice_email
          invoice_method
          name
          order_reference
          payment_term_net_days
          updated_at
          vat_number
        }
        your_reference
      }
      vendor {
        active
        business_id
        created_at
        customer_id
        e_invoice_address
        e_invoice_edi_id
        e_invoice_operator
        id
        invoice_email
        invoice_method
        name
        order_reference
        payment_term_net_days
        updated_at
        vat_number
      }
    }
  }
`;

export const GET_PRICE_LEVEL = gql`
  query getPriceLevel {
    getPriceLevel {
      currency
      price
      service_worker_id
      vat_included
      vat_percentage
    }
  }
`;

export const GET_WORKING_AREAS_VIEW_DATA = gql`
  query getWorkingAreaViewData {
    getWorkingAreaViewData {
      areasOfDemand {
        id
        name
        region_id
        area {
          type
          coordinates {
            lng
            lat
          }
        }
        created_at
        updated_at
        active
        areaOfDemandScore
        labelPosition {
          lng
          lat
        }
      }
      workingAreas {
        id
        created_at
        updated_at
        service_worker_id
        area {
          type
          coordinates {
            lat
            lng
          }
        }
        labelPosition {
          lng
          lat
        }
        working_area_template_id
      }
      bookings {
        id
        start_time
        duration
        frequency
        status
        recurring_booking_id
        customer_id
        address {
          id
          street_address
          city
          postcode
          coordinates {
            lat
            lng
          }
        }
        service {
          id
          name
        }
        person {
          id
          firstname
          lastname
        }
      }
      homeAddress {
        lng
        lat
      }
    }
  }
`;

export const GET_SERVICE_WORKER_KEYS = gql`
  query getServiceWorkerKeys($isActionRequired: Boolean) {
    getServiceWorkerKeys(isActionRequired: $isActionRequired) {
      id
      door_code
      label
      notes
      status
      service_worker_id
      customer_id
      return_date
      at_office
      nextBooking {
        id
        start_time
        end_time
        customer_id
        person {
          id
          firstname
          lastname
        }
        address {
          street_address
          city
          postcode
        }
        company {
          id
          name
          customer_id
        }
      }
    }
  }
`;
export const GET_WORKING_SCHEDULES = gql`
  query getWorkingSchedule($startDate: String, $endDate: String) {
    getWorkingSchedule(startDate: $startDate, endDate: $endDate) {
      service_worker_id
      date
      day_start
      day_end
      lunch_break_start
      lunch_break_end
      lunchDuration
      last_change_at
      locked
    }
  }
`;

export const GET_SUPPORT_MATERIAL = gql`
  query getSupportEntries {
    getSupportEntries {
      topic
      description
      category
      content
      slug
      audience
      publishedAt
      updatedAt
    }
  }
`;

export const GET_NEWS_ITEMS = gql`
  query getNewsItems($limit: Int) {
    getNewsItems(limit: $limit) {
      country
      audience
      slug
      title
      thumbImage {
        fields {
          title
          file {
            fileName
            contentType
            details {
              image {
                width
                height
              }
              size
            }
            url
          }
        }
      }
      body
      publishedAt
      updatedAt
      publishOn
      isPinned
    }
  }
`;

export const GET_ABSENCES = gql`
  query getAbsences($filter: String) {
    getAbsences(filter: $filter) {
      id
      start_time
      end_time
      reason {
        id
        key
      }
    }
  }
`;

export const GET_WORKER_NOTES_BY_BOOKING_ID = gql`
  query getWorkerNotesByBookingId($bookingId: Int!) {
    getWorkerNotesByBookingId(bookingId: $bookingId) {
      id
      customer_id
      service_name
      content
      created_at
      creator_id
      updated_at
      updater_id
      canUpdate
      canDelete
    }
  }
`;
