import {
  Provider as ThemeProvider,
  theme,
  typography,
} from '@freska/freska-ui';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import { init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import getClient from './api/client';
import SiteContainer from './containers/SiteContainer/SiteContainer';
import LanguageProvider from './i18n/LanguageProvider';
import { ServiceWorkerProvider } from './useServiceWorker';

/* eslint-disable no-unused-expressions */
const GlobalStyle = createGlobalStyle`
  * {
    border: 0;
    box-sizing: border-box;
    font-size: 100%;
    margin: 0;
    padding: 0;
	  vertical-align: baseline;
  }

  html{
    font-size: 16px;
    background: #ffffff;
    color: ${theme.colors.black};
    height: 100vh;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
  }

  body {
    ${typography.fontRegular()};
    height: 100vh;
  }

  ::selection {
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
  }

  *:focus {
    outline: 2px solid ${theme.colors.rating};
    outline-offset: 2px;
  }

  img {
    border: 0;
    height: auto;
    max-width: 100%;
  }

  a, a:active, a:visited {
    color: ${theme.colors.secondary};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  div#root {
    height: 100vh;
  }
`;

if (process && process.env.REACT_APP_BUILD_ENV !== 'development')
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_BUILD_ENV,
    release: process.env.COMMIT_REF,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.05,
  });

ReactDOM.render(
  <ThemeProvider>
    <ApolloProvider client={getClient()}>
      <LanguageProvider>
        <ServiceWorkerProvider>
          <GlobalStyle />
          <SiteContainer />
        </ServiceWorkerProvider>
      </LanguageProvider>
    </ApolloProvider>
  </ThemeProvider>,
  /* eslint-disable-next-line no-undef */
  document.getElementById('root')
);
