export default {
  app: {
    cancel: 'Cancel',
    close: 'Close',
    menu: 'Menu',
    open: 'Open',
    save: 'Save',
    delete: 'Delete',
  },
  invoices: {
    title: 'Invoices',
    back_link: 'Back to invoices',
    invoice_details: 'Invoice details',
    invoice_period: 'Invoice period',
    start_date: 'Start date',
    end_date: 'End date',
    date_range: 'Date range',
    create_invoice_period_continue: 'Create invoice period...',
    create_invoice_periods_continue: 'Create invoice periods...',
    create_invoice_period: 'Create invoice period',
    create_invoice_periods: 'Create invoice periods',
    all_invoiceable_bookings: 'All invoiceable bookings',
    specific_period: 'Specific period',
    invoice_period_disclaimer:
      'Please review and confirm this information before creating your invoice.',
    invoice_period_note: 'Important',
    grand_total: {
      pending_invoices: 'Total pending:',
      invoiced_last_18months: 'Total invoiced:',
    },
    awaiting_invoice_header: 'This is not an invoice.',
    awaiting_invoice_desc:
      'You still need to send your invoice to Freska with your own company or with an invoicing service.',
    extra_hours: 'Extra hours added',
    agreement:
      'I have reviewed all included bookings carefully and confirm that these are correct',
    section_headers: {
      pending_invoices: 'Awaiting your invoice',
      invoiced_last_18months: 'Invoiced',
      uninvoiced_bookings: 'Uninvoiced bookings',
      invoiceable_bookings: 'Invoiceable bookings',
    },
    status: {
      invoiced: 'Invoiced',
      paid: 'Paid',
      uninvoiced: 'Awaiting your invoice',
      processed: 'Processed',
    },
    errors: {
      no_invoiceable_bookings: 'No bookings in selected period',
      no_price_level:
        'Unable to create invoice details. Please contact the CCC',
      invoice_create_fail:
        'Something went wrong when creating your invoice details.',
    },
    ukko: {
      quantity: 'Quantity',
      unit_price: 'Unit price',
      payment_terms: 'Payment terms',
      total_amount: 'Total',
      invoice_number: 'Invoice no.',
      paid_on: 'Paid on',
      total_amount_with_vat: 'Total with VAT',
      vat_amount: 'VAT amount',
    },
    hours: '{hours} hours',
    hours_short: '{hours}h',
    included_bookings: 'Included bookings',
    totals_per_service_type: 'Totals per service type',
    no_invoices: 'No invoice information available',
    no_pending_invoices: 'No pending invoices available.',
    no_invoiced_last_18months: 'No invoiced invoices available.',
  },
  snackbar: {
    offline: 'You are now offline! Limited functionality available.',
    online:
      'You are back online! Page will refresh automatically in 3 seconds…',
    new_version: 'A new version of the app is available!',
    action: {
      update_now: 'Update now',
      refresh: 'Refresh page',
    },
  },
  areas: {
    title: 'Working Areas',
    next_booking: 'Next booking',
    home_address_marker_desc: 'Your home',
    toggle_visibility_working_area_caption:
      '{isShown, select, true {Hide} other {Show}} my working areas',
    toggle_visibility_customer_markers_caption:
      '{isShown, select, true {Hide} other {Show}} my customers',
    booking_details_link: 'Open booking details',
    error_loading_map:
      'Something went wrong loading the map. Try again later or contact the Freska service team on service.team@freska.fi',
  },
  availability: {
    add_period: 'Add availability period',
    availability_details: 'Availability details',
    availability_this_day: 'Availability this day',
    back_link: 'Back to calendar',
    bookings_this_day:
      '{num, plural, =0 {Bookings} one {{num} booking} other {{num} bookings}} this day',
    calendar: 'Calendar',
    continuous: 'Continuous',
    create_new_availability: 'Create new weekly availability',
    create_upcoming_availability: 'Create availability rule',
    edit_rule: 'Edit rule',
    edit_weekly_availability: 'Edit weekly availability',
    end_on: 'End on',
    ends: 'Ends',
    off: 'Off',
    only_one_offs: 'One-off bookings only',
    start_from: 'Start from',
    starting_on: 'Available from',
    ending_on: 'Available to',
    please_note: 'Please note',
    stable_availability:
      ' A stable availability of at least 12 weeks increases your chances to get regular customers',
    rules: 'Availability rules',
    starts: 'Starts',
    title: 'Availability',
    modal_title: 'Availability on',
    modal_description: '{date}',
    no_bookings_this_day: 'No bookings this day',
    no_availability_this_day: 'No availability has been added yet',
    one_off_modal: {
      title: 'One-offs only',
      message:
        'You are choosing to be available only for one-off bookings. Please note, when this option is selected you will not be assigned any regular customers who have recurring bookings. Activating this option will not remove your already existing regular customers.',
      confirm: 'Yes, give me one-offs only',
    },
    delete_modal: {
      title: 'Delete availability',
      message:
        'If you delete this availability, your bookings will not be cancelled automatically. You still need to contact Freska office via WhatsApp.',
      confirm: 'Delete anyways',
    },
    errors: {
      intervals_only:
        'Please note that your time input has been rounded to the nearest 15 minutes interval.',
    },
    remove_availability: 'Delete',
    save_availability: 'Save availability',
    disabled_explanation:
      'Upcoming availability can only be set if the first one has an ending date',
  },
  absence: {
    absence_types: {
      sick_leave: 'On sick leave',
      vacation: 'On vacation',
      other_absence: 'On absence leave',
    },
    last_day_of_absence: 'Last day of absence: ',
    report_fit_link_caption: 'Report fit',
    still_sick: "I'm still sick",
    modal_close: 'Close',
    action_fit_for_work: "I'm fit for work again",
    modal_heading: 'Report fit work',
    modal_text_intro_before_threshold:
      'You are currently on sick leave. Report <b>before {time} today</b> if you are ready to work tomorrow.',
    modal_text_intro_after_threshold:
      "You are currently on sick leave. <b>You missed today's {time} deadline</b> to restart work tomorrow.",
    modal_text_details_before_threshold:
      'Otherwise, your bookings for tomorrow will automatically be rescheduled or reassigned.',
    modal_text_details_after_threshold:
      "Tomorrow's bookings have been unassigned from you. If you report fit for work now, you are expected to return to work <b>after tomorrow</b> for your next scheduled booking. Questions? Please contact us.",
    modal_text_details_still_sick:
      'Still sick? Feel better soon and when you do, remember to report yourself fit on time.',
    error_updating_absence:
      "Oops! Something went wrong. We couldn't report you fit for work tomorrow succesfully. Please contact Freska office to report yourself fit instead.",
    error_fetching_ongoing_absences:
      'An error occurred when looking for your current absences. Please try again later and in case of urgency contact Freska office.',
  },
  access: {
    status: {
      no_key: 'Customer should open the door',
      door_code_only: 'Door code only',
      stored_by_customer: 'Stored by customer',
      key: 'Key',
    },
    labels: {
      door_code: 'Door code',
      access_notes: 'Access notes',
    },
  },
  bookings: {
    calendar: 'Calendar',
    completed: 'Completed',
    hide_past_bookings: 'Hide past bookings',
    last_week: 'Last week',
    next_week: 'Next week',
    no_bookings: 'No bookings this week',
    show_past_bookings: 'Show past bookings',
    title: 'Bookings',
    this_week: 'This week',
    today: 'Today',
    total_time:
      '{totalBookings} {totalBookings, plural, one {Booking} other {Bookings}} this week (total {totalHours} {totalHours, plural, one {hour} other {hours}})',
    unconfirmed_bookings:
      '{num} Unconfirmed {num, plural, one {Booking} other {Bookings}}',
    upcoming: 'Upcoming',
    week_num: 'Week {num}',
    company_contact_person: 'Contact person: ',
    new_customer: 'New customer!',
    extended_duration:
      'This is a new subscriber’s first cleaning. We added {duration} minutes extra to this booking so that you have time to perform a 5-star cleaning. You can use the extra time to double check the most important areas and write greetings in the welcome home card. 💙',
    card: {
      access: 'Access details',
      additional_services: 'Additional services',
      address_notes: 'Address notes',
      cleaning_supplies: 'Cleaning supplies',
      customer_notes: 'Customer notes',
      window_cleaning_notes: 'Window cleaning details',
      directions: 'Get directions',
      rating: 'Rating',
      average_rating: 'Average rating',
      no_rating_provided: 'No rating provided',
      service_type: {
        '1': 'Home cleaning',
        '2': 'Office cleaning',
        '3': 'Window cleaning',
        '4': 'Moving cleaning',
        '6': 'Hospital cleaning',
        '7': 'Deep cleaning',
        '8': 'Viewing cleaning',
        '9': 'Office meeting',
        '10': 'Key pick up',
        '11': 'Break',
        '12': 'Travel',
        '13': 'Late cancellation',
        '14': 'Training',
        '15': 'Vacation',
        '16': 'Parental leave',
        '17': 'Unpaid leave',
        '18': 'Sick leave',
        '19': 'Child sick leave',
        '20': 'Flex home cleaning',
        '21': 'Vacation service',
        '22': 'Stairs cleaning',
        '23': 'Custom cleaning',
        '36': 'Moving cleaning',
        '38': 'Hospital cleaning',
      },
      frequency: {
        '0': 'One-off',
        '1': 'Weekly',
        '2': 'Bi-weekly',
        '4': '4-weekly',
        substitute: 'Stand-in',
      },
      pets: {
        cat: 'Cat',
        dog: 'Dog',
        other: 'Other',
        pets: 'Pets',
      },
    },
    details: {
      back_link: 'Back to overview',
      booking_details: 'Booking details',
      cleaning_supplies: {
        by_freska: 'Cleaning supplies by Freska.',
        by_customer: 'Customer provides cleaning supplies.',
      },
      sw_comment_to_freska: 'Your comment to Freska',
      service_type: 'Service type',
      service_frequency: 'Frequency',
      window_cleaning: {
        window_groups: 'Window groups',
        single_windows: 'Single windows',
        balcony: 'Balcony',
        balcony_value: 'Yes, {num} panes',
        no_balcony: 'No',
        number_of_layers: 'Sides/window',
        ventilation_windows: 'Vent. windows',
        included: 'Yes',
        not_included: 'No',
        blinds: 'Blinds',
      },
      feedback_history: {
        no_history:
          'No customer feedback provided to you on this subscription.',
        title: 'Feedback history',
      },
      tab: {
        details: 'Details',
        instructions: 'Instructions',
        feedback: 'Feedback',
      },
      instructions: {
        title: 'Instructions',
        customer_instructions_title: "Customer' cleaning instructions",
        worker_notes_title: 'Worker notes',
        worker_notes_description:
          'Worker notes are meant for anything your stand-in must know about doing a high quality {service} for this customer in case you get sick or are on a holiday. Any worker doing a {service} for this customer will be able to see these notes.',
        worker_notes_info_tag: 'Help',
        no_cleaning_instructions:
          'Customer has not provided cleaning instructions.',
        error_deleting_worker_note:
          'Deleting the note was not successful. Refresh the page and try again. If the problem persists, please contact Freska service team.',
        error_fetching_worker_notes:
          'Retrieving the notes was not successful. Refresh the page to try again. If the problem persists, please contact Freska service team.',
        error_saving_worker_note:
          'Saving changes to the note was not successful. Refresh the page and try again. If the problem persists, please contact Freska service team.',
        save_changes: 'Save changes',
        add_note: 'Add note',
        cancel_add_note: 'Discard',
        cancel_edit_note: 'Discard',
        last_updated: 'Last updated ',
        delete_confirmation_modal: {
          title: 'Delete note',
          message:
            'This action will remove this note for all (future) workers for this customer and service. This action cannot be undone.',
          button_caption: 'Delete note',
        },
        no_worker_notes:
          'No worker notes for this customer and service have been added yet.',
        error_saving_worker_notes:
          'Error saving worker notes. Please try again later',
      },
    },
    status: {
      approved: 'Approved',
      booked: 'Booked',
      completed: 'Completed',
      invoiced: 'Invoiced',
      paid: 'Paid',
      prebooked: 'Pre-booked',
      unconfirmed: 'Unconfirmed',
      uninvoiced: 'Uninvoiced',
      outside_availability_period: 'Outside availability',
      canceled: 'cancelled',
    },
    summary: 'Summary',
    unconfirmed: {
      comments_to_freska: 'Comments to Freska (optional)',
      confirm_as_completed: 'I have completed this booking',
      ended: 'Ended',
      show_booking_details: 'Show booking details',
      started: 'Started',
      tips_and_tricks: 'Tips & Tricks for cleaners',
      comments_to_freska_placeholder:
        'Write here only if the time has been different than actual booked time, or if there is something we should know. This text is optional and not visible to customer!',
    },
  },
  calendar: {
    modal: {
      title: 'Availability details',
    },
  },
  keys: {
    title: 'Keys',
    back_link: 'Back to overview',
    key_details: 'Key details',
    notes: 'Notes',
    next_booking: 'Next booking',
    no_keys: 'No keys found',
    pick_up_before_date: 'Pick up before: {date}',
    return_latest_by_date: 'Return latest by: {date}',
    section_headers: {
      return: 'Keys to return to the office',
      pick_up: 'Keys to pick up from the office',
      not_urgent: 'Keys you should have',
    },
    status: {
      at_office: 'Freska Office',
      at_you: 'You',
      at_another_cleaner: 'Another cleaner',
      at_cleaner: 'You',
      at_freska_helsinki: 'Freska Helsinki Office',
      at_freska_stockholm: 'Freska Stockholm Office',
      at_freska_oslo: 'Freska Oslo Office',
      at_freska_bergen: 'Freska Bergen Office',
      at_herohub_billingstad: 'HeroHub Billingstad',
      at_herohub_drammen: 'HeroHub Drammen',
      at_herohub_majorstuen: 'HeroHub Majorstuen',
      at_herohub_langhus: 'HeroHub Langhus',
      at_herohub_lorenskog: 'HeroHub Lørenskog',
    },
    tags: {
      return: 'Return key to office',
      pick_up: 'Requires pick up',
    },
    labels: {
      return_date: 'Latest return date',
      label: 'Key label',
      safekeeper: 'Safekeeper',
      pick_up_location: 'Pick up location',
      key_notes: 'Key notes',
    },
  },
  dates: {
    mon: 'Mon',
    monday: 'Monday',
    tue: 'Tue',
    tuesday: 'Tuesday',
    wed: 'Wed',
    wednesday: 'Wednesday',
    thu: 'Thu',
    thursday: 'Thursday',
    fri: 'Fri',
    friday: 'Friday',
    sat: 'Sat',
    saturday: 'Saturday',
    sun: 'Sun',
    sunday: 'Sunday',
    jan: 'Jan',
    january: 'January',
    feb: 'Feb',
    february: 'February',
    mar: 'Mar',
    march: 'March',
    apr: 'Apr',
    april: 'April',
    may: 'May',
    may_full: 'May',
    jun: 'Jun',
    june: 'June',
    jul: 'Jul',
    july: 'July',
    aug: 'Aug',
    august: 'August',
    sep: 'Sep',
    september: 'September',
    oct: 'Oct',
    october: 'October',
    nov: 'Nov',
    november: 'November',
    dec: 'Dec',
    december: 'December',
  },
  pluralDates: {
    mon: 'Mondays',
    tue: 'Tuesdays',
    wed: 'Wednesdays',
    thu: 'Thursdays',
    fri: 'Fridays',
    sat: 'Saturdays',
    sun: 'Sundays',
  },
  shortDates: {
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
  },
  time: {
    duration_hours: {
      short: '{hours}h',
      long: '{hours} {hours, plural, one {hour} other {hours}}',
    },
    duration_minutes: {
      short: '{minutes}m',
      long: '{minutes} {minutes, plural, one {minute} other {minutes}}',
    },
  },
  error: {
    contact_support:
      'Please contact the Freska office if the problem continues.',
    default: 'An error has occurred. Please try again.',
    incorrect_credentials: 'Your email and/or password are incorrect',
    something_went_wrong:
      "Looks like something went wrong. Don't worry, we'll clean it up.",
    start_over: 'Start Over',
    whoops: 'Whoops!',
    wrong_input: "The passwords don't match",
    offline_header: 'Not available offline',
    offline_description:
      'When you are offline, the Superheroes app provides limited functionality based on the previous pages you have visited. Once you are connected to internet, full functionality will be restored.',
  },
  login: {
    email: 'Email',
    password: 'Password',
    login: 'Log in',
    return_to_login: 'Return to login',
    forgot_password: 'Forgot password?',
    reset_password: 'Send instructions',
    reset_password_email_sent:
      'The email with instructions has been sent to {email}.',
    translate_msg: 'In Finnish',
    reset_password_success: 'Your password has been changed',
  },
  nav: {
    logout: 'Logout',
  },
  dashboard_news: {
    title: 'Menu',
  },
  dashboard: {
    title: 'Dashboard',
    latest_news: 'Latest news',
    all_news: 'All news',
    next_booking: 'Now/Next',
    view_schedule: 'View schedule',
    published: 'Published on {date} at {time}',
    news_fetch_failed: 'Sorry, something happened while fetching the news',
    action_required: 'Your action required',
    booking_status: 'Unconfirmed booking',
    up_to_date: 'You are all up-to-date! Awesome! 💪',
    customer_instructions_available: 'Customer instructions available!',
    no_upcoming_cleanings:
      'Take it easy! No bookings during the next three days.',
    fetching_next_failed:
      'Something happened while fetching the next booking. View schedule to see your upcoming cleanings.',
    view: 'View',
    key_status: {
      at_cleaner: 'Return key to office',
      at_office: 'Pick up from office',
      at_freska_oslo: 'Pick up from Freska Oslo office',
      at_freska_bergen: 'Pick up from Freska Bergen office',
      at_freska_helsinki: 'Pick up from Freska Helsinki office',
      at_freska_stockholm: 'Pick up from Freska Stockholm office',
      at_herohub_billingstad: 'Pick up from HeroHub Billingstad',
      at_herohub_drammen: 'Pick up from HeroHub Drammen',
      at_herohub_majorstuen: 'Pick up from HeroHub Majorstuen',
      at_herohub_langhus: 'Pick up from HeroHub Langhus',
      at_herohub_lorenskog: 'Pick up from HeroHub Lørenskog',
    },
  },
  not_found: {
    return: 'Return',
    title: 'Page Not Found',
    whoops: 'Whoops… that page could not be found.',
  },
  support: {
    title: 'Support',
    back_link: 'Back',
  },
  news: {
    title: 'News',
    back_link: 'Back to overview',
    publishedAt: 'Published at {date} at {time}',
  },
  profile: {
    title: 'Profile',
    back_link: 'Back to profile',
    authenticated_user: 'Authenticated user',
    password: {
      change_password: 'Change Password',
      confirm_new_password: 'Confirm new password',
      current_password: 'Current password',
      new_password: 'New password',
      update: 'Update password',
    },
    language: {
      options: {
        english: 'English',
        finnish: 'Finnish',
      },
      set_language: 'Set language',
    },
    success: 'Your password has been changed',
  },
  ratings: {
    average_rating: 'Average rating for the last {num} months',
    behavior: 'Behavior',
    comments: 'Customer feedback',
    latest_ratings: 'Latest ratings',
    on_time: 'On Time',
    overall: 'Overall',
    title: 'Rating',
    total_cleanings: 'Total Cleanings',
    total_hours: 'Total Hours',
    quality: 'Quality',
    no_customer_feedback_provided:
      'Customer did not provide feedback on this booking.',
  },
  services: {
    fridge: 'Fridge',
    ironing: 'Ironing of clothes',
    oven_cleaning: 'Oven cleaning',
    sauna_cleaning: 'Sauna cleaning',
    trash: 'Trash',
  },
  face_mask: {
    title: 'Always wear a face mask',
    description:
      'To prevent the spread of coronavirus, wearing a face mask is {compulsory, select, true {compulsory} other {strongly recommended}}.',
  },
  article_was_not_found:
    'Unfortunately article was not found. Go back and try again.',
};
