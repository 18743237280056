import { configureScope } from '@sentry/react';

import localStorage from './localStorage';

const logout = ({ history, client }) => {
  localStorage.remove('freska-auth');
  client.resetStore();
  configureScope(scope => scope.setUser(null));
  history.push('/');
};

export default logout;
