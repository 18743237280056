import React, { Fragment } from 'react';

import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  useIntl,
} from 'react-intl';
import styled from 'styled-components';

import {
  Box,
  Button,
  Text,
  IconDirections,
  theme,
  IconCalendarEvent,
} from '@freska/freska-ui';
import FiberNewIcon from '@material-ui/icons/FiberNew';

import { formatDuration } from '../../utils/formatNumber';
import { trackEvent } from '../../utils/tracking';
import { space } from '../../theme/theme';
import { bookingType } from '../../types';

import DataBlock from '../Common/DataBlock';

const propTypes = {
  booking: bookingType,
};

export function ContactDetails({ booking }) {
  const intl = useIntl();
  const extendedDuration = booking.detailSummary.extendedDuration;

  const buildMapsURL = address => {
    const fullAddress = `${address.street_address} ${address.city} ${address.postcode}`;
    return encodeURI(
      `https://www.google.com/maps/dir/?api=1&destination=${fullAddress}`
    );
  };

  const handleDirectionsClick = address => {
    trackEvent('Bookings: Directions button clicked', {
      category: 'Bookings',
    });
    window.open(buildMapsURL(address));
    return false;
  };

  const isDirectionButtonPrimary = (bookingStatus, isUnconfirmed) =>
    (bookingStatus === 'booked' && !isUnconfirmed) ||
    bookingStatus === 'prebooked';

  return (
    <>
      <HeaderCustomerGrid>
        <Box>
          <Box display="flex">
            {booking.company ? (
              <Fragment>
                <Text as="p" color="black" bold mb={0}>
                  {booking.company.name}
                </Text>
                <Text as="p" color="black" mb={0}>
                  <FormattedMessage id="bookings.company_contact_person" />
                  {`${booking.person.firstname} ${booking.person.lastname}`}
                </Text>
              </Fragment>
            ) : (
              <Text as="p" color="black" bold mb={0}>
                {`${booking.person.firstname} ${booking.person.lastname}`}
              </Text>
            )}
            {booking.detailSummary.extendedDuration > 0 && (
              <FiberNewIcon
                style={{ fill: theme.colors.attention, marginLeft: '4px' }}
              />
            )}
          </Box>
          <Address as="p" mb={0}>
            <span>
              {booking.address.street_address}
              &#44;
            </span>
            <span>
              {booking.address.postcode}
              &nbsp;
              {booking.address.city}
            </span>
          </Address>
        </Box>
        <StyledButton
          isDirectionButtonPrimary={isDirectionButtonPrimary(
            booking.status,
            booking.isUnconfirmed
          )}
          onClick={() => handleDirectionsClick(booking.address)}
          m={0}
        >
          <IconDirections
            title={intl.formatMessage({
              id: 'bookings.card.directions',
            })}
            color={
              isDirectionButtonPrimary(booking.status, booking.isUnconfirmed)
                ? 'white'
                : 'primary'
            }
          />
        </StyledButton>
      </HeaderCustomerGrid>
      <DataBlock
        Icon={IconCalendarEvent}
        hasContent={!!booking.start_time && !!booking.end_time}
        hasBottomMargin={!extendedDuration}
      >
        <Text m={0} as="p">
          <FormattedDate
            value={booking.start_time}
            year="numeric"
            month="long"
            day="numeric"
            weekday="long"
          />
          <br />
          <FormattedTime
            value={booking.start_time}
            hour="2-digit"
            minute="2-digit"
            hour12={false}
          />
          &nbsp;&ndash;&nbsp;
          <FormattedTime
            value={booking.end_time}
            hour="2-digit"
            minute="2-digit"
            hour12={false}
          />
          {booking.invoiceable_duration &&
          booking.invoiceable_duration !== booking.duration ? (
            <Fragment>
              <br />
              <Text as="span">
                {formatDuration(booking.duration, intl, 'short', true)}
              </Text>
              &nbsp;
              <Text as="span" bold>
                &#40;
                {booking.invoiceable_duration >= booking.duration && '+'}
                {formatDuration(
                  booking.invoiceable_duration - booking.duration,
                  intl,
                  'short',
                  true
                )}
                &#41;
              </Text>
            </Fragment>
          ) : (
            ` (${formatDuration(booking.duration, intl, 'short', true)})`
          )}
        </Text>
      </DataBlock>
    </>
  );
}

const Address = styled(Text)`
  margin-bottom: 0;
  span {
    display: inline-block;
    padding-right: ${space.xs};
  }
`;

const HeaderCustomerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 48px;
  grid-column-gap: ${space.sm};
  align-items: center;
  margin-bottom: 24px;
  padding-top: 32px;
`;

const StyledButton = styled(Button)`
  min-width: 42px;
  min-height: 42px;
  width: 42px;
  height: 42px;
  padding: ${space.sm};
  border-radius: 50%;
  justify-self: center;
  svg {
    height: 24px;
    width: 24px;
  }
  ${props =>
    !props.isDirectionButtonPrimary &&
    `
    background-color: ${theme.colors.white};
    box-shadow: none;
    :hover,
    :visited,
    :focus  {
      background-color: ${theme.colors.white};
    }
    `}
`;

ContactDetails.propTypes = propTypes;
