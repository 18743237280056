import React, { Fragment } from 'react';
import {
  // FormattedMessage,
  // FormattedDate,
  // FormattedTime,
  useIntl,
} from 'react-intl';
// import styled from 'styled-components';
import // Box,
// Button,
// Tag,
// Text,
// IconDirections,
// IconCalendarEvent,
// IconRecurring,
// IconOneOff,
// theme,
'@freska/freska-ui';
// import FiberNewIcon from '@material-ui/icons/FiberNew';

// import { trackEvent } from '../../utils/tracking';
// import { formatDuration } from '../../utils/formatNumber';
// import { space } from '../../theme/theme';

import Alert from '../Common/Alert';
// import DataBlock from '../Common/DataBlock';

import BookingDetailsPets from './BookingDetailsPets';
import BookingDetailsServices from './BookingDetailsServices';
// import BookingDetailsCleaningSupplies from './BookingDetailsCleaningSupplies';
import BookingDetailsAddress from './BookingDetailsAddress';
import BookingDetailsAccess from './BookingDetailsAccess';
import BookingDetailsRating from './BookingDetailsRating';
import BookingDetailsCustomerFeedback from './BookingDetailsCustomerFeedback';
import BookingDetailsUnconfirmed from './BookingDetailsUnconfirmed';
import BookingDetailsWindowCleaningNotes from './BookingDetailsWindowCleaningNotes';
import BookingDetailsServiceWorkerComment from './BookingDetailsServiceWorkerComment';
import { bookingType } from '../../types';

// function getFrequencyIcon(serviceId, isSubstituteCleaner) {
//   if (serviceId === '0' || isSubstituteCleaner) return IconOneOff;
//   return IconRecurring;
// }

// function getTagColor(status, isUnconfirmed) {
//   if (isUnconfirmed) return 'attention';
//   switch (status) {
//     case 'booked':
//     case 'prebooked':
//       return 'secondary';
//     default:
//       return 'completed';
//   }
// }

// function getBookingStatus(status, isUnconfirmed) {
//   if (isUnconfirmed) return 'unconfirmed';

//   switch (status) {
//     case 'booked':
//       return 'booked';
//     case 'prebooked':
//       return 'prebooked';
//     default:
//       return 'completed';
//   }
// }

const propTypes = {
  booking: bookingType,
};

function BookingDetailsTabDetails({ booking }) {
  const intl = useIntl();

  const extendedDuration = booking.detailSummary.extendedDuration;

  return (
    <Fragment>
      {extendedDuration > 0 && (
        <Alert
          mt={1}
          mb={3}
          severity="info"
          title={intl.formatMessage({ id: 'bookings.new_customer' })}
          message={intl.formatMessage(
            { id: 'bookings.extended_duration' },
            { duration: extendedDuration }
          )}
        />
      )}
      {/* <DataBlock
        Icon={getFrequencyIcon(booking.frequency, booking.isSubstituteCleaner)}
        hasContent={!!booking.start_time && !!booking.end_time}
      >
        <Text m={0} as="p">
          <FormattedMessage
            id={`bookings.card.frequency.${
              booking.isSubstituteCleaner ? 'substitute' : booking.frequency
            }`}
          />
        </Text>
      </DataBlock> */}

      {/* <DataBlock
        Icon={getServiceTypeIcon(24, booking?.service?.id)}
        hasContent={!!booking?.service?.id}
      >
        <Text m={0} as="p">
          <FormattedMessage
            id={`bookings.card.service_type.${booking.service.id}`}
          />
        </Text>
      </DataBlock> */}

      {!!booking.rating && (
        <Fragment>
          <BookingDetailsRating rating={booking.rating} />
          <BookingDetailsCustomerFeedback comment={booking.rating.comments} />
        </Fragment>
      )}
      {booking.work_report && (
        <BookingDetailsServiceWorkerComment
          comment={booking.work_report.internal_comments}
        />
      )}

      {!!booking.isUnconfirmed && <BookingDetailsUnconfirmed data={booking} />}

      {/* <BookingDetailsCleaningSupplies
        cleaningSuppliesByFreska={booking.detailSummary.needsCleaningSupplies}
      /> */}

      {booking.service.id === 3 && !!booking.window_cleaning_details && (
        <BookingDetailsWindowCleaningNotes
          windowCleaningDetails={booking.window_cleaning_details}
        />
      )}

      <BookingDetailsAccess
        access={booking.access}
        accessIconStatus={booking.detailSummary.accessStatus}
        serviceWorkerId={Number(booking.service_worker.id)}
      />

      <BookingDetailsAddress addressNote={booking.detailSummary.addressNotes} />

      {booking.service.id !== 3 && (
        <BookingDetailsServices
          additionalServices={booking.detailSummary.additionalServices}
        />
      )}
      <BookingDetailsPets pets={booking.detailSummary.pets} />
    </Fragment>
  );
}

BookingDetailsTabDetails.propTypes = propTypes;

export default BookingDetailsTabDetails;
