import React, { Component, Fragment } from 'react';
import { oneOf, func, arrayOf, shape } from 'prop-types';
import { ApolloConsumer } from 'react-apollo';
import styled from 'styled-components';
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import {
  Card,
  Label,
  RadioButton,
  Input,
  Text,
  Button,
  typography,
  theme,
} from '@freska/freska-ui';
import {
  getMonth,
  getYear,
  isWithinInterval,
  format,
  parseISO,
  endOfDay,
  startOfDay,
} from 'date-fns';
import withLanguage from '../../utils/withLanguage';
import { trackEvent } from '../../utils/tracking';
import { formatNumber } from '../../utils/formatNumber';
import validateData, { checkForError } from '../../utils/validate';
import schema from './specificPeriodSchema';
import { LANGUAGES_TYPE } from '../../constants';
import { GET_PRICE_LEVEL } from '../../gql/queries';

import FlexContainer from '../Common/FlexContainer';
import NotificationBlock from '../Common/NotificationBlock';

import { sizes, space } from '../../theme/theme';
import { bookingType } from '../../types';
import { ServiceWorkerContext } from '../../useServiceWorker';

import { InfoBox } from './InfoBox';

const propTypes = {
  intl: shape({}).isRequired,
  language: oneOf(LANGUAGES_TYPE).isRequired,
  bookings: arrayOf(bookingType).isRequired,
  onCreateNewInvoice: func.isRequired,
};

class InvoiceableBookingsList extends Component {
  state = {
    bookingsGroupedByMonth: [],
    selectedPeriod: 'all',
    startDate: '',
    endDate: '',
    errors: {},
    showSpecificPeriodErrorMessage: false,
    noPriceLevelFound: false,
    loading: false,
  };

  componentDidMount() {
    const { bookings: invoiceableBookings } = this.props;
    const bookingsGroupedByMonth = {};

    invoiceableBookings.forEach(booking => {
      const index = `${getMonth(parseISO(booking.start_time))}-${getYear(
        parseISO(booking.start_time)
      )}`;
      if (bookingsGroupedByMonth[index]) {
        bookingsGroupedByMonth[index].push(booking);
      } else {
        bookingsGroupedByMonth[index] = [booking];
      }
    });

    const result = Object.entries(bookingsGroupedByMonth).map(
      ([date, bookings]) => ({
        date,
        bookings,
      })
    );

    this.setState({
      bookingsGroupedByMonth: result,
      startDate: format(
        parseISO(result[0].bookings[0].start_time),
        'yyyy-MM-dd'
      ),
      endDate: format(
        parseISO(
          result[result.length - 1].bookings[
            result[result.length - 1].bookings.length - 1
          ].start_time
        ),
        'yyyy-MM-dd'
      ),
    });
  }

  getTotalHours = (bookings, locale) =>
    formatNumber(
      bookings.reduce(
        (pre, cur) => pre + Number(cur.invoiceable_duration || cur.duration),
        0
      ),
      locale
    );

  getSpecificPeriodBookings = () => {
    const { bookings } = this.props;
    const { startDate, endDate } = this.state;
    const filteredBookings = bookings.filter(booking =>
      isWithinInterval(parseISO(booking.start_time), {
        start: startOfDay(parseISO(startDate)),
        end: endOfDay(parseISO(endDate)),
      })
    );
    return filteredBookings;
  };

  createSpecificPeriodInvoice = () => {
    const { onCreateNewInvoice } = this.props;
    const { startDate, endDate } = this.state;
    const specificBookings = this.getSpecificPeriodBookings();
    if (!specificBookings.length) {
      this.setState({ showSpecificPeriodErrorMessage: true, loading: false });
    } else {
      this.setState({ showSpecificPeriodErrorMessage: false });
      onCreateNewInvoice({
        startDate: parseISO(startDate),
        endDate: parseISO(endDate),
        bookings: specificBookings,
      });
    }
  };

  createInvoicePeriod = async (e, client) => {
    e.preventDefault();
    const { onCreateNewInvoice, bookings } = this.props;
    this.setState({ loading: true });
    try {
      await client.query({
        query: GET_PRICE_LEVEL,
      });
      const {
        startDate,
        endDate,
        selectedPeriod,
        bookingsGroupedByMonth,
      } = this.state;

      switch (selectedPeriod) {
        case 'specific': {
          trackEvent('Invoice period view opened', {
            category: 'Invoices',
            invoicePeriodType: 'specific',
          });
          validateData({ startDate, endDate }, schema).then(res =>
            this.setState(
              { errors: res, loading: false },
              () =>
                Object.keys(res).length === 0 &&
                this.createSpecificPeriodInvoice()
            )
          );
          break;
        }
        case 'all': {
          trackEvent('Invoice period view opened', {
            category: 'Invoices',
            invoicePeriodType: 'all',
          });
          onCreateNewInvoice({
            startDate: parseISO(bookings[0].start_time),
            endDate: parseISO(bookings[bookings.length - 1].start_time),
            bookings,
          });
          break;
        }
        default: {
          trackEvent('Invoice period view opened', {
            category: 'Invoices',
            invoicePeriodType: 'month',
          });
          const invoicedPeriod = bookingsGroupedByMonth.find(
            month => month.date === selectedPeriod
          );
          onCreateNewInvoice({
            startDate: parseISO(invoicedPeriod.bookings[0].start_time),
            endDate: parseISO(
              invoicedPeriod.bookings[invoicedPeriod.bookings.length - 1]
                .start_time
            ),
            bookings: invoicedPeriod.bookings,
          });
          break;
        }
      }
    } catch (err) {
      this.setState({ noPriceLevelFound: true, loading: false });
    }
  };

  render() {
    const { intl, language: locale, bookings } = this.props;
    const {
      bookingsGroupedByMonth,
      selectedPeriod,
      startDate,
      endDate,
      errors,
      showSpecificPeriodErrorMessage,
      loading,
      noPriceLevelFound,
    } = this.state;

    const endYear = getYear(parseISO(endDate));
    // Create multiple invoices only if there's bookings from 2021 and 2022 because of company ID change
    const isMultipleInvoices =
      getYear(parseISO(startDate)) !== endYear && endYear === 2022;

    return (
      <ApolloConsumer>
        {client => (
          <ServiceWorkerContext.Consumer>
            {({ onlineStatus }) => (
              <GroupSection>
                <Label pl={3} mb={2}>
                  <FormattedMessage id="invoices.section_headers.invoiceable_bookings" />
                </Label>
                <Card mx={space.xs}>
                  <FlexContainer column>
                    <form>
                      {noPriceLevelFound && (
                        <NotificationBlock hasError>
                          <FormattedMessage id="invoices.errors.no_price_level" />
                        </NotificationBlock>
                      )}
                      <RadioButton
                        mt={1}
                        label={intl.formatMessage({
                          id: 'invoices.all_invoiceable_bookings',
                        })}
                        id="all-invoiceable-bookings"
                        name="invoiceable-bookings-period"
                        onChange={() =>
                          this.setState({
                            selectedPeriod: 'all',
                            showSpecificPeriodErrorMessage: false,
                          })
                        }
                        value="all"
                        checked={selectedPeriod === 'all'}
                      />
                      <TimeFlexContainer alignV="center">
                        <DetailsWrapper>
                          {`${this.getTotalHours(bookings, locale)} hours`}
                        </DetailsWrapper>
                        <DetailsWrapper>
                          <FormattedDate
                            value={bookings[0].start_time}
                            year="numeric"
                            month="short"
                            day="2-digit"
                          />
                          &nbsp;&ndash;&nbsp;
                          <FormattedDate
                            value={bookings[bookings.length - 1].start_time}
                            year="numeric"
                            month="short"
                            day="2-digit"
                          />
                        </DetailsWrapper>
                      </TimeFlexContainer>
                      {bookingsGroupedByMonth.map(month => (
                        <Fragment key={month.date}>
                          <RadioButton
                            mt={1}
                            label={intl.formatDate(
                              month.bookings[0].start_time,
                              {
                                month: 'long',
                                year: 'numeric',
                              }
                            )}
                            id={month.date}
                            name="invoiceable-bookings-period"
                            onChange={() =>
                              this.setState({
                                selectedPeriod: month.date,
                                showSpecificPeriodErrorMessage: false,
                              })
                            }
                            value={month.date}
                            checked={selectedPeriod === month.date}
                          />
                          <TimeFlexContainer alignV="center">
                            <DetailsWrapper>
                              {`${this.getTotalHours(
                                month.bookings,
                                locale
                              )} hours`}
                            </DetailsWrapper>
                            <DetailsWrapper>
                              <FormattedDate
                                value={month.bookings[0].start_time}
                                year="numeric"
                                month="short"
                                day="2-digit"
                              />
                              &nbsp;&ndash;&nbsp;
                              <FormattedDate
                                value={
                                  month.bookings[month.bookings.length - 1]
                                    .start_time
                                }
                                year="numeric"
                                month="short"
                                day="2-digit"
                              />
                            </DetailsWrapper>
                          </TimeFlexContainer>
                        </Fragment>
                      ))}
                      <RadioButton
                        mt={1}
                        label={intl.formatMessage({
                          id: 'invoices.specific_period',
                        })}
                        id="specific-period"
                        name="invoiceable-bookings-period"
                        onChange={() =>
                          this.setState({ selectedPeriod: 'specific' })
                        }
                        value="specific"
                        checked={selectedPeriod === 'specific'}
                      />
                      {selectedPeriod === 'specific' && (
                        <FlexContainer column>
                          <RangeWrapper>
                            <StyledInput
                              id="start-date"
                              type="date"
                              label={intl.formatMessage({
                                id: 'invoices.start_date',
                              })}
                              onChange={e =>
                                this.setState({
                                  startDate: e.target.value,
                                  showSpecificPeriodErrorMessage: false,
                                })
                              }
                              value={startDate}
                              shrink
                              error={checkForError(errors, 'startDate')}
                            />
                            <FlexContainer row center>
                              <Text as="p" mx="2px" align="center">
                                &ndash;
                              </Text>
                            </FlexContainer>
                            <StyledInput
                              id="end-date"
                              type="date"
                              label={intl.formatMessage({
                                id: 'invoices.end_date',
                              })}
                              onChange={e =>
                                this.setState({
                                  endDate: e.target.value,
                                  showSpecificPeriodErrorMessage: false,
                                })
                              }
                              value={endDate}
                              shrink
                              error={checkForError(errors, 'endDate')}
                            />
                          </RangeWrapper>
                          {showSpecificPeriodErrorMessage && (
                            <Text
                              as="span"
                              ml="31px"
                              pt={1}
                              size="small"
                              color="alert"
                            >
                              <FormattedMessage id="invoices.errors.no_invoiceable_bookings" />
                            </Text>
                          )}
                        </FlexContainer>
                      )}
                      {isMultipleInvoices && (
                        <InfoBox messageId="multiple_invoices_will_be_created" />
                      )}
                      <Button
                        disabled={
                          !selectedPeriod ||
                          (selectedPeriod === 'specific' &&
                            (!endDate || !startDate)) ||
                          showSpecificPeriodErrorMessage ||
                          !onlineStatus
                        }
                        mt={3}
                        onClick={e => this.createInvoicePeriod(e, client)}
                        loading={loading}
                      >
                        <FormattedMessage
                          id={`invoices.create_invoice_period${isMultipleInvoices ? 's' : ''
                            }_continue`}
                        />
                      </Button>
                    </form>
                  </FlexContainer>
                </Card>
              </GroupSection>
            )}
          </ServiceWorkerContext.Consumer>
        )}
      </ApolloConsumer>
    );
  }
}

const GroupSection = styled.section`
  margin-bottom: ${space.default};
  max-width: ${sizes.width.invoiceCardMaxWidth};

  :last-child {
    margin-bottom: ${space.lg};
  }
`;

const RangeWrapper = styled.div`
  display: inline-flex;
  margin: ${space.md} 0 0 31px;
`;

const TimeFlexContainer = styled(FlexContainer)`
  ${typography.fontSmall()};
  color: ${theme.colors.secondary};
  margin-bottom: ${space.xs};
  margin-left: 31px;

  justify-content: flex-start;
  display: inline-flex;
  flex-wrap: wrap;
`;

const DetailsWrapper = styled.div`
  &:before {
    content: '•';
    padding: 0 ${space.sm};
  }

  &:first-child:before {
    content: '';
    padding: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const StyledInput = styled(Input)`
  width: 160px;
  @media (max-width: 375px) {
    width: 136px;
  }

  input {
    padding-left: 8px;
    padding-right: 8px;

    @media (max-width: 375px) {
      font-size: 17px;
    }
  }

  input::-webkit-clear-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (min-width: 480px) {
    width: 180px;
  }
`;

InvoiceableBookingsList.propTypes = propTypes;

export default injectIntl(withLanguage(InvoiceableBookingsList));
