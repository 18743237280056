import React from 'react';
import styled from 'styled-components';
import { Card, Text } from '@freska/freska-ui';
import { string } from 'prop-types';

const propTypes = {
  title: string,
  description: string,
};

const SupportCard = ({ title, description }) => (
  <CardContainer className="card-section" key={title}>
    <Title as="p" size="large" bold>
      {title}
    </Title>
    <Text as="p" size="small">
      {description}
    </Text>
  </CardContainer>
);

const Title = styled(Text)`
  margin-bottom: 4px;
  font-size: 17px;
`;

const CardContainer = styled(Card)`
  margin-bottom: 8px;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #DADBE3;
  padding: 24px;
  border-radius: 20px;
`;

SupportCard.propTypes = propTypes;

export default SupportCard;
