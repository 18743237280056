/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  Heading,
  Text,
  Label,
  typography,
  theme,
  Link,
} from '@freska/freska-ui';

import StickyTop from '../Common/StickyTop';
import Blockquote from '../Common/Blockquote';
import FullscreenContentContainer from '../Common/FullscreenContentContainer';
import { space } from '../../theme/theme';
import VideoComponent from './VideoComponent';
import { newsContentType } from '../../types';

const propTypes = {
  item: newsContentType,
  slug: string.isRequired,
};

const colorMap = {
  example: 'secondary',
  data: 'primary',
  note: 'attention',
};

function parseContent(content) {
  try {
    return JSON.parse(content);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Unable to parse contentful content');
    return false;
  }
}

const ContentfulEntry = ({ item }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        if (node.data.target.fields && node.data.target.fields.link) {
          return <VideoComponent video={node.data.target.fields} />;
        }
        if (node.data.target.fields && node.data.target.fields.blockType) {
          return (
            <Blockquote
              label={node.data.target.fields.blockType}
              color={colorMap[node.data.target.fields.blockType]}
            >
              {documentToReactComponents(
                node.data.target.fields.blockContent,
                options
              )}
            </Blockquote>
          );
        }
        return '';
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        if (
          node.data.target.fields &&
          node.data.target.fields.file.contentType.startsWith('image/')
        ) {
          return (
            <ImageWrapper>
              <StyledImage
                src={`https:${node.data.target.fields.file.url}`}
                alt={node.data.target.fields.title}
              />
            </ImageWrapper>
          );
        }
        if (node.data.target.fields && node.data.target.fields.file) {
          return (
            <Link
              download
              href={`https:${node.data.target.fields.file.url}`}
              alt={node.data.target.fields.title}
              title={node.data.target.fields.title}
            >
              {node.data.target.fields.title}
            </Link>
          );
        }
        return '';
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children.length === 1 && children[0] === '') {
          return null;
        }
        return <StyledText as="p">{children}</StyledText>;
      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading level={1} align="left" mt={space.default} mb={space.md}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading level={2} align="left" my={1}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading level={3} align="left" my={1}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Heading level={3} align="left" my={1}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <LargeText as="p" size="large">
          {children}
        </LargeText>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <StyledLabel>{children}</StyledLabel>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <BulletPoints>{children}</BulletPoints>
      ),
      [BLOCKS.OL_LIST]: (node, children) => <List>{children}</List>,
      [BLOCKS.HR]: () => <Hr />,
      [INLINES.HYPERLINK]: (node, children) => (
        <Link
          href={node.data.uri}
          alt={children}
          title={children}
          target="_blank"
          underline={true}
        >
          {children}
        </Link>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <RouterLink
          to={`/${
            node.data.target.sys.contentType.sys.id === 'newsItems'
              ? 'news'
              : 'support'
          }/${node.data.target.fields.slug}`}
        >
          {children}
        </RouterLink>
      ),
    },
  };
  return (
    <FullscreenContentContainer>
      <StickyTop>
        {item && (
          <Heading level={2} mb={1}>
            {item.topic || item.title}
          </Heading>
        )}
      </StickyTop>
      <Content>
        {item ? (
          documentToReactComponents(
            parseContent(item.body || item.content),
            options
          )
        ) : (
          <FormattedMessage id="article_was_not_found" />
        )}
      </Content>
    </FullscreenContentContainer>
  );
};

const Content = styled.article`
  padding: 0 ${space.default} ${space.lg};
  white-space: pre-line;
  z-index: 1;
`;

const LargeText = styled(Text)`
  margin-bottom: ${space.sm};

  &:last-child {
    margin-bottom: 0;
  }
`;
const StyledText = styled(Text)`
  margin-bottom: ${space.md};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledLabel = styled(Label)`
  margin: ${space.xs} 0;
`;

const BulletPoints = styled.ul`
  ${typography.fontRegular()};
  ${typography.fontBold()};
  list-style: disc;

  margin-bottom: ${space.sm};
  padding-left: ${space.default};

  li {
    margin: 0 0 ${space.sm} ${space.xs};
    padding-left: ${space.sm};
  }
`;

const List = styled.ol`
  ${typography.fontRegular()};
  ${typography.fontBold()};
  list-style: decimal;

  margin-bottom: ${space.default};
  padding-left: ${space.default};

  li {
    margin: 0 0 ${space.sm} ${space.xs};
    padding-left: ${space.sm};
  }
`;

const Hr = styled.hr`
  background-color: ${theme.colors.greyMed};
  height: 1px;
  margin: ${space.default} 0px;
`;

const StyledImage = styled.img`
  margin-bottom: ${space.md};
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

ContentfulEntry.propTypes = propTypes;

export default ContentfulEntry;
