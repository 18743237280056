import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import { Logo, Card, Label } from '@freska/freska-ui';
import { withRouter } from 'react-router';

import NotificationBlock from '../../components/Common/NotificationBlock';
import ForgotPasswordForm from '../../components/Forms/ForgotPasswordForm';
import FlexContainer from '../../components/Common/FlexContainer';

const propTypes = {
  location: shape({ state: shape({ from: shape({ pathname: string }) }) }),
};

const defaultProps = {
  location: {
    state: {},
  },
};

class ForgotPasswordContainer extends PureComponent {
  state = {
    forgotPassword: false,
  };

  toggleForgotPassword = e => {
    e.preventDefault();
    this.setState(prevState => ({ forgotPassword: !prevState.forgotPassword }));
  };

  render() {
    const {
      location: { state },
    } = this.props;

    return (
      <FlexContainer center fullScreen column backgroundColor="#F0F1F4">
        <FlexContainer center>
          <Logo size={40} mb={4} />
        </FlexContainer>
        <FormWrapper>
          <Label>
            <FormattedMessage id="login.forgot_password" />
          </Label>
          {state && state.isFromResetPasswordComponent && (
            <NotificationBlock hasError={false}>
              <FormattedMessage id="login.reset_password_success" />
            </NotificationBlock>
          )}
          <ForgotPasswordForm
            toggleForgotPassword={this.toggleForgotPassword}
          />
        </FormWrapper>
      </FlexContainer>
    );
  }
}

const FormWrapper = styled(Card)`
  margin: 0 auto;
  max-width: 400px;
  width: 90%;
`;

ForgotPasswordContainer.propTypes = propTypes;
ForgotPasswordContainer.defaultProps = defaultProps;

export default withRouter(ForgotPasswordContainer);
