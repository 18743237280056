import React, { Component, Fragment } from 'react';
import { shape, func } from 'prop-types';
import { Button, Input, Text } from '@freska/freska-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';

import NotificationBlock from '../Common/NotificationBlock';
import FlexContainer from '../Common/FlexContainer';
import Form from '../Common/Form';
import { SEND_RESET_PASSWORD_EMAIL } from '../../gql/mutations';
import schema from './schemas/forgotPasswordSchema';
import validateData, { checkForError } from '../../utils/validate';

const propTypes = {
  intl: shape({}).isRequired,
  sendResetPasswordEmail: func.isRequired,
};

class ForgotPasswordForm extends Component {
  state = {
    loading: false,
    error: false,
    success: false,
    email: '',
    errors: {},
  };

  onSubmit = e => {
    const { email } = this.state;
    e.preventDefault();

    this.setState({ loading: true });
    validateData({ email }, schema).then(res =>
      this.setState(
        { errors: res },
        () => Object.keys(res).length === 0 && this.sendEmail()
      )
    );
  };

  sendEmail = () => {
    const { sendResetPasswordEmail } = this.props;
    const { email } = this.state;
    sendResetPasswordEmail({
      variables: {
        email,
        redirectUrl: `${window.location.origin}/reset-password`,
      },
    })
      .then(() =>
        this.setState({ loading: false, error: false, success: true })
      )
      .catch(() =>
        this.setState({ loading: false, error: true, success: false })
      );
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  render() {
    const { intl } = this.props;
    const { email, loading, error, success, errors } = this.state;

    if (success) {
      return (
        <Fragment>
          <NotificationBlock hasError={false}>
            <FormattedMessage id="login.reset_password_email_sent" values={{ email }} />
          </NotificationBlock>
        </Fragment>
      );
    }

    return (
      <Form
        name="login-form"
        onSubmit={e => {
          this.onSubmit(e);
        }}
        loading={loading}
      >
        <Text pb="12px" pt="8px">We will send you a link to create new password to Freska Pro.</Text>
        {error && (
          <NotificationBlock hasError={error}>
            <FormattedMessage id="error.default" />
          </NotificationBlock>
        )}
        <Input
          id="forgot-password-email"
          name="forgot-password-email"
          type="email"
          label={intl.formatMessage({ id: 'login.email' })}
          placeholder={intl.formatMessage({ id: 'login.email' })}
          disabled={loading}
          error={checkForError(errors, 'email')}
          onChange={this.handleEmailChange}
          value={email}
          mb={2}
          hasBorder
        />
        <FlexContainer alignH="flex-start" column>
          <Button
            type="submit"
            loading={loading}
            disabled={!email}
            id="login-btn"
          >
            <FormattedMessage id="login.reset_password" />
          </Button>
        </FlexContainer>
      </Form>
    );
  }
}

ForgotPasswordForm.propTypes = propTypes;

export default graphql(SEND_RESET_PASSWORD_EMAIL, {
  name: 'sendResetPasswordEmail',
})(withRouter(injectIntl(ForgotPasswordForm)));
