import React from 'react';
import { oneOf, number } from 'prop-types';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import {
  Box,
  Spinner,
  Text,
  IconStarFull,
  Label,
  theme,
} from '@freska/freska-ui';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { LANGUAGES_TYPE, RATING_QUALITY } from '../../constants';
import { GET_BOOKINGS_WITH_FEEDBACK } from '../../gql/queries';
import { formatRating } from '../../utils/formatNumber';
import withLanguage from '../../utils/withLanguage';
import { space } from '../../theme/theme';
import FlexContainer from '../Common/FlexContainer';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import { Fragment } from 'react';

const propTypes = {
  language: oneOf(LANGUAGES_TYPE).isRequired,
  recurringBookingId: number.isRequired,
};

function getFeedbackItems(bookings, locale) {
  return bookings.map(booking => (
    <StyledBox key={booking.id}>
      <Label>
        <FormattedDate
          value={booking.start_time}
          year="numeric"
          month="long"
          day="numeric"
          weekday="long"
        />
      </Label>
      <FlexContainer row>
        <FlexContainer column alignV="center" alignH="flex-start">
          <IconStarFull
            size={20}
            color={
              booking.rating.average > RATING_QUALITY ? 'rating' : 'attention'
            }
            mb={space.xs}
          />
          <Text m={0} as="p" size="small">
            {formatRating(booking.rating.average, locale)}
          </Text>
        </FlexContainer>
        <Box ml={2}>
          <Text m={0} as="p">
            {booking.rating.comments}
          </Text>
        </Box>
      </FlexContainer>
    </StyledBox>
  ));
}

const BookingDetailsPreviousCustomerFeedback = ({
  recurringBookingId,
  language: locale,
}) => {
  const { error, loading, data } = useQuery(GET_BOOKINGS_WITH_FEEDBACK, {
    variables: {
      recurringBookingId,
    },
  });

  const bookings = data?.getBookingsWithCustomerFeedback;
  if (error) {
    return <ErrorHandler error={error} />;
  }
  if (loading) {
    return (
      <Box p={3}>
        <Spinner />
      </Box>
    );
  }
  return (
    <Fragment>
      <Label m={0} pb={1}>
        <FormattedMessage id="bookings.details.feedback_history.title" />
      </Label>
      {bookings.length ? (
        getFeedbackItems(bookings, locale)
      ) : (
        <Text m={0} as="p" color="secondary">
          <FormattedMessage id="bookings.details.feedback_history.no_history" />
        </Text>
      )}
    </Fragment>
  );
};

const StyledBox = styled(Box)`
  padding-bottom: ${theme.space[1]}px;
  margin-bottom: ${theme.space[2]}px;
  border-bottom: 1px solid ${theme.colors.greyMed};
`;

BookingDetailsPreviousCustomerFeedback.propTypes = propTypes;

export default withLanguage(BookingDetailsPreviousCustomerFeedback);
