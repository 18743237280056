import React, { Component, Fragment } from 'react';
import {
  bool,
  object,
  instanceOf,
  shape,
  func,
  string,
  arrayOf,
} from 'prop-types';
import styled from 'styled-components';
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Input,
  Box,
  Spinner,
  Text,
  Heading,
  IconDateRange,
  IconEdit,
  IconClose,
  theme,
} from '@freska/freska-ui';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import {
  format,
  setHours,
  setMinutes,
  parseISO,
  startOfDay,
  endOfDay,
} from 'date-fns';

import { trackEvent } from '../../utils/tracking';
import validateData, { checkForError } from '../../utils/validate';
import schema from './schema';
import DataBlock from '../Common/DataBlock';
import FlexContainer from '../Common/FlexContainer';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import StickyTop from '../Common/StickyTop';
import FullscreenContentContainer from '../Common/FullscreenContentContainer';
import BookingCard from '../Bookings/BookingCard';
import {
  CREATE_AVAILABILITY,
  EDIT_AVAILABILITY,
  DELETE_AVAILABILITY,
} from '../../gql/mutations';
import {
  GET_AVAILABILITY_CALENDAR_DATA,
  GET_BOOKINGS,
} from '../../gql/queries';
import withUser from '../../utils/withUser';
import { sizes, space } from '../../theme/theme';
import { bookingType } from '../../types';
import { ServiceWorkerContext } from '../../useServiceWorker';
import ConfirmationModal from '../Common/ConfirmationModal';

const propTypes = {
  date: instanceOf(Date).isRequired,
  user: shape({ id: string }).isRequired,
  create: func.isRequired,
  edit: func.isRequired,
  remove: func.isRequired,
  close: func.isRequired,
  intl: shape({}).isRequired,
  defaultDay: shape({
    startTime: string,
    endTime: string,
  }).isRequired,
  toggleDrawer: func.isRequired,
  availabilities: arrayOf(shape({})),
  data: shape({
    error: object,
    loading: bool,
    getBookings: arrayOf(bookingType),
  }),
  handleOutsideAvailabilityPeriod: func.isRequired,
};

const defaultProps = {
  availabilities: [],
  data: {},
};

class DayModal extends Component {
  state = {
    startField: '08:00',
    endField: '18:00',
    errors: {},
    disabled: false,
    isBeingEdited: false,
    selectedAvailability: {},
    showWarning: false,
    showDeleteDialog: false,
  };

  componentDidMount = () => {
    const { toggleDrawer } = this.props;
    toggleDrawer(false);
    trackEvent('Availability: Calendar: Single Availabiltiy Edit: Opened', {
      category: 'Availability',
    });
  };

  showTime = time => format(parseISO(time), 'HH:mm');

  handleSave = () => {
    const { startField, endField } = this.state;
    trackEvent(
      'Availability: Calendar: Single Availabiltiy Edit: Save button clicked',
      {
        category: 'Availability',
      }
    );

    const data = {
      startField,
      endField,
      startHours: parseInt(startField.split(':')[0], 10),
      endHours: parseInt(endField.split(':')[0], 10),
    };
    validateData(data, schema).then(res =>
      this.setState(
        { errors: res },
        () => Object.keys(res).length === 0 && this.proccessSave()
      )
    );
  };

  getHoursAndMinutesIntegers = timeString =>
    timeString.split(':').map(time => parseInt(time, 10));

  getUTCDate = (date, time) =>
    setHours(setMinutes(date, time[1]), time[0]).toUTCString();

  update = (store, { data }) => {
    const calendarData = store.readQuery({
      query: GET_AVAILABILITY_CALENDAR_DATA,
    });
    if (data.createAvailability) {
      calendarData.getAvailabilityCalendarData.availabilities.push({
        ...data.createAvailability,
        availabilityId: data.createAvailability.id,
      });
    }
    if (data.editAvailability) {
      const edittedAvailabilityIndex = calendarData.getAvailabilityCalendarData.availabilities.findIndex(
        availability => availability.availabilityId === data.editAvailability.id
      );
      calendarData.getAvailabilityCalendarData.availabilities[
        edittedAvailabilityIndex
      ] = {
        ...data.editAvailability,
        availabilityId: data.editAvailability.id,
      };
    }
    if (data.deleteAvailability) {
      calendarData.getAvailabilityCalendarData.availabilities = calendarData.getAvailabilityCalendarData.availabilities.filter(
        availability => availability.availabilityId !== data.deleteAvailability
      );
    }
    store.writeQuery({
      query: GET_AVAILABILITY_CALENDAR_DATA,
      data: calendarData,
    });
  };

  proccessSave = () => {
    const { create, edit, date, user } = this.props;
    const { startField, endField, selectedAvailability } = this.state;

    const isNew = !selectedAvailability.availabilityId;

    const startDate = this.getUTCDate(
      date,
      this.getHoursAndMinutesIntegers(startField)
    );
    const endDate = this.getUTCDate(
      date,
      this.getHoursAndMinutesIntegers(endField)
    );

    const data = {
      end_time: endDate,
      frequency: 'once',
      service_worker_id: Number(user.id),
      start_time: startDate,
      valid_to: endDate,
      valid_from: startDate,
    };

    if (isNew) {
      this.mutate(create, { availability: data }, this.update, {
        createAvailability: {
          ...data,
          id: 1,
          availability_group_id: null,
          __typename: 'Availability',
        },
      });
    } else {
      this.mutate(
        edit,
        {
          id: selectedAvailability.availabilityId,
          availability: data,
        },
        this.update,
        {
          editAvailability: {
            ...data,
            id: selectedAvailability.availabilityId,
            availability_group_id: selectedAvailability.availabilityId,
            __typename: 'Availability',
          },
        }
      );
    }
  };

  deleteAvailability = () => {
    const { remove } = this.props;
    const { selectedAvailability } = this.state;
    trackEvent(
      'Availability: Calendar: Single Availabiltiy Edit: Delete button clicked',
      {
        category: 'Availability',
      }
    );

    this.mutate(
      remove,
      {
        id: selectedAvailability.availabilityId,
      },
      this.update,
      {
        deleteAvailability: selectedAvailability.availabilityId,
      }
    );
  };

  mutate = (mutation, variables, update, optimisticResponse) => {
    const { toggleDrawer } = this.props;
    toggleDrawer(false);
    this.setState({
      loading: true,
    });

    mutation({
      variables,
      update,
      optimisticResponse,
      refetchQueries: () => [{ query: GET_AVAILABILITY_CALENDAR_DATA }],
    })
      .then(() => {
        this.setState({
          loading: false,
          error: false,
          selectedAvailability: {},
          isBeingEdited: false,
          showDeleteDialog: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
          selectedAvailability: {},
          isBeingEdited: false,
        });
      });
  };

  updateTimes = (e, type) => {
    this.setState({
      [type]: e.target.value,
      disabled: false,
    });
  };

  onTimeBlur = (e, type) => {
    const { value } = e.target;
    const minutes = value.split(':')[1];
    if (minutes % 15 !== 0) {
      let hours = value.split(':')[0];
      const m = (Math.round(minutes / 15) * 15) % 60;
      // eslint-disable-next-line no-nested-ternary
      const h = minutes > 52 ? (hours === 23 ? 0 : ++hours) : Number(hours);
      this.setState({
        [type]: `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}`,
        showWarning: true,
      });
    } else {
      this.setState({ showWarning: false });
    }
  };

  goToOverview = () => {
    const { close, toggleDrawer } = this.props;
    toggleDrawer(true);
    close();
  };

  editAvailability = availability => {
    const { defaultDay } = this.props;
    const startField = availability.startTime
      ? this.showTime(availability.startTime)
      : defaultDay.startTime;
    const endField = availability.endTime
      ? this.showTime(availability.endTime)
      : defaultDay.endTime;
    const selectedAvailability = {
      availabilityId: availability.availabilityId,
      startTime: startField,
      endTime: endField,
    };
    this.setState({
      isBeingEdited: true,
      selectedAvailability,
      startField,
      endField,
    });
  };

  closeEdit = () => {
    this.setState({
      selectedAvailability: {},
      isBeingEdited: false,
      errors: {},
      showWarning: false,
    });
  };

  renderEditView = () => {
    const { intl } = this.props;
    const {
      selectedAvailability,
      startField,
      endField,
      errors,
      disabled,
      showWarning,
      showDeleteDialog,
    } = this.state;
    return (
      <ServiceWorkerContext.Consumer>
        {({ onlineStatus }) => (
          <Fragment key={selectedAvailability.availabilityId}>
            {showDeleteDialog && (
              <ConfirmationModal
                onConfirm={this.deleteAvailability}
                onClose={() => this.setState({ showDeleteDialog: false })}
                titleId="availability.delete_modal.title"
                messageId="availability.delete_modal.message"
                buttonCaptionId="availability.delete_modal.confirm"
              />
            )}
            <EditBox>
              <CloseIconWrapper>
                <IconClose size={24} onClick={this.closeEdit} />
              </CloseIconWrapper>

              <FlexContainer row alignH="space-between" alignV="center" mb={2}>
                <TimeInput
                  id="start-time"
                  type="time"
                  label={intl.formatMessage({ id: 'availability.starting_on' })}
                  name="start-time"
                  isRequired
                  value={startField}
                  onChange={e => {
                    this.updateTimes(e, 'startField');
                  }}
                  onBlur={e => {
                    this.onTimeBlur(e, 'startField');
                  }}
                  error={
                    checkForError(errors, 'startField') ||
                    checkForError(errors, 'startHours')
                  }
                  hasBorder
                />
                <StyledDash as="p" align="center" pt={1} ml="4px">
                  &ndash;
                </StyledDash>
                <TimeInput
                  id="end-time"
                  type="time"
                  label={intl.formatMessage({ id: 'availability.ending_on' })}
                  name="end-time"
                  isRequired
                  value={endField}
                  onChange={e => {
                    this.updateTimes(e, 'endField');
                  }}
                  onBlur={e => {
                    this.onTimeBlur(e, 'endField');
                  }}
                  error={
                    checkForError(errors, 'endField') ||
                    checkForError(errors, 'endHours')
                  }
                  hasBorder
                  ml="4px"
                />
              </FlexContainer>
              {showWarning && (
                <FlexContainer mb={2}>
                  <Text as="span" size="small" mt={-1} color="secondary">
                    <FormattedMessage id="availability.errors.intervals_only" />
                  </Text>
                </FlexContainer>
              )}
              <FlexContainer mb={1} row alignH="space-between">
                <Button
                  mr={3}
                  onClick={this.handleSave}
                  disabled={disabled || !onlineStatus}
                >
                  <FormattedMessage id="availability.save_availability" />
                </Button>
                {selectedAvailability.availabilityId && (
                  <Button
                    variant="destructive"
                    onClick={() => this.setState({ showDeleteDialog: true })}
                    disabled={!onlineStatus}
                  >
                    <FormattedMessage id="availability.remove_availability" />
                  </Button>
                )}
              </FlexContainer>
            </EditBox>
          </Fragment>
        )}
      </ServiceWorkerContext.Consumer>
    );
  };

  render() {
    const {
      date,
      availabilities,
      data: {
        getBookings: bookingsData,
        loading: bookingsLoading,
        error: bookingsError,
      },
      handleOutsideAvailabilityPeriod,
    } = this.props;
    const { loading, error, selectedAvailability, isBeingEdited } = this.state;

    const sortedAvailabilities = availabilities.sort(
      (previous, next) =>
        new Date(previous.startTime) - new Date(next.startTime)
    );

    if (loading) {
      return (
        <Box p={3}>
          <Spinner />
        </Box>
      );
    }

    if (error || bookingsError) {
      return <ErrorHandler error={error || bookingsError} />;
    }

    return (
      <FullscreenContentContainer>
        <Entry>
          <StickyTop>
            <Heading level={2} mb={3} align="left">
              <FormattedMessage id="availability.availability_details" />
            </Heading>
            <StyledDataBlock Icon={IconDateRange} hasContent>
              <Text m={0} as="p">
                <FormattedDate
                  value={date}
                  year="numeric"
                  month="long"
                  day="numeric"
                  weekday="long"
                />
              </Text>
            </StyledDataBlock>
          </StickyTop>
          <AvailabilitySection>
            <Heading level={3} mb={2} ml={3} align="left">
              <FormattedMessage id="availability.availability_this_day" />
            </Heading>
            {!sortedAvailabilities.length && (
              <Text ml={3} mb={2} as="p" color="secondary">
                <FormattedMessage id="availability.no_availability_this_day" />
              </Text>
            )}
            {!!sortedAvailabilities.length &&
              sortedAvailabilities.map(availability => {
                if (
                  selectedAvailability.availabilityId ===
                  availability.availabilityId
                ) {
                  return this.renderEditView();
                }
                return (
                  <Grid key={uuidv4()}>
                    <Text as="p" bold>
                      {`${this.showTime(
                        availability.startTime
                      )} - ${this.showTime(availability.endTime)}`}
                    </Text>
                    {!isBeingEdited && (
                      <EditIcon
                        size={24}
                        onClick={() => this.editAvailability(availability)}
                      />
                    )}
                  </Grid>
                );
              })}

            {!selectedAvailability.availabilityId && !isBeingEdited && (
              <Button mb={1} ml={3} onClick={this.editAvailability}>
                <FormattedMessage id="availability.add_period" />
              </Button>
            )}
            {!selectedAvailability.availabilityId &&
              isBeingEdited &&
              this.renderEditView()}
          </AvailabilitySection>
          <Section>
            {bookingsLoading && (
              <Box p={3}>
                <Spinner />
              </Box>
            )}
            {!bookingsLoading && bookingsData.length > 0 && (
              <Fragment>
                <Heading level={3} mb={2} align="left">
                  <FormattedMessage
                    id="availability.bookings_this_day"
                    values={{ num: bookingsData.length }}
                  />
                </Heading>
                {bookingsData[0].bookings.length ? (
                  bookingsData[0].bookings.map(booking => (
                    <BookingCard
                      data={booking}
                      key={booking.id}
                      isBookingFromAvailability
                      isOutsideAvailabilityPeriod={handleOutsideAvailabilityPeriod(
                        booking
                      )}
                    />
                  ))
                ) : (
                  <Text mb={2} as="p" color="secondary">
                    <FormattedMessage id="availability.no_bookings_this_day" />
                  </Text>
                )}
              </Fragment>
            )}
          </Section>
        </Entry>
      </FullscreenContentContainer>
    );
  }
}

const EditBox = styled.div`
  box-shadow: ${theme.elevation.top};
  position: relative;
  width: 100%;
  padding: ${space.xs} ${space.default} ${space.md};
`;

const Grid = styled.div`
  grid-gap: ${space.md};
  align-items: start;
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;
  margin-bottom: ${space.md};
  padding: ${space.xs} ${space.default};
`;

const AvailabilitySection = styled.section`
  margin-bottom: ${space.default};
`;

const Section = styled.section`
  margin-bottom: ${space.default};
  padding: 0 ${space.default};
`;

const StyledDataBlock = styled(DataBlock)`
  margin-bottom: ${space.sm};
`;

const EditIcon = styled(IconEdit)`
  justify-self: end;
`;

const CloseIconWrapper = styled.div`
  text-align: right;
  padding: ${space.sm} 0;
`;

const Entry = styled.div`
  background: ${theme.colors.white};
  max-width: ${sizes.width.bookingCardMaxWidth};
  margin: 0 auto;
  padding-bottom: ${space.sm};
  min-height: 100%;
`;

const TimeInput = styled(Input)`
  width: 100%;

  input::-webkit-clear-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledDash = styled(Text)`
  display: block;

  @media (max-width: 352px) {
    display: none;
  }
`;

DayModal.propTypes = propTypes;
DayModal.defaultProps = defaultProps;

export default compose(
  graphql(CREATE_AVAILABILITY, {
    name: 'create',
  }),
  graphql(EDIT_AVAILABILITY, {
    name: 'edit',
  }),
  graphql(DELETE_AVAILABILITY, {
    name: 'remove',
  }),
  graphql(GET_BOOKINGS, {
    options: props => ({
      variables: {
        startDate: startOfDay(props.date),
        endDate: endOfDay(props.date),
        bookingStatus: 'all',
      },
    }),
  })
)(withRouter(withUser(injectIntl(DayModal))));
