import React, { useEffect } from 'react';
import { useIntl, FormattedMessage, FormattedDate } from 'react-intl';
import styled from 'styled-components';
import {
  Tag,
  IconTag,
  IconKeyAttention,
  IconLock,
  IconNote,
  IconNextBooking,
  Box,
  IconDirections,
  Text,
  theme,
  Button,
} from '@freska/freska-ui';

import { trackEvent } from '../../utils/tracking';
import { space } from '../../theme/theme';
import DataBlock from '../Common/DataBlock';
import StickyTop from '../Common/StickyTop';
import FullscreenContentContainer from '../Common/FullscreenContentContainer';
import { serviceWorkerKeysType } from '../../types';

const propTypes = {
  keyInfo: serviceWorkerKeysType.isRequired,
};

const buildMapsURL = address => {
  const fullAddress = `${address.street_address} ${address.city} ${address.postcode}`;
  return encodeURI(
    `https://www.google.com/maps/dir/?api=1&destination=${fullAddress}`
  );
};

const handleDirectionsClick = address => {
  trackEvent('Keys: Directions button clicked', {
    category: 'Keys',
  });
  window.open(buildMapsURL(address));
};

const KeyDetails = ({ keyInfo }) => {
  const intl = useIntl();
  useEffect(() => {
    trackEvent('Key Details opened', {
      category: 'Keys',
    });
  }, []);

  return (
    <FullscreenContentContainer>
      <StickyTop>
        {keyInfo.nextBooking && (
          <HeaderCustomerGrid>
            <Box>
              {!!keyInfo.nextBooking.company && (
                <Text as="p" color="black" bold mb={0}>
                  {keyInfo.nextBooking.company.name}
                </Text>
              )}
              <Text
                as="p"
                color="black"
                bold={!keyInfo.nextBooking.company}
                mb={0}
              >
                {!!keyInfo.nextBooking.company && (
                  <FormattedMessage id="bookings.company_contact_person" />
                )}
                {`${keyInfo.nextBooking.person.firstname} ${keyInfo.nextBooking.person.lastname}`}
              </Text>
              <Address as="p" mb={0}>
                <span>
                  {keyInfo.nextBooking.address.street_address}
                  &#44;
                </span>
                <span>
                  {keyInfo.nextBooking.address.postcode}
                  &nbsp;
                  {keyInfo.nextBooking.address.city}
                </span>
              </Address>
            </Box>
            <StyledButton
              onClick={() => handleDirectionsClick(keyInfo.nextBooking.address)}
              m={0}
            >
              <IconDirections
                title={intl.formatMessage({
                  id: 'bookings.card.directions',
                })}
                color="primary"
              />
            </StyledButton>
          </HeaderCustomerGrid>
        )}
        {(keyInfo.return_date || keyInfo.status === 'at_office') && (
          <Tag color="attention" mb={3}>
            <FormattedMessage
              id={`keys.tags.${keyInfo.return_date ? 'return' : 'pick_up'}`}
            />
          </Tag>
        )}
        <LabelDataBlock Icon={IconTag}>{keyInfo.label}</LabelDataBlock>
      </StickyTop>
      <Wrapper>
        {keyInfo.return_date && (
          <DataBlock
            Icon={IconKeyAttention}
            title="keys.labels.return_date"
            withAttention
          >
            {keyInfo.return_date}
          </DataBlock>
        )}
        {keyInfo.nextBookingDate && (
          <DataBlock
            withAttention
            Icon={IconNextBooking}
            title="keys.next_booking"
          >
            <FormattedDate
              value={keyInfo.nextBookingDate}
              weekday="long"
              day="2-digit"
              month="long"
              year="numeric"
              hour="2-digit"
              minute="2-digit"
            />
          </DataBlock>
        )}
        <DataBlock
          Icon={IconLock}
          title={`keys.labels.${keyInfo.nextBookingDate ? 'pick_up_location' : 'safekeeper'
            }`}
        >
          <FormattedMessage id={`keys.status.${keyInfo.status}`} />
        </DataBlock>
        <DataBlock
          Icon={IconNote}
          title="keys.notes"
          hasContent={!!keyInfo.notes}
        >
          {keyInfo.notes}
        </DataBlock>
      </Wrapper>
    </FullscreenContentContainer>
  );
};

const HeaderCustomerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 48px;
  grid-column-gap: ${space.sm};
  align-items: center;

  margin-bottom: ${space.md};
`;

const Address = styled(Text)`
  margin-bottom: 0;
  span {
    display: inline-block;
    padding-right: ${space.xs};
  }
`;

const StyledButton = styled(Button)`
  min-width: 42px;
  min-height: 42px;
  width: 42px;
  height: 42px;
  padding: ${space.sm};
  border-radius: 50%;
  justify-self: center;

  svg {
    height: 24px;
    width: 24px;
  }

  ${props =>
    props.bookingStatus !== 'booked' &&
    props.bookingStatus !== 'prebooked' &&
    `
    background-color: ${theme.colors.white};
    box-shadow: none;

    :hover,
    :visited,
    :focus  {
      background-color: ${theme.colors.white};
    }
    `}
`;

const Wrapper = styled.section`
  padding: 0 ${space.default} ${space.default};
  height: 100%;
  z-index: 1;
`;

const LabelDataBlock = styled(DataBlock)`
  margin-bottom: 0;
`;

KeyDetails.propTypes = propTypes;

export default KeyDetails;
