import React, { Fragment, useState } from 'react';
import { number } from 'prop-types';
import {
  Text,
  Box,
  Spinner,
  IconAddCircle,
  TextArea,
  typography,
  Button,
  Card,
  theme,
  Label,
} from '@freska/freska-ui';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery, useMutation } from 'react-apollo';
import { GET_WORKER_NOTES_BY_BOOKING_ID } from '../../gql/queries';
import { ADD_WORKER_NOTE } from '../../gql/mutations';
import NotificationBlock from '../Common/NotificationBlock';
import Blockquote from '../Common/Blockquote';
import BookingDetailsWorkerNote from './BookingDetailsWorkerNote';

import { trackEvent } from '../../utils/tracking';

const propTypes = {
  bookingId: number.isRequired,
  serviceId: number.isRequired,
};

const BookingDetailsWorkerNotes = ({ bookingId, serviceId }) => {
  const intl = useIntl();
  const [
    addWorkerNote,
    { loading: addWorkerNoteLoading, error: addWorkerNoteError },
  ] = useMutation(ADD_WORKER_NOTE);

  const { data, loading, error } = useQuery(GET_WORKER_NOTES_BY_BOOKING_ID, {
    variables: {
      bookingId,
    },
  });

  const [showAddNote, setShowAddNote] = useState(false);
  const [addNoteTextareaValue, setAddNoteTextareaValue] = useState('');

  function handleAddWorkerNote(e) {
    e.preventDefault();
    addWorkerNote({
      variables: {
        bookingId,
        content: addNoteTextareaValue,
      },
      refetchQueries: [
        {
          query: GET_WORKER_NOTES_BY_BOOKING_ID,
          variables: {
            bookingId,
          },
        },
      ],
      awaitRefetchQueries: true,
    }).then(() => {
      setShowAddNote(false);
      setAddNoteTextareaValue('');
      trackEvent('Worker note created', {
        category: 'Booking details',
      });
    });
  }

  return (
    <Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
      >
        <Label m={0}>
          <FormattedMessage id="bookings.details.instructions.worker_notes_title" />
        </Label>

        <StyledButton
          onClick={() => setShowAddNote(true)}
          variant="secondary"
          size="small"
          p={0}
          disabled={showAddNote}
        >
          <IconAddCircle color={showAddNote ? 'greyMed' : 'primary'} mr="4px" />
          <Box height="24px" pt="3px">
            <FormattedMessage id="bookings.details.instructions.add_note" />
          </Box>
        </StyledButton>
      </Box>
      <Blockquote
        color="completed"
        label={intl.formatMessage({
          id: `bookings.details.instructions.worker_notes_info_tag`,
        })}
        pt={1}
      >
        <Text size="small">
          <FormattedMessage
            id="bookings.details.instructions.worker_notes_description"
            values={{
              service: intl
                .formatMessage({
                  id: `bookings.card.service_type.${serviceId}`,
                })
                .toLowerCase(),
            }}
          />
        </Text>
      </Blockquote>

      {addWorkerNoteError && !showAddNote && (
        <NotificationBlock hasError>
          <FormattedMessage id="bookings.details.instructions.error_saving_worker_notes" />
        </NotificationBlock>
      )}
      {showAddNote && (
        <Card variant="secondary" mb={3}>
          <StyledTextArea
            hideLabel
            label="Worker note"
            name="addWorkerNote"
            value={addNoteTextareaValue}
            onChange={e => setAddNoteTextareaValue(e.target.value)}
            rows={8}
            disabled={addWorkerNoteLoading}
          />
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="row"
          >
            <Button
              size="small"
              disabled={addWorkerNoteLoading}
              onClick={e => handleAddWorkerNote(e)}
              mr={1}
            >
              {addWorkerNoteLoading ? (
                <Spinner size={19} />
              ) : (
                <FormattedMessage id="bookings.details.instructions.add_note" />
              )}
            </Button>
            <Button
              variant="secondary"
              size="small"
              disabled={addWorkerNoteLoading}
              onClick={() => {
                setAddNoteTextareaValue('');
                setShowAddNote(false);
              }}
            >
              <FormattedMessage id="bookings.details.instructions.cancel_add_note" />
            </Button>
          </Box>
        </Card>
      )}
      {error && (
        <NotificationBlock hasError>
          <FormattedMessage id="bookings.details.instructions.error_fetching_worker_notes" />
        </NotificationBlock>
      )}
      {loading && (
        <Box p={4}>
          <Spinner size={24} />
        </Box>
      )}
      {data?.getWorkerNotesByBookingId?.length > 0 &&
        data.getWorkerNotesByBookingId.map(note => (
          <BookingDetailsWorkerNote
            bookingId={bookingId}
            key={note.id}
            note={note}
          />
        ))}
      {data?.getWorkerNotesByBookingId?.length === 0 && (
        <Text color="secondary">
          <FormattedMessage id="bookings.details.instructions.no_worker_notes" />
        </Text>
      )}
    </Fragment>
  );
};

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  box-shadow: none;
  &:hover {
    box-shadow: none;
    background: none;
  }
`;

const StyledTextArea = styled(TextArea)`
  margin-bottom: ${theme.space[1]}px;

  & textarea {
    ${typography.fontSmall}
    font-family: monospace;
    white-space: pre-wrap;
    width: 100%;
  }
`;

BookingDetailsWorkerNotes.propTypes = propTypes;

export default BookingDetailsWorkerNotes;
